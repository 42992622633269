import { ReactNode } from "react";
import { ComponentSizeProps, extractSizeProps } from "utility/component-size";
import { variantClasses, VariantProps } from "utility/variant-classes";
import { StyleProps } from "../interfaces";

interface AvatarProps extends StyleProps, ComponentSizeProps, VariantProps {
  children: ReactNode;
}

export function Avatar({
  children,
  className = " sm:h-[60px] sm:w-[60px] h-10 w-10",
  style,
  ...props
}: AvatarProps) {
  const { componentSize, otherProps: variantProps } = extractSizeProps(props);
  return (
    <div {...{ className, style: componentSize.apply(style) }}>
      <div
        className={variantClasses(
          {
            primary: "bg-primary text-white",
            success: "bg-success text-white",
            secondary: "bg-secondary text-white",
            warning: "bg-warning text-white",
            danger: "bg-danger text-white",
            white: "bg-white text-primary",
            blue: "bg-blue text-white",
            disabled: "bg-charcoal-light text-charcoal-accent",
            default:
              "flex h-full w-full cursor-pointer items-center justify-center rounded-full text-xs text-white",
          },
          variantProps
        )}
        data-testid="avatar"
      >
        {children}
      </div>
    </div>
  );
}
