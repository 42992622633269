import { Translator } from "components/atomic/injection";
import { defaultTranslator } from "./default-date-translator";

export function dateString(
  date: Date = new Date(),
  $t: Translator = defaultTranslator,
  lang = "en"
) {
  const now = new Date();
  const diffInSecs = (now.getTime() - date.getTime()) / 1000;
  const diffInMins = diffInSecs / 60;
  const diffInHours = diffInMins / 60;
  const diffInDays = diffInHours / 24;
  const diffInMonhts =
    12 * (now.getFullYear() - date.getFullYear()) +
    (now.getMonth() - date.getMonth());

  if (diffInMins < 60) {
    return $t("mins-ago", { count: Math.floor(diffInMins) });
  }

  if (diffInHours < 24) {
    return $t("hours-ago", { count: Math.floor(diffInHours) });
  }

  if (diffInDays < 7) {
    return $t("days-ago", { count: Math.floor(diffInDays) });
  }

  if (diffInMonhts > 3) {
    return date.toLocaleDateString(lang, {
      month: "short",
      year: "numeric",
    });
  }

  return date.toLocaleDateString(lang, {
    day: "numeric",
    month: "short",
  });
}
