import { sendErrorToSentry } from "utility/sentry";

interface JwtPayload {
  exp: number;
  iat: number;
}

interface JwtHeader {}

interface DecodedTokenOptions {
  expire: {
    secondsEarly: number;
  };
}

const defaultOptions: DecodedTokenOptions = {
  expire: {
    secondsEarly: 15,
  },
};
let msTimeDifferential = 0;
/**
 *
 * @param serverTime Time since unix epoch in ms
 */
export function synchronizeTime(serverTime: number) {
  const clientTime = Date.now();
  msTimeDifferential = serverTime - clientTime;
}

export class DecodedToken {
  isValid: boolean;
  header: JwtHeader;
  payload: JwtPayload;
  signature: string;
  options: DecodedTokenOptions;
  issuedAtMs: number;
  expiresAtMs: number;
  lifetimeMs: number;

  constructor(token: string, options = defaultOptions) {
    this.options = options;

    if (!token) {
      this.isValid = false;
      return;
    }

    try {
      const parts = token.split(".");

      [this.header, this.payload] = parts
        .slice(0, 2)
        .map(atob)
        .map((part) => JSON.parse(part));

      this.signature = parts[2];
      this.isValid = true;
    } catch (error) {
      const tokenError = new Error(
        `Failed to parse token (${token}) -- ${error.message}`
      );
      tokenError.name = error.name;
      tokenError.stack = error.stack;
      Math.random() < 0.05 && sendErrorToSentry(tokenError);
      this.isValid = false;
    }

    this.expiresAtMs =
      this.payload.exp * 1000 -
      this.options.expire.secondsEarly * 1000 -
      msTimeDifferential;

    this.issuedAtMs = this.payload.iat * 1000;
    this.lifetimeMs = this.expiresAtMs - this.issuedAtMs;
  }

  get isExpired() {
    if (!this.isValid) return true;
    return this.expiresAtMs < Date.now();
  }
}

export function decodeToken(token: string, options = defaultOptions) {
  return new DecodedToken(token, options);
}
