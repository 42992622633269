import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { getLoginStatus } from "store/auth/auth-manager";
import { handleAccountError } from "store/registration";
import { synchronizeTime } from "utility/auth/decode-token";
import { authAxios, AxiosDialogProps } from "utility/axios";
import { setSentryUser } from "utility/sentry";

import {
  ChangePasswordMutation,
  ChangePasswordRequest,
  deserializeUser,
  UpdateProfileMutation,
  UpdateProfileRequest,
  User,
  UserResponse,
} from "./";
import { UserSettings } from "./user-models";
import { serializeUserProfile } from "./user-serialization";

export function useAccountManager() {
  const queryClient = useQueryClient();

  const changePassword = useCallback(
    async ({ oldPassword, newPassword }: ChangePasswordMutation) => {
      const request: ChangePasswordRequest = {
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: newPassword,
      };

      return authAxios
        .post("auth/password/change/", request, {
          hideWaitDialog: true,
          hideSuccessDialog: true,
          hideErrorDialog: true,
        })
        .then((response) => response.data)
        .catch(handleAccountError);
    },
    []
  );

  const updateProfile = useCallback(
    async (updates: UpdateProfileMutation, options?: AxiosDialogProps) => {
      const result = await authAxios.patch(
        "auth/me/",
        serializeUserProfile(updates),
        options
      );
      queryClient.invalidateQueries("user");
      return result;
    },
    [queryClient]
  );

  return useMemo(
    () => ({
      updateProfile,
      changePassword,
    }),
    [changePassword, updateProfile]
  );
}

export async function getUser() {
  const { data } = await authAxios
    .get<UserResponse>(`/auth/user/`)
    .then((response) => {
      const serverTime = new Date(response.headers["date"]).getTime();
      if (!isNaN(serverTime)) synchronizeTime(serverTime);
      setSentryUser({ email: response.data.email });
      return response;
    });

  return deserializeUser(data);
}

export function useUser(
  queryOptions: Omit<
    UseQueryOptions<User, unknown, User, "user">,
    "queryKey" | "queryFn"
  > = {}
) {
  const queryClient = useQueryClient();
  return useQuery("user", () => getLoginStatus() && getUser(), queryOptions);
}
