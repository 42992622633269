import { NumberInput } from "components/atomic/input/interfaces";
import { AttainedDegree } from "store/education";
import { JobCategoryId } from "store/job-category/interfaces";
import { PositionType } from "store/job/enums";
import { AnyJobQuestion, JobLocation, JobSkill } from "store/job/interfaces";
import { Skill } from "store/skill/interfaces";
import { State, StateProps } from "utility/object-state";

import { JobPayType } from "./job-details-form/partials/job-pay";

export interface JobRequirements {
  skills: JobSkill[];
  education: AttainedDegree;
  experience: NumberInput;
  keywords: Keyword[];
}
export enum KeywordContext {
  USER = "user",
  SKILL = "skill",
}
export interface Keyword {
  context: KeywordContext;
  title: string;
}
export interface JobDetails extends JobLocation {
  title: string;
  description: string;
  annualPay: JobPayType;
  hourlyPay: JobPayType;
  benefits: string[];
  positions: PositionType[];
  hours: NumberInput;
  isAssessmentJobAd: boolean;
}

export interface JobState
  extends StateProps<{
    jobCategoryId: JobCategoryId;
    questions: AnyJobQuestion[];
  }> {
  defaultSkills: Skill[];
  details: State<JobDetails>;
  requirements: State<JobRequirements>;
}
