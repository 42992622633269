export enum UserType {
  Admin = "admin",
  JobSeeker = "job-seeker",
  Employer = "employer",
}

export enum Role {
  Admin = "admin",
  Owner = "owner",
  HiringManager = "hiring-manager",
  Colleague = "colleague",
  Candidate = "candidate",
}
