import { CloseIcon } from "assets/icons";
import { ToastProps } from "components/atomic/dialog/interfaces";
import React, { useEffect } from "react";
import { IconButton } from "../buttons";
import { useDialog } from "./dialog-hook";

export function ToastDialog({
  message,
  timeout = 1000,
  record,
  variant = "primary",
  color = "white",
  className,
}: ToastProps) {
  const { all } = useDialog();
  useEffect(() => {
    record.resolve(record);

    setTimeout(() => {
      record.delete();
    }, timeout);
  }, [timeout, record]);

  const index = all
    .filter((r) => r.props.type === "toast")
    .findIndex((r) => r.id === record.id);
  return (
    <div
      style={{ translate: `0px ${index * 105}% ` }}
      className={`fixed top-10 right-16 z-50 w-[300px]  rounded-md font-poppins text-${color} bg-${variant} flex justify-between p-5 ${className}`}
      data-testid="toast-dialog"
    >
      {message}
      <IconButton
        className="fill-white stroke-white"
        width="16px"
        height="16px"
        onClick={() => record.delete()}
        icon={CloseIcon}
        data-testid="toast-dialog-close"
      />
    </div>
  );
}
