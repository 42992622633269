import { CheckIcon, CircleIcon } from "assets/icons";
import classNames from "classnames";
import { StyleProps } from "components/atomic/interfaces";
import React, { FC, MouseEvent } from "react";
import { variantClasses, VariantProps } from "utility/variant-classes";

export interface CheckboxProps extends StyleProps, VariantProps {
  /**  edge length of the checkbox*/
  radioEdge?: string;
  /**  default value for the checkbox*/
  defaultValue?: boolean;
  /** Is checbox checked*/
  value?: boolean;
  /** Callback for onClick */
  onClick?: (value: boolean, event: MouseEvent) => void;
  bgClass?: string;
  borderClass?: string;
  markerClass?: string;
  Marker?: FC<StyleProps>;
}

export const CircleMarker: FC<StyleProps> = ({
  className = "bg-primary",
  style,
}) => {
  return (
    <CircleIcon
      className={classNames("h-full w-full", "animate-fade-in p-1", className)}
      style={style}
    />
  );
};

export const CheckMarker: FC<StyleProps> = ({
  className = "bg-primary fill-white",
  style,
}) => {
  return (
    <CheckIcon
      className={classNames(
        "h-full w-full",
        "animate-fade-in p-0.5",
        className
      )}
      style={style}
    />
  );
};

export const Checkbox: FC<CheckboxProps> = ({
  radioEdge = "20px",
  value,
  className,
  bgClass = "",
  borderClass = "",
  markerClass = "",
  style = {},
  Marker = CheckMarker,
  onClick,
  ...props
}) => {
  return (
    <div
      className={className}
      style={{
        width: radioEdge,
        height: radioEdge,
        ...style,
      }}
    >
      <button
        onClick={(event) => onClick && onClick(!value, event)}
        className={
          borderClass
            ? classNames(borderClass, bgClass, "block h-full w-full rounded")
            : classNames(
                "block h-full w-full rounded",
                variantClasses(
                  {
                    primary: classNames("border-primary", bgClass),
                    secondary: classNames("border-secondary", bgClass),
                    white: classNames("border-white", bgClass),
                    blue: classNames("border-blue", bgClass),
                    danger: classNames("border-danger", bgClass),
                    success: classNames("border-success", bgClass),
                    warning: classNames("border-warning", bgClass),
                    disabled:
                      "cursor-not-allowed border-secondary-200 bg-secondary-100",
                    compact: "border",
                    default: "border-2",
                  },
                  props
                )
              )
        }
      >
        <div className="flex h-full w-full items-center justify-center">
          {value && (
            <Marker
              className={
                markerClass ||
                variantClasses(
                  {
                    primary: "bg-primary fill-white",
                    secondary: "bg-secondary fill-white",
                    white: "fill-white",
                    blue: "bg-blue fill-white",
                    danger: "bg-danger fill-white",
                    success: "bg-success fill-white",
                    warning: "bg-warning fill-white",
                    disabled: "bg-secondary-200 fill-white",
                  },
                  props
                )
              }
            />
          )}
        </div>
      </button>
    </div>
  );
};
