import { createContext } from "react";
import { type Accuracy } from "./accuracy";

export interface WindowScrollState {
  x: number;
  y: number;
  width: number;
  height: number;
}

export type WindowScrollAccuracy = Accuracy<WindowScrollState>;

const defaultWindowScrollState: WindowScrollState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

export const WindowScrollContext = createContext<WindowScrollState>(
  defaultWindowScrollState
);
