import { deserializeOption } from "store/job/deserializer";
import { QuestionSetId } from "store/question-set";

import {
  FollowUpQuestion,
  QuestionDetails,
  QuestionId,
  QuestionListItem,
  QuestionListTest,
  QuestionOption,
  QuestionResponse,
  QuestionResponseOption,
  QuestionType,
  ResponseQuestionTypeProps,
} from "./";
import { Question, QuestionRequest } from "./requests";
import {
  FollowUpListItemResponse,
  PreviewQuestionResponse,
  QuestionListResponse,
} from "./responses";
import { QuestionContext, RoughQuestionType } from "./interfaces";
import { TestId, TestQuestionWithOptions } from "store/test/interfaces";
import { PreviewTestResponseQuestion } from "store/test/responses";

export function serializeQuestionType(input: QuestionType) {
  const type = {
    [QuestionType.MULTIPLE_CHOICE]: "sa-multi-choice-question",
    [QuestionType.MULTIPLE_ANSWER]: "ma-multi-choice-question",
    [QuestionType.WEIGHTED]: "sa-multi-choice-question",
    [QuestionType.MULTI_INPUT]: "mi-free-text-question",
    [QuestionType.FREE_TEXT]: "si-free-text-question",
    [QuestionType.NUMERIC]: "si-free-text-question",
    [QuestionType.VIDEO]: "video",
  }[input];

  if (!type) throw new Error(`Invalid question type: ${input}`);

  return {
    weighted: input === QuestionType.WEIGHTED,
    answer_type: input === QuestionType.NUMERIC ? "numerical" : null,
    type: type as QuestionRequest["type"],
  } as ResponseQuestionTypeProps;
}

export function deserializeQuestionType({
  type,
  answer_type,
  weighted,
}: ResponseQuestionTypeProps): QuestionType {
  if (weighted && type == "sa-multi-choice-question") {
    return QuestionType.WEIGHTED;
  }

  if (answer_type === "numerical" && type == "si-free-text-question") {
    return QuestionType.NUMERIC;
  }

  return {
    "sa-multi-choice-question": QuestionType.MULTIPLE_CHOICE,
    "ma-multi-choice-question": QuestionType.MULTIPLE_ANSWER,
    "mi-free-text-question": QuestionType.MULTI_INPUT,
    "si-free-text-question": QuestionType.FREE_TEXT,
    video: QuestionType.VIDEO,
  }[type];
}

export function serializeRoughQuestionType(type: RoughQuestionType): string[] {
  return {
    "Multiple Choice": ["sa-multi-choice-question", "ma-multi-choice-question"],
    "Free Text": ["mi-free-text-question", "si-free-text-question"],
  }[type];
}

export function deserializeFollowUpQuestion(
  input: FollowUpListItemResponse,
  parentId: QuestionId,
): FollowUpQuestion {
  return {
    __context: "FollowUpQuestion",
    id: input.id,
    parentId,
    questionId: input.question.id,
    option:
      typeof input.option === "object" ? input.option.option.id : input.option,
    options: input.question?.options?.map((o) => deserializeOption(o)) || [],
    timeLimit: input.question.time_limit,
    title: input.question.title,
    text: input.question.text,
    type: deserializeQuestionType(input.question),
    isSelected: input.is_selected,
  };
}

export function deserializeQuestionOption({
  id: questionOptionId,
  option,
  answer_count: answerCount,
  weight,
  is_correct: isCorrect,
}: QuestionResponseOption): QuestionOption {
  return {
    optionId: option?.id,
    questionOptionId,
    text: option?.text,
    weight: parseFloat(weight),
    answerCount,
    isCorrect,
  };
}

export function deserializeQuestion<Context extends QuestionContext>(
  question: QuestionResponse,
  context: Context,
): QuestionDetails<Context> {
  return {
    context,
    __context: "Question",
    questionId: question.id,
    title: question.title,
    type: deserializeQuestionType(question),
    options: question.options.map(deserializeQuestionOption),
    text: question.text,
    timeLimit: question.time_limit,
    jobCategory: question.jobad_category,
    usedTests: question.used_tests?.map((test) => ({
      testId: test.id,
      name: test.name,
      questionSets: test.questionsets.map((set) => ({
        questionSetId: set.questionset,
        order: set.order,
      })),
    })),
    followUpCount: question.followup?.length,
    followUp: question.followup?.map((f) =>
      deserializeFollowUpQuestion(f, question.id),
    ),
    lookFor: question.look_for,
    relevance: question.relevance,
  };
}
export function deserializePreviewQuestion(
  testId: TestId,
  { question, id, order }: PreviewTestResponseQuestion,
): TestQuestionWithOptions {
  return {
    context: "test",
    __context: "TestQuestion",
    id,
    order,
    testId,
    questionId: question.id,
    title: question.title,
    type: deserializeQuestionType(question),
    options: question.options.map(deserializeQuestionOption),
    text: question.text,
    timeLimit: question.time_limit,
    followUpCount: question.followup?.length,
  };
}

export function deserializeQuestionList<Context extends QuestionContext>(
  response: QuestionListResponse,
  context: Context,
): QuestionListItem<Context>[] {
  return response.results.map((question) => ({
    context,
    __context: "Question",
    questionId: question.id,
    employerId: question.company,
    title: question.title,
    text: question.text,
    relevance: question.relevance,
    lookFor: question.look_for,
    type: deserializeQuestionType(question),
    followUpCount: question?.followups_count,
    jobCategory: question.jobad_category,
    tests: Object.values(
      question.questionsets?.reduce(
        (tests, questionSet) => {
          questionSet.tests.forEach((test) => {
            tests[test.id] ??= {
              id: test.id,
              name: test.name,
              questionSetIds: [],
            };

            tests[test.id].questionSetIds.push(questionSet.id);
          });

          return tests;
        },
        {} as Record<QuestionSetId, QuestionListTest>,
      ) ?? {},
    ).map(({ questionSetIds, ...test }) => ({
      ...test,
      questionSetIds: Array.from(new Set(questionSetIds)),
    })),
  }));
}

export const serializeQuestion = (question: Question): QuestionRequest => {
  const { weighted, type, answer_type } = serializeQuestionType(question.type);

  return {
    id: question.id,
    title: question.title,
    text: question.text,
    time_limit: question.timeLimit,
    look_for: question.lookFor || "",
    relevance: question.relevance || "",
    options: question.options,
    jobad_category: question.jobCategory ?? null,
    weighted,
    type,
    answer_type,
  };
};
