import classNames from "classnames";
import { extractSizeProps } from "utility/component-size";
import { isNotEmpty } from "utility/poke";
import { variantClasses } from "utility/variant-classes";
import { BorderBoxProps } from "components/atomic/form-field/interfaces";

const borderColors = {
  light: "border-secondary-100",
  dark: "border-secondary",
};

export default function BorderBox({
  style,
  variant,
  disabled,
  compact,
  children,
  paddingClass = compact ? "p-2" : "px-3 sm:py-4 py-2.5  ",
  borderColor = "",
  vCenter = false,
  ...props
}: BorderBoxProps) {
  const { componentSize } = extractSizeProps(props);

  borderColor = borderColors[borderColor] || borderColor;

  return (
    <div {...props} style={componentSize.apply(style)}>
      <div
        className={classNames(
          paddingClass,
          borderColor,
          disabled ? "bg-secondary-100" : "bg-white",
          `rounded border shadow `,
          `h-full w-full appearance-none`,
          `focus-within:shadow-outline focus-within:border-secondary focus-within:outline-none `,
          variantClasses(
            {
              primary: "text-secondary hover:border-primary",
              secondary: "text-secondary hover:border-secondary",
              white: "text-white hover:border-white",
              blue: "text-blue hover:border-blue",
              disabled:
                "border-secondary-300 bg-silver text-secondary-300 hover:border-secondary-300",
              success:
                "border-success text-secondary focus-within:border-success hover:border-success",
              danger:
                "border-danger text-danger focus-within:border-danger hover:border-danger",
            },
            { variant, disabled }
          )
        )}
      >
        {vCenter ? (
          <div className="flex h-full items-center">{children}</div>
        ) : (
          children
        )}
      </div>
    </div>
  );
}
