import { classnames } from "utility/classnames";
import buttonFactory from "../factory";

const SolidButton = buttonFactory("border-2 rounded", {
  primary: classnames(
    "bg-primary group-hover:bg-primary-accent active:bg-primary-dark",
    "border-primary group-hover:border-primary-accent active:border-primary-dark",
    "fill-white stroke-white text-silver"
  ),
  secondary: classnames(
    "bg-secondary group-hover:bg-secondary-accent active:bg-secondary-dark",
    "border-secondary group-hover:border-secondary-accent active:border-secondary-dark",
    "fill-white stroke-white text-silver"
  ),
  white: classnames(
    "bg-white group-hover:bg-silver active:bg-white",
    "border-white group-hover:border-silver active:border-white",
    "fill-secondary stroke-secondary text-secondary-accent"
  ),
  blue: classnames(
    "bg-blue group-hover:bg-blue-accent active:bg-blue-dark",
    "border-blue group-hover:border-blue-accent active:border-blue-dark",
    "fill-white stroke-white text-silver"
  ),
  success: classnames(
    "bg-success group-hover:bg-success-accent active:bg-success-dark",
    "border-success group-hover:border-success-accent active:border-success-dark",
    "fill-white stroke-white text-silver"
  ),
  danger: classnames(
    "bg-danger group-hover:bg-danger-accent active:bg-danger-dark",
    "border-danger group-hover:border-danger-accent active:border-danger-dark",
    "fill-white stroke-white text-silver"
  ),
  warning: classnames(
    "bg-warning group-hover:bg-warning-accent active:bg-warning-dark",
    "border-warning group-hover:border-warning-accent active:border-warning-dark",
    "fill-white stroke-white text-silver"
  ),
  disabled: classnames(
    "border-secondary-100 bg-secondary-100 text-secondary-light",
    "fill-charcoal-200 stroke-charcoal-200"
  ),
  green: classnames(
    "border-green bg-green text-white",
    "fill-white-200 stroke-white-200"
  ),
  compact: "px-3",
  default: "px-7",
});

export default SolidButton;
