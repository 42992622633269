import { useContext, useEffect, useState } from "react";
import {
  ScreenSizeContext,
  type ScreenSizeAccuracy,
} from "./screen-size-context";
import { discreteUpdate } from "./discrete-update";
import { extendScreenValues } from "./screen-size-retrieval";

interface ScreenHookOptions {
  accuracy?: ScreenSizeAccuracy;
}

export function useScreenSize({
  accuracy = { width: 16, height: 16 },
}: ScreenHookOptions = {}) {
  const globalState = useContext(ScreenSizeContext);
  const [state, setState] = useState(extendScreenValues(globalState));

  useEffect(() => {
    setState((oldValues) => {
      const extendedValues = extendScreenValues(globalState);
      return discreteUpdate(extendedValues, accuracy, oldValues);
    });
  }, [accuracy, globalState]);

  return state;
}
