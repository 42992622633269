type Props = {
  videoRef: any;
  isCameraPermissionGranted: boolean;
  isMicPermissionGranted: boolean;
};

export const streamCamVideo = ({
  videoRef,
  isCameraPermissionGranted,
  isMicPermissionGranted,
}: Props) => {
  var constraints = {
    audio: isMicPermissionGranted ? true : false,
    video: isCameraPermissionGranted ? true : false,
  };
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then(function (mediaStream) {
      if (videoRef) {
        videoRef.srcObject = mediaStream;
        videoRef.onloadedmetadata = function (e) {
          videoRef.play();
        };
      }
    })
    .catch(function (err) {
      console.log(err.name + ": " + err.message);
    }); // always check for errors at the end.
};
