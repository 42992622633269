export const baseurl =
  process.env.NEXT_PUBLIC_API_BASE_URL || "http://localhost:8000/api/backend";

export type Id = number | string;

export type Patch<Type extends { id?: any }> = Partial<Omit<Type, "id">> & {
  id: Type["id"];
};

export type WithId<Type, IdType> = Omit<Type, "id"> & { id: IdType };
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface DeserializationResult<Type> {
  key: string;
  errors: string[];
  data?: Type;
  keep: boolean;
}
