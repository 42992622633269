import { Reference, ReferenceRequest } from "store/applicant";
import { ApplicantReferenceRequest } from "store/applicant/applicant-requests";
import {
  deserializeJobApplicantAnswer,
  deserializeJobApplicantPreferences,
  deserializeJobApplicantReference,
  deserializeJobApplicantSkills,
  JobApplicantId,
} from "store/job-applicant";
import { OwnApplicantTest } from "store/test-taking/interfaces";

import {
  OwnJobApplicant,
  OwnJobApplicantListItem,
} from "./own-job-applicant-models";
import {
  JobApplicantActivity,
  JobApplicantActivityResponse,
  OwnApplicantTestResponse,
  OwnJobApplicantListItemResponse,
  OwnJobApplicantResponse,
} from "./own-job-applicant-responses";
import { RoundId } from "store/round/interfaces";

export function deserializeOwnJobApplicantListItem(
  response: OwnJobApplicantListItemResponse,
): OwnJobApplicantListItem {
  return {
    id: response.id as JobApplicantId,
    createdAt: new Date(response.created_at),
    employerId: response.jobad?.company.id,
    employerName: response.jobad?.company.company.name,
    jobId: response.jobad?.id,
    jobTitle: response.jobad?.title,
    status: response.status,
    hasPendingTasks: response.pending,
    submittedAt: response.submitted_at,
    created_at: response.created_at,
    updated_at: response.updated_at,
  };
}
export function deserializeOwnJobApplicant(
  response: OwnJobApplicantResponse,
): OwnJobApplicant {
  return {
    id: response.id as JobApplicantId,
    currentStep: response.current_step,
    highestStep: response.highest_step,
    references: response.references.map((ref) =>
      deserializeJobApplicantReference(ref),
    ),
    educations: response.educations,
    answers:
      response.answers?.map((a) => deserializeJobApplicantAnswer(a)) || [],
    preferences: response.preferences
      ? deserializeJobApplicantPreferences(response.preferences)
      : undefined,
    experience: response.experience,
    skills: deserializeJobApplicantSkills(response.skills),
    source: response.source,
    jobId: response.jobad,
    resume: response.resume,
    createdAt: new Date(response.created_at),
    updatedAt: new Date(response.updated_at),
    linkedin: response.linkedin,
    address: response.address,
  };
}

export function deserializeOwnApplicantTest(
  test: OwnApplicantTestResponse,
): OwnApplicantTest {
  return {
    testId: test.id,
    status: test.progress.progress.status,
    createdAt: new Date(test.created_at),
    updatedAt: new Date(test.updated_at),
    description: test.description,
    duration: test.duration,
    progress: {
      step: test.progress.progress.step,
      total: test.progress.progress.total,
    },
    name: test.name,
    category: test.category,
    latestAttempt: test.last_update ? new Date(test.last_update) : undefined,
    gameUrl: test.game_url,
  };
}
export function serializeJobApplicantReference(
  ref: Reference,
): ReferenceRequest {
  return {
    company_name: ref.company,
    full_name: ref.fullName ? ref.fullName : undefined,
    contact_info: ref.contact,
    relation: ref.relation,
    email: ref.email,
    phone_number: ref.phoneNumber,
    linkedin: ref.linkedin,
  };
}
export function serializeApplicantReference(
  ref: Reference,
): ApplicantReferenceRequest {
  return {
    reference: serializeJobApplicantReference(ref),
  };
}

export function deserializeJobApplicantActivity(
  activityResponse: JobApplicantActivityResponse,
): JobApplicantActivity {
  if (!activityResponse.round)
    return {
      roundId: "no-round" as RoundId,
      roundName: "Rejected",
      createdAt: new Date(activityResponse.created_at),
      updatedAt: new Date(activityResponse.updated_at),
    };
  return {
    roundId: activityResponse.round.id,
    roundName: activityResponse.round.name,
    createdAt: new Date(activityResponse.created_at),
    updatedAt: new Date(activityResponse.updated_at),
  };
}
