import { AxiosError } from "axios";
import {
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { Folder, FolderId } from "store/folder";
import { useFolders } from "store/folder/folder-manager";
import { PageData, paginateArray, PaginatedList } from "store/pagination";
import { authAxios } from "utility/axios";
import { refactorQueryResult } from "utility/refactor";

import { useMemo } from "react";
import {
  deserializeTestHistoryList,
  TestHistory,
  TestHistoryResponse,
} from "store/applicant";
import { EmployerJobApplicantTestStats } from "store/applicant/applicant-models";
import { JobApplicantTestStatsResponse } from "store/applicant/applicant-responses";
import { deserializeJobApplicantTestStats } from "store/applicant/applicant-serialization";
import { emptyArray } from "utility/empty";
import { useSyncJobApplicantList } from "./cache/job-applicant-list-cache";
import { useSyncJobApplicantItem } from "./cache/job-applicant-retrieve-cache";
import {
  convertFilters,
  defaultJobApplicantFilters,
  JobApplicantFilters,
} from "./job-applicant-filters";
import {
  EmailConversationItem,
  EmailHistoryItem,
  EmailThreadId,
  EmailThreadItem,
  JobApplicant,
  JobApplicantId,
  JobApplicantInfo,
  JobApplicantListItem,
  JobApplicantOverview,
} from "./job-applicant-models";
import {
  EmailConversationResponseItem,
  EmailHistoryResponseItem,
  EmailThreadResponseItem,
  JobApplicantInfoResponse,
  JobApplicantListResponseItem,
  JobApplicantOverviewResponse,
  JobApplicantResponse,
} from "./job-applicant-responses";
import {
  deserializeJobApplicant,
  deserializeEmailConversation,
  deserializeJobApplicantEmailHistoryList,
  deserializeEmailThreadList,
  deserializeJobApplicantInfo,
  deserializeJobApplicantList,
  deserializeJobApplicantOverview,
} from "./job-applicant-serialization";
import { delay } from "utility/delay/delay";

export async function getJobApplicant(jobApplicantId: JobApplicantId) {
  const { data } = await authAxios.get<JobApplicantResponse>(
    `/companies/job-applicant/${jobApplicantId}/`,
  );

  return deserializeJobApplicant(data);
}
export async function getJobApplicantInfo(jobApplicantId: JobApplicantId) {
  const { data } = await authAxios.get<JobApplicantInfoResponse>(
    `/companies/job-applicant/${jobApplicantId}/info`,
  );

  return deserializeJobApplicantInfo(data);
}
export async function getJobApplicantOverview(jobApplicantId: JobApplicantId) {
  const { data } = await authAxios.get<JobApplicantOverviewResponse>(
    `/companies/job-applicant/${jobApplicantId}/overview`,
  );

  return deserializeJobApplicantOverview(data);
}

export function jobApplicantQuery(
  jobApplicantId: JobApplicantId,
): UseQueryOptions<JobApplicant, AxiosError> {
  return {
    queryKey: ["job-applicant", "item", jobApplicantId],
    enabled: Boolean(jobApplicantId),
    queryFn: () => getJobApplicant(jobApplicantId),
  };
}
export function jobApplicantInfoQuery(
  jobApplicantId: JobApplicantId,
): UseQueryOptions<JobApplicantInfo, AxiosError> {
  return {
    queryKey: ["job-applicant", "item", jobApplicantId, "info"],
    enabled: Boolean(jobApplicantId),
    queryFn: () => getJobApplicantInfo(jobApplicantId),
  };
}
export function jobApplicantOverviewQuery(
  jobApplicantId: JobApplicantId,
): UseQueryOptions<JobApplicantOverview, AxiosError> {
  return {
    queryKey: ["job-applicant", "item", jobApplicantId, "overview"],
    enabled: Boolean(jobApplicantId),
    queryFn: () => getJobApplicantOverview(jobApplicantId),
  };
}

export function useJobApplicant(jobApplicantId: JobApplicantId) {
  const query = jobApplicantQuery(jobApplicantId);
  const updateCache = useSyncJobApplicantItem();

  return useQuery<JobApplicant, AxiosError>({
    ...query,
    onSuccess: updateCache,
  });
}
export function useJobApplicantInfo(jobApplicantId: JobApplicantId) {
  return refactorQueryResult(
    "jobApplicantInfo",
    useQuery<JobApplicantInfo, AxiosError>(
      jobApplicantInfoQuery(jobApplicantId),
    ),
  );
}
export function useJobApplicantOverview(jobApplicantId: JobApplicantId) {
  return refactorQueryResult(
    "jobApplicantOverview",
    useQuery<JobApplicantOverview, AxiosError>(
      jobApplicantOverviewQuery(jobApplicantId),
    ),
  );
}

export async function getJobApplicantList(
  filters: JobApplicantFilters,
  folderId: FolderId = filters.folderId,
) {
  const { data } = await authAxios.get<
    PaginatedList<JobApplicantListResponseItem>
  >("/companies/job-applicant/", {
    params: {
      ...convertFilters({ ...filters, folderId }),
    },
  });

  return deserializeJobApplicantList(data, filters);
}

export function jobApplicantListQuery({
  filters: { folderId, ...filters } = {} as JobApplicantFilters,
  enabled = true,
}): UseQueryOptions<PageData<JobApplicantListItem>, AxiosError> {
  filters.combo = filters.combo == "_" ? "" : filters.combo;

  return {
    queryKey: ["job-applicant", "list", folderId, filters],
    queryFn: () => getJobApplicantList(filters, folderId),
    enabled,
  };
}

export function useJobApplicants({
  filters = {} as JobApplicantFilters,
  enabled = true,
} = {}) {
  const query = jobApplicantListQuery({ filters, enabled });
  const updateCache = useSyncJobApplicantList(query.queryKey);

  return refactorQueryResult(
    "jobApplicants",
    useQuery({
      ...query,
      onSuccess: updateCache,
    }),
  );
}

export function useFolderApplicationCounts({
  filters = {} as Omit<JobApplicantFilters, "folderId">,
  enabled = true,
} = {}) {
  const queryClient = useQueryClient();
  const { folders = emptyArray, foldersFetched } = useFolders();

  const getApplicantCount = async (folder: Folder) => {
    // The first page that will be shown by default

    const newFilters = folder
      ? {
          ...defaultJobApplicantFilters,
          ...filters,
          folderId: folder.id,
          sorting: folder.defaultSort,
        }
      : {
          ...defaultJobApplicantFilters,
          ...filters,
        };

    const firstPageQuery = jobApplicantListQuery({
      filters: newFilters,
    });

    const { pagination } = await queryClient.fetchQuery(firstPageQuery);
    return [folder?.id, pagination.count] as [FolderId, number];
  };

  const queries = useQueries(
    [...folders, undefined].map((folder) => ({
      queryKey: ["job-applicant", "count", filters, folder?.id],
      queryFn: () => getApplicantCount(folder),
      enabled: enabled && foldersFetched,
    })),
  );

  const folderCounts = useMemo(
    () =>
      Object.fromEntries(
        queries.map((query) => query.data).filter(Boolean),
      ) as Record<FolderId, number>,
    [queries],
  );

  return folderCounts;
}

export function getTestHistoryQuery(
  jobApplicantId: JobApplicantId,
): UseQueryOptions<TestHistory[]> {
  return {
    queryKey: ["test-history", "list", jobApplicantId as string],
    queryFn: async () => {
      const { data } = await authAxios.get<PaginatedList<TestHistoryResponse>>(
        `/jobs/job-applicant/${jobApplicantId}/test-history/`,
      );
      return deserializeTestHistoryList(data);
    },
    enabled: Boolean(jobApplicantId),
  };
}

export function useTestHistory(jobApplicantId: JobApplicantId) {
  return refactorQueryResult(
    "testHistory",
    useQuery<TestHistory[], AxiosError>(getTestHistoryQuery(jobApplicantId)),
  );
}

export function getTestStatsQuery(
  jobApplicantId: JobApplicantId,
): UseQueryOptions<EmployerJobApplicantTestStats> {
  return {
    queryKey: ["job-applicant", "test-stats", "list", jobApplicantId as string],
    queryFn: () =>
      authAxios
        .get<JobApplicantTestStatsResponse>(
          `/jobs/job-applicant/${jobApplicantId}/stats/`,
        )
        .then((response) => deserializeJobApplicantTestStats(response.data)),
    enabled: Boolean(jobApplicantId),
  };
}

export function useTestStats(jobApplicantId: JobApplicantId) {
  return refactorQueryResult(
    "testStats",
    useQuery<EmployerJobApplicantTestStats, AxiosError>(
      getTestStatsQuery(jobApplicantId),
    ),
  );
}

export function getEmailHistoryQuery(
  jobApplicantId: JobApplicantId,
): UseQueryOptions<EmailHistoryItem[]> {
  return {
    queryKey: [
      "job-applicant",
      "email-history",
      "list",
      jobApplicantId as string,
    ],
    queryFn: async () => {
      const { data } = await authAxios.get<
        PaginatedList<EmailHistoryResponseItem>
      >(`jobs/job-applicant/${jobApplicantId}/email-history/`);
      return deserializeJobApplicantEmailHistoryList(data);
    },
  };
}
export function getEmployerEmailHistoryQuery(queryParams: any = {}) {
  return {
    queryKey: ["email-history", "list", JSON.stringify(queryParams)],
    queryFn: async () => {
      const { data } = await authAxios.get<
        PaginatedList<EmailHistoryResponseItem>
      >(`jobs/email-history/`, {
        params: queryParams,
      });
      return paginateArray(deserializeJobApplicantEmailHistoryList(data), {
        count: data.count,
        previous: data.previous,
        next: data.next,
        offset: queryParams.offset,
        limit: queryParams.limit,
      });
    },
  };
}
export function getJobApplicantEmailThreadsQuery(
  jobApplicantId: JobApplicantId,
): UseQueryOptions<EmailThreadItem[]> {
  return {
    queryKey: [
      "job-applicant",
      "email-threads",
      "list",
      jobApplicantId as string,
    ],
    queryFn: async () => {
      const { data } = await authAxios.get<
        PaginatedList<EmailThreadResponseItem>
      >(`jobs/job-applicant/${jobApplicantId}/email-threads/`);
      const sortedThreads = deserializeEmailThreadList(data).sort(
        (a, b) => b.timestamp.valueOf() - a.timestamp.valueOf(),
      );

      return sortedThreads;
    },
    staleTime: 10 * 60 * 1000,
  };
}
export function getJobApplicantEmailThreadQuery(
  threadId: EmailThreadId,
  jobApplicantId: JobApplicantId,
): UseQueryOptions<EmailConversationItem[]> {
  return {
    queryKey: [
      "job-applicant",
      "email-threads",
      "item",
      jobApplicantId,
      threadId as string,
    ],
    staleTime: 10 * 60 * 1000,
    queryFn: async () => {
      const { data } = await authAxios.get<EmailConversationResponseItem[]>(
        `jobs/job-applicant/${jobApplicantId}/email-threads/${threadId}`,
      );
      return deserializeEmailConversation(data);
    },
  };
}

export function useEmployerEmailHistory(filters: any) {
  return refactorQueryResult(
    "emailHistory",
    useQuery<PageData<EmailHistoryItem>, AxiosError>(
      getEmployerEmailHistoryQuery(filters),
    ),
  );
}
export function useJobApplicantEmailHistory(jobApplicantId: JobApplicantId) {
  return refactorQueryResult(
    "emailHistory",
    useQuery<EmailHistoryItem[], AxiosError>(
      getEmailHistoryQuery(jobApplicantId),
    ),
  );
}

export function useJobApplicantEmailThread(
  threadId: EmailThreadId,
  jobApplicantId: JobApplicantId,
) {
  return refactorQueryResult(
    "emailConversation",
    useQuery<EmailConversationItem[], AxiosError>(
      getJobApplicantEmailThreadQuery(threadId, jobApplicantId),
    ),
  );
}

export function useJobApplicantEmailThreads(jobApplicantId: JobApplicantId) {
  return refactorQueryResult(
    "emailThreads",
    useQuery<EmailThreadItem[], AxiosError>(
      getJobApplicantEmailThreadsQuery(jobApplicantId),
    ),
  );
}
