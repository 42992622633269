import classNames from "classnames";
import { MessageProps } from "components/atomic/form-field/interfaces";
import React from "react";

export function Message({ variant, message, hidden = true }: MessageProps) {
  const messages = Array.isArray(message) ? message ?? [] : [message];

  return (
    <div
      className={classNames(
        "bg-white p-2 text-center",
        "rounded border shadow",
        "group-hover:block",
        hidden ? "hidden" : "block",
        {
          disabled: "border-charcoal-light",
          success: "border-success",
          warning: "border-warning",
          error: "border-danger",
          hint: "border-secondary",
        }[variant]
      )}
    >
      <p
        className={`font-poppins ${
          {
            disabled: "text-charcoal-light",
            success: "text-success",
            warning: "text-warning",
            error: "text-danger",
            hint: "text-secondary",
          }[variant]
        } mt-1 text-xs`}
      >
        {messages.length > 1 ? (
          <ul className="mx-4 list-disc space-y-2 text-left">
            {messages
              .filter((message) => typeof message === "string")
              .map((message, index) => (
                <li key={index}>{message}</li>
              ))}
          </ul>
        ) : (
          messages[0]
        )}
      </p>
      <div className="arrow absolute h-2 w-2">
        <div
          className={classNames(
            "absolute h-2 w-2 bg-white",
            {
              disabled: "border-charcoal-light",
              success: "border-success",
              warning: "border-warning",
              error: "border-danger",
              hint: "border-secondary",
            }[variant]
          )}
        ></div>
      </div>
    </div>
  );
}
