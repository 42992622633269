import { useInterval } from "hooks/timer";
import { useEffect, useState } from "react";

import { StyleProps } from "../interfaces";

interface LoadingIconProps extends StyleProps {
  height?: string;
  width?: string;
}

export function LoadingIcon({ height, width, ...props }: LoadingIconProps) {
  const { setInterval } = useInterval();
  const [dots, setDots] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setDots((dots) => (dots + 1) % 4);
    }, 500);
  }, [setInterval]);

  return (
    <div {...props}>
      <div className={`flex w-fit items-center justify-center gap-2`}>
        <div
          style={{ borderTopColor: "transparent", height, width }}
          className="h-6 w-6 animate-slow-spin rounded-full border-4 border-solid border-primary-light"
        ></div>
      </div>
    </div>
  );
}
