import { CSSProperties } from "react";
import { ComponentSizeProps } from "./interfaces";

export default class ComponentSize<OtherProps extends object>
  implements ComponentSizeProps
{
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  otherProps: Omit<OtherProps, keyof ComponentSizeProps>;

  constructor({
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    ...props
  }: ComponentSizeProps & OtherProps) {
    this.width = width;
    this.height = height;
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
    this.minWidth = minWidth;
    this.minHeight = minHeight;
    this.otherProps = props;
  }

  isDefined(value: string | number | undefined) {
    if (value === undefined) return false;
    if (typeof value == "string") return value.trim() !== "";
    return true;
  }

  apply(style?: CSSProperties) {
    const newStyle = style === undefined ? {} : { ...style };

    if (this.isDefined(this.width)) newStyle.width = this.width;
    if (this.isDefined(this.height)) newStyle.height = this.height;
    if (this.isDefined(this.minWidth)) newStyle.minWidth = this.minWidth;
    if (this.isDefined(this.minHeight)) newStyle.minHeight = this.minHeight;
    if (this.isDefined(this.maxWidth)) newStyle.maxWidth = this.maxWidth;
    if (this.isDefined(this.maxHeight)) newStyle.maxHeight = this.maxHeight;

    return newStyle;
  }

  get props() {
    return { width: this.width, height: this.height };
  }
}

export interface ExtractedSize<OtherProps extends object> {
  componentSize: ComponentSize<OtherProps>;
  props: ComponentSizeProps;
  otherProps: Omit<OtherProps, keyof ComponentSizeProps>;
}

export function extractSizeProps<OtherProps extends object>(
  props: ComponentSizeProps & OtherProps
): ExtractedSize<OtherProps> {
  const componentSize = new ComponentSize<OtherProps>(props);
  return {
    componentSize,
    props: componentSize.props,
    otherProps: componentSize.otherProps,
  };
}
