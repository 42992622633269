import { QueryClient } from "react-query";
import { FolderId } from "store/folder";
import { JobApplicant, JobApplicantListItem } from "store/job-applicant";

type Props = {
  queryClient: QueryClient;
  jobApplicant: JobApplicantListItem | JobApplicant;
  folderId: FolderId;
  newStarred: boolean;
};
export function syncJobApplicantStarredCache({
  queryClient,
  jobApplicant,
  folderId,
  newStarred,
}: Props) {
  const listQueries = queryClient.getQueriesData<JobApplicantListItem[]>([
    "job-applicant",
    "list",
  ]);
  const detailQueries = queryClient.getQueriesData<JobApplicant>([
    "job-applicant",
    "item",
    jobApplicant.id,
  ]);

  listQueries.forEach(([key, data]) => {
    data?.forEach((ja) => {
      if (jobApplicant.id === ja.id) {
        jobApplicant.starred = newStarred;
      }
    });

    queryClient.setQueryData(key, data);
  });

  detailQueries.forEach(([key, data]) => {
    data.starred = newStarred;
    queryClient.setQueryData(key, data);
  });
}
