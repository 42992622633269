import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { PublicRoutes } from "routes/routes";
import {
  accessToken,
  clearLoginStatus,
  getLoginStatus,
  refreshAccessToken,
} from "store/auth";
import { useUser } from "store/user";
import { decodeToken } from "utility/auth";
import { setSentryUser } from "utility/sentry";

interface UseAuthOptions {
  redirect?: boolean;
  enabled?: boolean;
}

export const useAuth = ({
  redirect = true,
  enabled = true,
}: UseAuthOptions = {}) => {
  const router = useRouter();

  const isFirstRender = !accessToken;
  const isLoggedIn = getLoginStatus();

  const { isFetching: isRefreshingToken } = useQuery({
    queryFn: () => refreshAccessToken(),
    enabled: enabled && isLoggedIn && isFirstRender,
    staleTime: Infinity,
  });

  const isAuthenticating = enabled && isLoggedIn && isRefreshingToken;
  const isAuthenticated = !isRefreshingToken && !isFirstRender;

  if (enabled && !isAuthenticating && !isAuthenticated) {
    if (isLoggedIn) {
      setSentryUser(null);
      clearLoginStatus();
    }

    if (redirect && typeof window !== "undefined") {
      router.push({
        pathname: PublicRoutes.Login,
        query: { next: router.asPath },
      });
    }
  }

  const { data: user, isFetched: userFetched } = useUser({
    enabled: enabled && isAuthenticated,
  });

  return {
    isAuthenticating: isAuthenticating || (isAuthenticated && !userFetched),
    isAuthenticated,
    hijacker: decodeToken(accessToken).payload?.["admin"],
    user,
  };
};
