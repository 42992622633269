export const emptyArray = [];
export const emptyObject = {};

export const noopFunction = () => {};
export const emptyFunction = noopFunction;
export const trueFunction = () => true;
export const falseFunction = () => false;
export const nullFunction = () => null;
export const disableEvent = (event) => {
  event.stopPropagation();
  event.preventDefault();
  return false;
};
