import { DialogManager } from "components/atomic/dialog";
import { getAxiosResponseDialogMessage } from "utility/dialog/get-axios-config-message";
import { DialogAxiosResponse } from "./interfaces";
export const responseInterceptor = (r: DialogAxiosResponse) => {
  const { hideSuccessDialog, timestamp, method, customIdentifier } = r.config;

  if (typeof window === "undefined") return r;

  const dialog = window["dialog"] as DialogManager;
  customIdentifier && dialog.closeByIdentifier(customIdentifier);
  dialog.closeByIdentifier("axios-wait" + timestamp);
  if (!hideSuccessDialog && method !== "get") {
    dialog.toast({
      message: getAxiosResponseDialogMessage(r.config),
      variant: "success",
      timeout: 3000,
      identifier: customIdentifier || "axios-success" + timestamp,
    });
  }

  return r;
};
