import { contactToString, Reference } from "store/applicant";
import { deserializeJobAd } from "store/job/deserializer";
import { JobSkillResponse } from "store/job/responses";
import {
  paginateArray,
  PaginatedList,
  PaginationFilters,
} from "store/pagination";
import { Skill } from "store/skill/interfaces";
import { deserializeUser } from "store/user";
import { safeParseDate } from "utility/dates";

import {
  EmailConversationItem,
  EmailHistoryItem,
  EmailThread,
  EmailThreadItem,
  JobApplicant,
  JobApplicantAnswer,
  JobApplicantInfo,
  JobApplicantListItem,
  JobApplicantOverview,
  JobApplicantPreferences,
} from "./job-applicant-models";
import {
  JobApplicantAnswerRequest,
  JobApplicantPreferencesRequest,
} from "./job-applicant-requests";
import {
  EmailConversationResponseItem,
  EmailHistoryResponseItem,
  EmailThreadResponse,
  EmailThreadResponseItem,
  JobApplicantAnswerResponse,
  JobApplicantInfoResponse,
  JobApplicantListResponseItem,
  JobApplicantOverviewResponse,
  JobApplicantPreferencesResponse,
  JobApplicantReferenceResponse,
  JobApplicantResponse,
} from "./job-applicant-responses";
import { deserializeJobApplicantActivity } from "store/own-job-applicant/own-job-applicant-serialization";

export function deserializeJobApplicantList(
  response: PaginatedList<JobApplicantListResponseItem>,
  { offset, limit }: PaginationFilters,
) {
  return paginateArray(
    response.results.map((responseItem): JobApplicantListItem => {
      return {
        id: responseItem.id,
        applicantId: responseItem.applicant,
        userId: responseItem.user,
        address: responseItem.address,
        firstName: responseItem.first_name,
        lastName: responseItem.last_name,
        fullName: [responseItem.first_name, responseItem.last_name].join(" "),
        email: responseItem.email,
        phScore: responseItem.perfectly_hired_score,
        phScoreVariant: "success",
        job: {
          id: responseItem.jobad,
          title: responseItem.jobad_title,
          requirements: {
            degree: responseItem.degree,
            hoursPerWeek: responseItem.hours_required,
            yearsOfExperience: responseItem.experience_required,
          },
          salary: {
            type: responseItem.salary_type,
            hourly_min: responseItem.job_hourly_min,
            hourly_max: responseItem.job_hourly_max,
            annual_min: responseItem.job_annual_min,
            annual_max: responseItem.job_annual_max,
          },
          archived: responseItem.jobad_archived,
        },
        status: {
          roundId: responseItem.round,
          name: responseItem.round_name,
          order: responseItem.round_order,
        },
        workedFor: responseItem.worked_for.map((company) => ({
          name: company.name,
          description: company.description,
          logo: company.logo,
          employeeCount: company.employee_count,
          tier: company.tier,
        })),
        education: responseItem.educations?.map(({ education }) => education),
        experience: responseItem.applicant_experience,
        hoursPerWeek: responseItem.applicant_hours,
        salary: {
          exists:
            Boolean(responseItem.hourly_pay) ||
            Boolean(responseItem.annual_pay),
          hasHourly: Boolean(responseItem.hourly_pay),
          hasAnnual: Boolean(responseItem.annual_pay),
          hourly: responseItem.calculated_hourly_pay,
          annual: responseItem.calculated_annual_pay,
          magnitude: responseItem.salary_magnitude,
        },
        starred: responseItem.starred,
        inWorkflow: responseItem.in_workflow,
        date: new Date(responseItem.created_at),
        folderId: responseItem.folder,
        forgotten: responseItem.forgotten,
        reminderCounter: responseItem.reminder_counter,
        lastReminderDate: responseItem.last_reminder_date
          ? new Date(responseItem.last_reminder_date)
          : undefined,
        hasNotes: responseItem.has_notes,
      };
    }),
    {
      count: response.count,
      previous: response.previous,
      next: response.next,
      offset,
      limit,
    },
  );
}

export function deserializeJobApplicant(
  response: JobApplicantResponse,
): JobApplicant {
  return {
    id: response.id,
    fullName: [response.owner?.first_name, response.owner?.last_name].join(" "),
    firstName: response.owner?.first_name,
    lastName: response.owner.last_name,
    applicantId: response.owner.applicant.id,
    userId: response.owner.pk,
    references: response.references.map(deserializeJobApplicantReference),
    answers:
      response.answers?.map((a) => deserializeJobApplicantAnswer(a)) || [],
    preferences: response.preferences
      ? deserializeJobApplicantPreferences(response.preferences)
      : undefined,
    experience: response.experience,
    workedFor: [],
    folderId: response?._folder,
    status: {
      roundId: response.status?.id,
      name: response.status?.name,
      order: response.status?.order,
    },
    isDisqualificationAllowed: response.is_disqualification_allowed,
    starred: response.starred,
    source: response.source,
    notes: response.notes,
    owner: response.owner ? deserializeUser(response.owner) : null,
    job: response.jobad ? deserializeJobAd(response.jobad) : null,
    resume: response.resume,
    contentType: response.content_type,
    createdAt: safeParseDate(response.created_at),
    updatedAt: safeParseDate(response.updated_at),
    inWorkflow: response.in_workflow || false,
    phScore: response.perfectly_hired_score,
    assessmentScores: response.assessments_scores.map(
      ({ assessment: assessmentName, ...score }) => ({
        ...score,
        assessmentName,
      }),
    ),
    keywordsFrequency: response.keywords_frequency,
    avgTimePerWorkplace: response.avg_time_per_workplace,
    attentionToDetailRating: response.attention_to_detail_rating,
    desiredCompanies: response.desired_companies,
    skills: deserializeJobApplicantSkills(response.skills),
    cognitiveScore: Math.max(0, Math.min(100, response.cognitive_score)),
    qualityScore: Math.max(0, Math.min(100, response.applicant_quality_score)),
    companyFitScore: Math.max(0, Math.min(100, response.company_fit_score)),
    forgotten: response.forgotten,
    reminderCounter: response.reminder_counter,
    lastReminderDate: safeParseDate(response.last_reminder_date),
    address: response.address,
    educations: response.educations,
    linkedin: response.linkedin,
  };
}
export function deserializeJobApplicantInfo(
  response: JobApplicantInfoResponse,
): JobApplicantInfo {
  return {
    id: response.id,
    fullName: [response.owner?.first_name, response.owner?.last_name].join(" "),
    firstName: response.owner?.first_name,
    lastName: response.owner.last_name,
    email: response.owner?.email,
    phone: response.owner?.contact?.phone_number,
    applicantId: response.owner.applicant.id,
    userId: response.owner.pk,
    preferences: response.preferences
      ? deserializeJobApplicantPreferences(response.preferences)
      : undefined,
    folderId: response?._folder,
    status: {
      roundId: response.status?.id,
      name: response.status?.name,
      order: response.status?.order,
    },
    starred: response.starred,
    source: response.source,
    job: response.jobad ? deserializeJobAd(response.jobad) : null,
    resume: response.resume,
    createdAt: safeParseDate(response.created_at),
    updatedAt: safeParseDate(response.updated_at),
    inWorkflow: response.in_workflow || false,
    phScore: response.perfectly_hired_score,
    forgotten: response.forgotten,
    reminderCounter: response.reminder_counter,
    lastReminderDate: safeParseDate(response.last_reminder_date),
    address: response.address,
    linkedin:
      response.linkedin &&
      (response.linkedin.startsWith("http")
        ? response.linkedin
        : `https://${response.linkedin}`),
    activity: response.round_history.map(deserializeJobApplicantActivity),
  };
}
export function deserializeJobApplicantOverview(
  response: JobApplicantOverviewResponse,
): JobApplicantOverview {
  return {
    id: response.id,
    references: response.references.map(deserializeJobApplicantReference),
    answers:
      response.answers?.map((a) => deserializeJobApplicantAnswer(a)) || [],
    experience: response.experience,

    source: response.source,
    createdAt: safeParseDate(response.created_at),
    updatedAt: safeParseDate(response.updated_at),
    phScore: response.perfectly_hired_score,
    assessmentScores: response.assessments_scores.map(
      ({ assessment: assessmentName, ...score }) => ({
        ...score,
        assessmentName,
      }),
    ),
    keywordsFrequency: response.keywords_frequency,
    avgTimePerWorkplace: response.avg_time_per_workplace,
    attentionToDetailRating: response.attention_to_detail_rating,
    cognitiveScore: Math.max(0, Math.min(100, response.cognitive_score)),
    qualityScore: Math.max(0, Math.min(100, response.applicant_quality_score)),
    companyFitScore: Math.max(0, Math.min(100, response.company_fit_score)),
    educations: response.educations,
    submittedAt: response.submitted_at ? new Date(response.submitted_at) : null,
    skills: deserializeJobApplicantSkills(response.skills),
    desiredCompanies: response.desired_companies,
    overallEvaluation: response.overall_evaluation,
    pros: response.pros || [],
    cons: response.cons || [],
  };
}

export function deserializeJobApplicantSkills(
  response: JobSkillResponse[],
): Skill[] {
  if (typeof response[0] === "string") {
    return response as unknown as Skill[];
  }
  const skillNames = response.map((s) => s?.skill?.name);
  const filtered = response.filter(
    (s, index) => !skillNames.includes(s?.skill?.name, index + 1),
  );
  return filtered.map((s) => s.skill);
}

export function deserializeJobApplicantPreferences(
  response: JobApplicantPreferencesResponse,
): JobApplicantPreferences {
  return {
    id: response.id,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    preferredAnnualPay: response.preferred_annual_pay,
    preferredHourlyPay:
      response.preferred_hourly_pay &&
      parseFloat(response.preferred_hourly_pay),
    preferredLocations: response.preferred_locations,
    preferredPositions: response.preferred_positions,
    workingHours: response.number_of_hours,
  };
}

export function deserializeJobApplicantAnswer(
  response: JobApplicantAnswerResponse,
): JobApplicantAnswer {
  return {
    id: response.id,
    question: response.question,
    answerText: response.answer_text.replace(/,$/, ""),
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    answerFile: response.answer_file,
    answerLink: response.answer_link,
  };
}

export function serializeJobApplicantAnswer(
  data: JobApplicantAnswer,
): JobApplicantAnswerRequest {
  return {
    id: data.id,
    question: data.question,
    answer_text: data.answerText,
    created_at: data.createdAt,
    updated_at: data.updatedAt,
    answer_file: data.answerFile,
    answer_link: data.answerLink,
  };
}

export function serializeJobApplicantPreferences(
  data: JobApplicantPreferences,
): JobApplicantPreferencesRequest {
  return {
    id: data.id,
    created_at: data.createdAt,
    updated_at: data.updatedAt,
    preferred_annual_pay: data.preferredAnnualPay,
    preferred_hourly_pay: data.preferredHourlyPay,
    preferred_locations: data.preferredLocations,
    preferred_positions: data.preferredPositions,
    number_of_hours: data.workingHours,
  };
}
export function deserializeJobApplicantEmailHistoryList(
  response: PaginatedList<EmailHistoryResponseItem>,
): EmailHistoryItem[] {
  return response.results.map((responseItem) => ({
    id: responseItem.id,
    createdAt: new Date(responseItem.created_at),
    updatedAt: new Date(responseItem.updated_at),
    recipient: responseItem.recipient,
    recipientName: responseItem.recipient_name,
    subject: responseItem.subject,
    body: responseItem.body,
    isDelivered: responseItem.is_delivered,
    isRead: responseItem.is_read,
    isSent: responseItem.is_sent,
    scheduledOn: responseItem.scheduled_on
      ? new Date(responseItem.scheduled_on)
      : null,
    threadId: responseItem.thread,
  }));
}

export function deserializeEmailThreadList(
  response: PaginatedList<EmailThreadResponseItem>,
): EmailThreadItem[] {
  return response.results.map((responseItem) => ({
    id: responseItem.id,
    topic: responseItem.topic,
    jobId: responseItem.job,
    applicantEmail: responseItem.applicant_email,
    applicantName: responseItem.applicant_name,
    starred: responseItem.starred,
    company: responseItem.company,
    isRead: responseItem.is_read,
    isReadByApplicant: responseItem.is_read_by_applicant,
    createdAt: new Date(responseItem.created_at),
    updatedAt: new Date(responseItem.updated_at),
    receivedCount: responseItem.received_count,
    sentCount: responseItem.sent_count,
    pendingCount: responseItem.pending_count,
    timestamp: new Date(responseItem.timestamp),
  }));
}
export function deserializeEmailThread(
  threadResponse: EmailThreadResponse,
): EmailThread {
  return {
    id: threadResponse.id,
    applicantEmail: threadResponse.applicant_email,
    applicantName: threadResponse.applicant_name,
    company: threadResponse.company,
    createdAt: threadResponse.created_at,
    isRead: threadResponse.is_read,
    jobAd: threadResponse.jobad,
    pendingCount: threadResponse.pending_count,
    receivedCount: threadResponse.received_count,
    sentCount: threadResponse.sent_count,
    starred: threadResponse.starred,
    timestamp: threadResponse.timestamp,
    topic: threadResponse.topic,
    updatedAt: threadResponse.updated_at,
    jobApplicantId: threadResponse.job_applicant,
    conversationEmails: deserializeEmailConversation(
      threadResponse.conversation_emails,
    ),
  };
}

export function deserializeEmailConversation(
  response: EmailConversationResponseItem[],
): EmailConversationItem[] {
  return response.map((responseItem) => ({
    id: responseItem.id,
    sender: responseItem.sender,
    recipient: responseItem.recipient,
    subject: responseItem.subject,
    body: responseItem.body,
    timestamp: new Date(responseItem.timestamp),
    isRead: responseItem.is_read,
    isSent: responseItem.is_sent,
  }));
}

export function deserializeJobApplicantReference(
  response: JobApplicantReferenceResponse,
): Reference {
  const {
    company,
    contact,
    full_name,
    company_name,
    contact_info,
    relation,
    email,
    phone_number,
    linkedin,
    status,
    shared_reference_check_id,
  } = response.reference;

  return {
    company: company_name || company?.name || "",
    fullName: full_name || "",
    contact: contact_info || contactToString(contact) || "",
    relation: relation || "",
    email: email || "",
    phoneNumber: phone_number || "",
    linkedin: linkedin || "",
    applicantReferenceId: response?.id,
    status,
    sharedReferenceCheckId: shared_reference_check_id,
  };
}
