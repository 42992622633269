import classNames from "classnames";
import { Variant, VariantClasses, VariantProps } from ".";
import { BroadVariant } from "./interfaces";

function variantMapping(input: BroadVariant): Variant {
  return {
    primary: "primary",
    secondary: "secondary",
    white: "white",
    blue: "blue",
    message: "primary",
    hint: "primary",
    success: "success",
    error: "danger",
    danger: "danger",
    warning: "warning",
    disabled: "disabled",
    green: "green",
  }[input];
}

export default function variantClasses(
  classes: VariantClasses,
  props: VariantProps
) {
  if (props.variant === "none") return "";
  const variant: BroadVariant = props.disabled ? "disabled" : props.variant;
  return classNames(
    classes[variantMapping(variant) || "primary"] || classes["primary"],
    props.compact ? classes["compact"] : classes["default"]
  );
}
