import { useCallback } from "react";
import {
  FetchQueryOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { PaginatedList } from "store/pagination";
import { authAxios } from "utility/axios";
import { refactorQueryResult } from "utility/refactor";

import { Folder, FolderId } from "./folder-models";
import { CreateFolderRequest, UpdateFolderRequest } from "./folder-requests";
import { FolderResponse } from "./folder-responses";
import { deserializeFolder } from "./folder-serialization";

export function getFoldersQuery(options: UseQueryOptions<Folder[]> = {}) {
  return {
    queryKey: ["folder", "list"],
    queryFn: getFolders,
    ...options,
  };
}

export function useFolders(options: UseQueryOptions<Folder[]> = {}) {
  return refactorQueryResult(
    "folders",
    useQuery<Folder[]>(getFoldersQuery(options))
  );
}

export function useFolderManager() {
  const queryClient = useQueryClient();

  const invalidateQueries = (folderId?: FolderId) => {
    queryClient.invalidateQueries(["folder", "list"]);
    queryClient.invalidateQueries(["job-applicant", "groups"]);
    folderId && queryClient.invalidateQueries(["folder", "item", folderId]);
  };

  const retrieve = useCallback(
    (folderId: FolderId): FetchQueryOptions<Folder> => ({
      queryKey: ["folder", "item", folderId],
      queryFn: () => getFolder(folderId),
    }),
    []
  );

  const update = useMutation(async (folderData: UpdateFolderRequest) => {
    const { id, dialogOptions = {}, ...restData } = folderData;
    const { data } = await authAxios.patch(
      `/jobtests/folder/${id}/`,
      restData,
      dialogOptions
    );
    invalidateQueries(data.id);
    return data;
  });

  const updateListOrder = useCallback(
    (folders: Folder[]) => {
      folders.forEach(async (newFolder, index) => {
        if (newFolder.order !== index) {
          await update.mutateAsync({
            ...newFolder,
            order: index + 5,
            dialogOptions: {
              hideSuccessDialog: true,
              hideErrorDialog: true,
              hideWaitDialog: true,
            },
          });
        }
      });
    },
    [update]
  );

  return {
    retrieve,
    create: useMutation(async (folderData: CreateFolderRequest) => {
      const response = await authAxios.post(`/jobtests/folder/`, folderData, {
        waitMessage: "Creating Folder...",
        successMessage: "Created",
        errorMessage: "Action couldn't be completed",
      });
      invalidateQueries(response.data.id);
      return response.data;
    }),
    update,
    updateListOrder,
    delete: async (folder: FolderId) => {
      await authAxios.delete(`/jobtests/folder/${folder}/`);
      invalidateQueries(folder);
    },
    invalidateQueries,
  };
}

export async function getFolder(id: FolderId) {
  if (!id) return;
  const { data } = await authAxios.get(`/jobtests/folder/${id}/`);

  return data;
}

export async function getFolders() {
  const {
    data: { results: folders },
  } = await authAxios.get<PaginatedList<FolderResponse>>(`/jobtests/folder/`);

  const archivedFolder: FolderResponse = {
    id: "archived" as FolderId,
    name: "Archived",
    is_singleton: true,
    order: 3.5,
  };

  folders.push(archivedFolder);

  return folders
    .sort((a, b) => a.order - b.order)
    .map((f) => deserializeFolder(f));
}
