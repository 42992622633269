import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useDialog } from "components/atomic/dialog";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_KEY ||
    "pk_test_51JNW5oSA6CT9S3COdAZIFHLbYZk0ILLX4ZlcjpGgR3vgMdfNMFoyfpapCrr5pbBeuu76jKtH5RTys1TyoVrQNVph00tdENfvIh",
);
type Props = {
  fetchClientSecret: () => Promise<string>;
  handleComplete: () => void;
  message?: string;
};
export const CheckoutPage = ({
  fetchClientSecret,
  handleComplete,
  message = "Payment successful",
}: Props) => {
  const dialog = useDialog();
  const options = {
    fetchClientSecret,
    onComplete: async () => {
      handleComplete();
      dialog.closeByIdentifier("checkout-dialog");
      dialog.toast({
        message,
        variant: "success",
        identifier: "payment-success-dialog",
        timeout: 4000,
      });
    },
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
