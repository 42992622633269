import { createContext } from "react";

export interface ScreenSizeState {
  width: number;
  height: number;
  canHover: boolean;
}

export type ScreenSizeAccuracy = {
  width?: number | null;
  height?: number | null;
};

const defaultScreenSizeState: ScreenSizeState = {
  width: 0,
  height: 0,
  canHover: false,
};

export const ScreenSizeContext = createContext<ScreenSizeState>(
  defaultScreenSizeState
);
