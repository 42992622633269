import { FormField } from "components/atomic/form-field";
import { extractSizeProps } from "utility/component-size";
import { AutoComplete } from "./auto-complete";
import { SelectProps } from "./interfaces";
import { SimpleSelect } from "./simple-select";

export function Select<Option extends any>({
  className,
  style,
  disabled,
  ...props
}: SelectProps<Option>) {
  const { componentSize, otherProps } = extractSizeProps(props);
  return (
    <div className={className} style={componentSize.apply(style)}>
      {typeof props.options == "function" ? (
        <FormField<SelectProps<Option>>
          element={AutoComplete}
          disabled={disabled}
          {...otherProps}
        />
      ) : (
        <FormField<SelectProps<Option>>
          element={SimpleSelect}
          disabled={disabled}
          {...otherProps}
        />
      )}
    </div>
  );
}
