import { useCallback } from "react";

/**
 * A replacement for `useCallback` that makes it more convenient to
 * define bulk actions. The function returned by this hook will accept
 * either a single item or an array of items, and will call the action
 * function with an array of items.
 *
 * @param action
 * @param deps
 * @returns
 */
export function useBulkAction<T>(
  action: (items: T[]) => Promise<void>,
  deps: any[] = [action]
) {
  return useCallback(
    (items: T[] | T) => {
      const itemsArray = Array.isArray(items) ? items : [items];

      if (!itemsArray.length) {
        return;
      }

      return action(itemsArray);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
}
