var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fVXplyHYvThuw5-Foz08J"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import { getObfuscate } from "./src/utility/obfuscate";
import buildId from "./public/app/build-id.json";

function crypto(input) {
  const key = "saxz97vzd98a9032lkj21jdsa";

  return input
    .split("")
    .map((char, index) => {
      const code = char.charCodeAt(0);
      const keyCode = key.charCodeAt(index % key.length);
      return String.fromCharCode(code ^ keyCode);
    })
    .join("");
}

const encrypter = (input) => btoa(crypto(input));
const obfuscate = getObfuscate({ encrypter });

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const replacements = {
  pass: "p*",
  auth: "a*",
  token: "t*",
  secret: "s*",
  access: "ac*",
  refresh: "rf*",
  register: "rg*",
};

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://e8664d2fe6304247b0d6da13360b9dc4@o156377.ingest.sentry.io/6145741",
  // Adjust this value in production, or use tracesSampler for greater control
  release: buildId,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === "xhr") {
      breadcrumb.data.body = Object.keys(replacements).reduce(
        (result, key) => result.replaceAll(key, replacements[key]),
        obfuscate(hint.xhr.__sentry_xhr__.body, Object.keys(replacements))
      );
      breadcrumb.data.response = Object.keys(replacements).reduce(
        (result, key) => result.replaceAll(key, replacements[key]),
        obfuscate(hint.xhr.response, Object.keys(replacements))
      );
      breadcrumb.data.url = Object.keys(replacements).reduce(
        (result, key) => result.replaceAll(`/${key}`, `/${replacements[key]}`),
        hint.xhr.responseURL
      );
    }
    return breadcrumb;
  },
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV || "local",
  tracesSampleRate: 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
