import { useRouter } from "next/router";
import { createContext, ReactNode, useEffect, useState } from "react";
import { DialogContextInterface, DialogRecord, useDialog } from ".";

export const DialogContext = createContext<DialogContextInterface>({
  dialogRecords: [],
  setDialogRecords: () => {},
});

interface DialogProviderProps {
  children: ReactNode;
  throughRoutes?: string[] | ((route: string) => boolean);
}

export function DialogProvider({
  children,
  throughRoutes,
}: DialogProviderProps) {
  const router = useRouter();

  const [dialogRecords, setDialogRecords] = useState<DialogRecord[]>([]);
  useEffect(() => {
    const validRecords = dialogRecords.filter(
      (record) =>
        record.path === router.pathname ||
        (Array.isArray(throughRoutes) && throughRoutes.includes(record.path)) ||
        (typeof throughRoutes === "function" && throughRoutes(record.path)),
    );

    setDialogRecords(validRecords);
    // It is enough that this runs whenever the page changes. Further,
    // specifying dialogRecords will also put us in an infinite loop,
    // therefore:
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, setDialogRecords]);

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return <></>;

  return (
    <DialogContext.Provider value={{ dialogRecords, setDialogRecords }}>
      {children}
    </DialogContext.Provider>
  );
}

export function DialogRoot() {
  const dialogManager = useDialog();
  useEffect(() => {
    if (typeof window !== "undefined") {
      window["dialog"] = dialogManager;
    }
  }, [dialogManager]);

  return (
    <div id="[--dialog-root]">
      {dialogManager.all.map((dialog) => (
        <div key={dialog.id}>
          <dialog.Element
            {...{
              record: dialog,
              ...dialog.props,
            }}
          />
        </div>
      ))}
    </div>
  );
}
