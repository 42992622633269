import { AdminLogo } from "components/brand";
import Loader from "components/loader/loader";
import { AdminMenu } from "components/menu";
import { useUserStatus } from "hooks/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import NotFoundPage from "pages/404";
import { accessToken } from "store/auth";
import { UserType } from "store/user";
import { decodeToken } from "utility/auth";
import { sendErrorToSentry } from "utility/sentry";

import { LayoutProps, AuthLayout } from "./";

export function AdminLayout({ children }: LayoutProps) {
  const router = useRouter();
  const { userIsFetched, userIsValid } = useUserStatus([UserType.Admin]);

  if (!userIsFetched) return <Loader />;
  if (!userIsValid && router.pathname !== "/admin/blank") {
    const { header, payload, signature } = decodeToken(accessToken);
    sendErrorToSentry(new Error("User is not valid for admin layout"), {
      extra: {
        userIsFetched,
        userIsValid,
        pathname: router.pathname,
        t: {
          header,
          payload,
          signature,
        },
      },
    });

    return (
      <AuthLayout>
        <NotFoundPage />
      </AuthLayout>
    );
  }

  return (
    <div className="flex min-h-screen animate-fade-in flex-col bg-silver">
      <header style={{ height: 96 }} className="z-5 w-full bg-white">
        <div className="pc-container flex h-full items-center justify-between">
          <Link href="/">
            <AdminLogo height="34px" />
          </Link>
          <AdminMenu />
        </div>
      </header>
      <div className="flex flex-1 items-stretch">
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
}
