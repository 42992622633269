export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function truth(condition: () => boolean, interval = 100) {
  return new Promise<void>((result) =>
    setTimeout(() => {
      if (condition()) result();
      else truth(condition, interval).then(result);
    }, interval)
  );
}
