import { JobId } from "store/job/interfaces";
import { RoundId } from "store/round/interfaces";
import { safeParseMicroDate } from "utility/dates";

import {
  EmployerNotification,
  EmployerNotificationKey,
  EmployerNotificationList,
} from "./notification-models";
import {
  EmployerNotificationListResponse,
  EmployerNotificationListResponseItem,
} from "./notification-responses";
import { sendErrorToSentry } from "utility/sentry";
import { DeserializationResult } from "store/ph-api";

export function deserializeEmployerNotification(
  notification: EmployerNotificationListResponseItem
): DeserializationResult<EmployerNotification> {
  const seenAt = safeParseMicroDate(notification.seen_at);

  const key = [
    notification.type,
    seenAt?.toUrlFriendlyString(),
    notification.jobad?.id,
    notification.round?.id,
  ].join(" ") as EmployerNotificationKey;

  let keep = true;
  const errors = [];

  const ids = new Set(notification.ids);
  if (ids.size !== notification.ids.length) {
    errors.push("Notification has duplicate ids.");
  }

  const userIds = new Set(notification.users?.map((user) => user.id) || []);
  if (userIds.size !== notification.users?.length) {
    errors.push("Notification has duplicate users.");
  }
  const users =
    notification.users
      ?.filter((user) => userIds.has(user.id))
      ?.map((user) => ({
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
      })) || [];

  // Notifications that don't have notes return note objects with null ids.
  const realNotes = notification.notes?.filter((note) => note.id);
  const noteIds = new Set(realNotes?.map((note) => note.id) || []);
  if (noteIds.size !== realNotes?.length) {
    errors.push("Notification has duplicate notes.");
  }
  const notes =
    realNotes
      ?.filter((note) => noteIds.has(note.id))
      ?.map((note) => ({
        id: note.id,
        userId: note.user_id,
        jobApplicantId: note.job_applicant_id,
        firstName: note.first_name,
        lastName: note.last_name,
      })) || [];

  if (!notification.jobad?.id) {
    errors.push("Notification is missing jobad.");
    keep = false;
  }

  if (!notification.round?.id && notification.type !== "mentioned-in-note") {
    errors.push("Notification is missing round.");
    keep = false;
  }

  return {
    key,
    errors,
    data: {
      key,
      type: notification.type,
      job: notification.jobad && {
        id: notification.jobad.id as JobId,
        title: notification.jobad.title,
      },
      round: notification.round && {
        id: notification.round.id as RoundId,
        name: notification.round.name || `Round ${notification.round.order}`,
      },
      users,
      notes,
      ids,
      seen: Boolean(notification.seen_at),
      seenAt,
      openedAt: safeParseMicroDate(notification.opened_at),
      dismissedAt: safeParseMicroDate(notification.dismissed_at),
      createdAt: safeParseMicroDate(notification.created_at),
      updatedAt: safeParseMicroDate(notification.updated_at),
    },
    keep,
  };
}

export function deserializeNotificationList(
  notifications: EmployerNotificationListResponse
): EmployerNotificationList {
  const deserializationResults = notifications.results.map(
    deserializeEmployerNotification
  );

  const errors = deserializationResults.filter(
    (result) => result.errors.length > 0
  );

  if (errors.length > 0) {
    sendErrorToSentry(
      new Error("Errors occured during notification deserialization"),
      {
        extra: {
          errors: errors.map((result) => ({
            ...result,
            errors: result.errors.join("\n"),
            data: JSON.stringify({
              job: result.data?.job?.title,
              round: result.data?.round?.name,
              users: result.data?.users?.map((user) => user.id).join(", "),
              notes: result.data?.notes?.map((note) => note.id).join(", "),
            }),
          })),
        },
      }
    );
  }

  return deserializationResults
    .filter((result) => result.keep)
    .map((result) => result.data);
}
