import { useCallback } from "react";
import { QueryClient } from "react-query";

export function useInvalidator<IdType>(
  queryBase: string,
  queryClient: QueryClient
) {
  const invalidateId = useCallback(
    (id: IdType) => queryClient.invalidateQueries([queryBase, "item", id]),
    [queryBase, queryClient]
  );

  const invalidateList = useCallback(
    () => queryClient.invalidateQueries([queryBase, "list"]),
    [queryBase, queryClient]
  );

  const invalidateAll = useCallback(
    () => queryClient.invalidateQueries([queryBase]),
    [queryBase, queryClient]
  );

  const invalidate = useCallback(
    async (ids?: IdType[] | IdType | true | undefined) => {
      if (ids === true) {
        return invalidateAll();
      }

      if (ids) {
        if (!Array.isArray(ids)) ids = [ids];

        return await Promise.all([
          invalidateList(),
          ...ids.map((id) => invalidateId(id)),
        ]);
      }

      return invalidateList();
    },

    [invalidateAll, invalidateId, invalidateList]
  );

  return {
    invalidateList,
    invalidateId,
    invalidateAll,
    invalidate,
  };
}
