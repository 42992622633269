import Axios from "axios";

import { baseurl } from "store/ph-api";

import { errorHandler } from "./error-handler";
import { DialogAxiosInstance } from "./interfaces";
import { authRequestInterceptor } from "./request-interceptor";
import { responseInterceptor } from "./response-interceptor";

export const authAxios: DialogAxiosInstance = Axios.create({
  baseURL: baseurl,
  timeout: 30000,
});

authAxios.defaults.headers["Accept"] = "application/json";
authAxios.interceptors.request.use(authRequestInterceptor);
authAxios.interceptors.response.use(responseInterceptor, errorHandler);
