import { SolidButton, GhostButton } from "components/atomic/buttons";
import { cloneElement } from "react";
import { Dialog } from ".";
import { ConfirmationDialogProps } from ".";

export function ConfirmationDialog({
  message,
  dangerous = false,
  onConfirm = () => false,
  onCancel = () => false,
  record,
  ConfirmButton = dangerous ? GhostButton : SolidButton,
  CancelButton = dangerous ? SolidButton : GhostButton,
  confirmVariant = dangerous ? "danger" : "success",
  cancelVariant = dangerous ? "success" : "danger",
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  confirmElement = (
    <ConfirmButton className="w-full" variant={confirmVariant}>
      {confirmLabel}
    </ConfirmButton>
  ),
  cancelElement = (
    <CancelButton className="w-full" variant={cancelVariant}>
      {cancelLabel}
    </CancelButton>
  ),
  ...props
}: ConfirmationDialogProps) {
  const buttons = [
    cloneElement(confirmElement, {
      key: 0,
      onClick: async () => {
        if (await onConfirm(record)) return;
        record.resolve(true);
        record.delete();
      },
    }),
    cloneElement(cancelElement, {
      key: 1,
      onClick: async () => {
        if (await onCancel(record)) return;
        record.resolve(false);
        record.delete();
      },
    }),
  ];

  return (
    <Dialog {...{ onClose: onCancel, record, ...props }}>
      <div className="flex h-full w-full flex-col justify-between">
        <div className=" poppins-medium-secondary p-3  text-lg">{message}</div>
        <div className="w-full space-y-3 md:flex md:space-x-4 md:space-y-0">
          {dangerous ? buttons.reverse() : buttons}
        </div>
      </div>
    </Dialog>
  );
}
