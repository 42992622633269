import { useTranslation } from "hooks/translation";
import Head from "next/head";

import { ErrorLayoutProps } from ".";
import { useRouter } from "next/router";
import { TextButton } from "components/atomic";

const validCodes = ["client", 404, 500, "404", "500"];

export function ErrorLayout({ code }: ErrorLayoutProps) {
  const { $t } = useTranslation();
  const router = useRouter();

  code = validCodes.includes(code) ? code : "client";

  return (
    <div className="absolute bottom-1/2 right-1/2 flex translate-x-1/2 translate-y-1/2 items-center justify-center">
      <Head>
        <title>{$t("common:errors." + code)} | PerfectlyHired</title>
      </Head>
      <article className="max-w-sm text-center">
        {code != "client" && (
          <h1 className="mb-8 inline-block bg-secondary px-8 py-4 text-white">
            {code}
          </h1>
        )}
        <h3>{$t(`common:errors.${code}`)}</h3>

        <p className="mt-8">
          <TextButton
            className="text-xs"
            scale="tiny"
            onClick={() => router.back()}
          >
            {$t("common:errors.go-back")}
          </TextButton>
        </p>
      </article>
    </div>
  );
}
