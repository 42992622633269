import { createFormats, ObjectFormatter } from "./object-formats";

export interface ObfuscatorOptions {
  secretKeys?: string[];
  encrypter?: (input: string) => string;
  formatters?: ObjectFormatter[];
  ignoreParsingFailure?: boolean;
}

export const defaultOptions: ObfuscatorOptions = {
  encrypter: () => "[-hidden-]",
  formatters: createFormats(
    ["=", ":", "=>"],
    [";", ",", "\r\n", "\n", "\t", "\0"]
  ),
  ignoreParsingFailure: false,
  secretKeys: ["password", "secret", "token"],
};
