import { QueryClient } from "react-query";
import { JobApplicant, JobApplicantListItem } from "store/job-applicant";
import { PageData } from "store/pagination";
import { getText } from "utility/html";

type Props = {
  queryClient: QueryClient;
  jobApplicant: JobApplicant;
};
export function syncJobApplicantNotesCache({
  queryClient,
  jobApplicant,
}: Props) {
  const detailQueries = queryClient.getQueriesData<JobApplicant>([
    "job-applicant",
    "item",
    jobApplicant.id,
  ]);

  detailQueries.forEach(([key, data]) => {
    data.notes = jobApplicant.notes;
    queryClient.setQueryData(key, data);
  });

  const listQueries = queryClient.getQueriesData<
    PageData<JobApplicantListItem>
  >(["job-applicant", "list", jobApplicant.folderId]);
  listQueries.forEach(([key, data]) => {
    if (data) {
      data.forEach((currentJobApplicant) => {
        if (jobApplicant.id === currentJobApplicant.id) {
          const notesText = jobApplicant.notes[0]?.text;
          currentJobApplicant.hasNotes = Boolean(getText(notesText));
        }
      });
      queryClient.setQueryData(key, data);
    }
  });
}
