export {
  getSubjects,
  useSubjects,
  getUniversities,
  useUniversities,
  useUniversityRankings,
  useIsTop1000University,
  useGetUniversityRanking,
} from "./education-manager";

export { compareDegrees } from "./education-functions";

export {
  deserializeDegreeType,
  deserializeSubject,
  deserializeUniversity,
} from "./education-serialization";

export { AttainedDegree, DegreeType } from "./education-enums";
export type { SubjectResponse } from "./education-responses";
export type { Subject, University } from "./education-models";
