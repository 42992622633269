import { CompanyId } from "store/company";
import { JobId } from "store/job/interfaces";
import { PaginationFilters } from "store/pagination";
import { RoundId } from "store/round/interfaces";
import { UserId } from "store/user";
import { MicroDate } from "utility/dates";

export interface EmpoyerNotificationFilters extends PaginationFilters {
  types?: string[];
  job?: JobId;
  round?: RoundId;
  company?: CompanyId;
  users?: UserId;
  seen?: boolean;
  opened?: boolean;
  dismissed?: boolean;
  since?: MicroDate;
  before?: MicroDate;
}

export interface EmployerNotificationRequestFilters extends PaginationFilters {
  types?: string;
  jobad?: JobId;
  round?: RoundId;
  company?: CompanyId;
  users?: UserId;
  seen?: boolean;
  opened?: boolean;
  dismissed?: boolean;
  since?: string;
  before?: string;
}

export function serializeNotificationFilters(
  filters: EmpoyerNotificationFilters
): EmployerNotificationRequestFilters {
  return {
    types: filters.types?.join(",") ?? "",
    jobad: filters.job,
    round: filters.round,
    company: filters.company,
    users: filters.users,
    seen: filters.seen,
    opened: filters.opened,
    dismissed: filters.dismissed,
    since: filters.since?.toISOString(),
    before: filters.before?.toISOString(),
    offset: filters.offset,
    limit: filters.limit,
  };
}
