import { useUniversities } from "store/education";

interface PreloaderProps {
  hasAuth?: boolean;
}

export function Preloader({ hasAuth }: PreloaderProps) {
  useUniversities(hasAuth);

  return <></>;
}
