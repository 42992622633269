import { PaymentMethod, SubscriptionPlan } from "./interfaces";
import { PaymentMethodResponse, SubscriptionPlanResponse } from "./responses";

export function deserializeSubscriptionPlan(
  subscriptionPlanResponse: SubscriptionPlanResponse,
): SubscriptionPlan {
  return {
    id: subscriptionPlanResponse.id,
    tier: subscriptionPlanResponse.tier,
    pricePerMonth: subscriptionPlanResponse.price_per_month,
    features: subscriptionPlanResponse.features,
    activeJobs: subscriptionPlanResponse.active_jobs,
    activeJobApplicants: subscriptionPlanResponse.active_job_applicants,
    activeCompanyMembers: subscriptionPlanResponse.active_company_members,
    marketplaceCredits: subscriptionPlanResponse.marketplace_credits,
    hasMarketplaceAccess: subscriptionPlanResponse.has_marketplace_access,
    hasEmailAccess: subscriptionPlanResponse.has_email_access,
    hasAssessmentAccess: subscriptionPlanResponse.has_assessment_access,
    hasVideoQuestionsAccess:
      subscriptionPlanResponse.has_video_questions_access,
    customJobQuestions: subscriptionPlanResponse.custom_job_questions,
    maxVideoQuestionsPerAssessment:
      subscriptionPlanResponse.max_video_questions_per_assessment,
    createdAt: subscriptionPlanResponse.created_at,
    updatedAt: subscriptionPlanResponse.updated_at,
  };
}

export function deserializePaymentMethod(
  data: PaymentMethodResponse,
): PaymentMethod {
  return {
    id: data.id,
    brand: data.brand,
    last4: data.last4,
    expMonth: data.exp_month,
    expYear: data.exp_year,
    isDefault: data.is_default,
    ownerName: data.owner_name,
  };
}
