import classNames from "classnames";
import { Props, getResponsiveFontClasses } from "./interfaces";

export const ResponsiveText = ({
  className = "",
  children,
  fontSize = "base",
  color,
  onClick,
}: Props) => {
  const fontSizeClasses = getResponsiveFontClasses(fontSize);
  return (
    <div
      className={classNames(fontSizeClasses, className)}
      data-test="responsive-text"
      style={{
        color,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
