import { FacebookIcon, LinkedInIcon, TwitterIcon } from "assets/icons";
import { SolidButton } from "components/atomic";
import { IconButton } from "components/atomic/buttons";
import { ResponsiveMenu } from "components/atomic/responsive-menu";
import { Logo, MonoLogo } from "components/brand";
import { useTranslation } from "hooks/translation";
import { useRouter } from "next/router";
import { PublicRoutes } from "routes/routes";
import { match } from "utility/match";

import { LayoutProps } from "./interfaces";

export function AuthLayout({ children }: LayoutProps) {
  const router = useRouter();
  const { $t } = useTranslation();

  const menuLinks = {
    "job-seeker": PublicRoutes.RegisterJobSeeker,
    employer: PublicRoutes.RegisterEmployer,
    "sign-in": PublicRoutes.Login,
    "talk-to-us": PublicRoutes.TalkToUs,
    "terms-of-service": PublicRoutes.TermsOfService,
  };

  const menuItems = match([
    {
      test: () => router.pathname.includes("employer"),
      result: ["job-seeker", "sign-in"],
    },
    {
      test: () => router.pathname.includes("job-seeker"),
      result: ["employer", "sign-in"],
    },
    {
      test: () => router.pathname.includes("login"),
      result: ["employer", "job-seeker"],
    },
    {
      test: () => true,
      result: ["employer", "job-seeker", "sign-in"],
    },
  ]);

  return (
    <div className="min-h-screen w-full bg-white">
      <div className="pc-container flex min-h-[99vh] flex-col pt-4 lg:container md:max-w-2xl md:pt-12 lg:max-w-screen-2xl lg:px-[2.5em] lg:pt-0">
        <header className="mb-1 flex items-center justify-between py-2 lg:mb-5 lg:py-12">
          <div>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/">
              <Logo width={200} />
            </a>
          </div>
          <ResponsiveMenu
            items={menuItems}
            link={(item) => menuLinks[item]}
            content={(item) => $t(`account:menu.${item}`)}
            supplement={
              /* eslint-disable-next-line @next/next/no-html-link-for-pages */
              <a href={menuLinks["talk-to-us"]}>
                <SolidButton>{$t("account:menu.talk-to-us")}</SolidButton>
              </a>
            }
          />
        </header>
        <div className="flex-1 md:px-15 md:py-5 lg:p-0">{children}</div>
      </div>
      <footer className="bg-secondary py-8 text-xs text-white md:py-20">
        <div className="pc-container flex flex-wrap items-center justify-center gap-[40px]">
          <div className="flex-1">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/">
              <MonoLogo className="mx-auto fill-success md:mx-0" width="200" />
            </a>
          </div>
          <div className="flex items-center gap-x-[40px]">
            <a
              href={menuLinks["terms-of-service"]}
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              {$t("account:tos.name")}
            </a>
            <a href={menuLinks["talk-to-us"]} className="text-white">
              {$t("account:menu.talk-to-us")}
            </a>
          </div>
          <div className="hidden h-[3.75rem] w-[1px] bg-charcoal-600 md:block"></div>
          <div className="flex items-center gap-x-[40px]">
            <IconButton
              target="_blank"
              rel="noreferrer"
              href={"http://facebook.com/perfectly-hired"}
              variant="white"
              icon={FacebookIcon}
            />
            <IconButton
              target="_blank"
              rel="noreferrer"
              href={"http://linkedin.com/in/perfectly-hired"}
              variant="white"
              icon={LinkedInIcon}
            />
            <IconButton
              target="_blank"
              rel="noreferrer"
              href={"http://twitter.com/perfectly-hired"}
              variant="white"
              icon={TwitterIcon}
            />
          </div>
        </div>
      </footer>
    </div>
  );
}
