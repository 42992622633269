import React from "react";
import CustomDialog from "./custom-dialog";
import { PiWarning } from "react-icons/pi";
import { EmployerRoutes } from "routes/routes";
import { useStripeCheckout } from "../payment/hooks/use-stripe-checkout";

type Props = {};

export const SubscriptionFailed = (props: Props) => {
  const { processStripeCheckout } = useStripeCheckout();

  return (
    <div className=" flex h-[100vh]  justify-center ">
      <div className="w-[400px] translate-y-10">
        <CustomDialog
          heading="Subscription renewal failed"
          message="Your subscription renewal was unsuccessful because we were unable to process the payment through your credit card. Please update your payment method."
          icon={PiWarning}
          buttonText="View Plans"
          backButtonVariant="warning"
          redirectUrl={EmployerRoutes.Plans}
          backText="Update Payment method"
          onBack={() => processStripeCheckout(true)}
        />
      </div>
    </div>
  );
};
