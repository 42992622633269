import { ComponentType, ReactNode } from "react";
import { DialogProps, DialogRecordInterface } from ".";
import { CloseDialogProps } from "./interfaces";

type ConstructorProps = {
  Element: ComponentType<DialogProps>;
  children: ReactNode;
  props: Omit<DialogProps, "record">;
  path: string;
  query: string;
  resolve: (value: any) => void;
  reject: (value: any) => void;
  close: (closeProps: CloseDialogProps) => void;
};

export class DialogRecord implements DialogRecordInterface {
  id: string;
  path: string;
  query: string;
  Element: ComponentType<DialogProps>;
  props: Omit<DialogProps, "record">;
  resolve: (value: any) => void;
  reject: (value: any) => void;
  close: (closeProps: CloseDialogProps) => void;
  hasUnsavedChanges?: boolean;

  constructor({
    Element,
    children,
    props,
    path,
    query,
    resolve,
    reject,
    close,
  }: ConstructorProps) {
    this.id = `[dialog---${new Date().getTime()}]`;
    this.path = path;
    this.query = query;
    this.resolve = resolve;
    this.reject = reject;
    this.close = close;
    this.Element = Element;
    this.props = { ...props, children };
  }

  delete() {
    this.close({ dialog: this });
  }
}
