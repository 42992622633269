import { Logo } from "components/brand";
import { PublicMenu } from "components/menu";
import Link from "next/link";
import { useScreenSize } from "utility/detection";

import { LayoutProps } from "./";

export function PublicLayout({ children }: LayoutProps) {
  const { isMobile } = useScreenSize();
  return (
    <div className="flex min-h-screen animate-fade-in flex-col bg-silver">
      <header
        style={{ height: isMobile ? 65 : 96 }}
        className="z-5 w-full bg-white"
      >
        <div className="pc-container flex h-full items-center justify-between">
          <Link href="/">
            <Logo height={isMobile ? "24px" : "34px"} />
          </Link>
          <PublicMenu />
        </div>
      </header>
      <div className="flex flex-1 items-stretch">
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
}
