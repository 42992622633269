import { TestCategory, TestDifficulty } from "store/test/interfaces";
import { QuestionType } from "store/question/interfaces";

// REGEX
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REGEX =
  /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;

export const NON_SPACE_CHARACTER_REGEX = /^(?!\s*$).+/;
export const COMPANY_URL_REGEX = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/;
export const COMPANY_NAME_REGEX = /^(?=.*[\w]).+/;
export const PHONE_REGEX = /^(?=.*[0-9])[- +()0-9]+$/;
export const FULL_NAME_REGEX = /^([\w]{2,})+\s+([\w\s]{2,})+$/i;

export const INTEGER_REGEX = /^(0|-*[1-9]+[0-9]*)$/;
export const POSITIVE_INTEGER_REGEX = /^[1-9]\d*$/;
export const NEGATIVE_NUMBER_REGEX = /^-(?!0*\.?0+$)\d*\.?\d+$/;

export const FIRST_TWO_SENTENCES_REGEX = /^.*?[.?!]\s.*?[.?!]/;
export const HTML_REGEX = /<[^>]*>/g;

export const QUESTION_TYPE_REGEX = new RegExp(
  Object.values(QuestionType).join("|")
);
export const TEST_LEVEL_REGEX = new RegExp(
  Object.values(TestDifficulty).join("|")
);
export const TEST_CATEGORY_REGEX = new RegExp(
  Object.values(TestCategory).join("|")
);
export const LINKEDIN_REGEX =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
export const WEBSITE_REGEX =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

export const isValidField = (value: boolean) => {
  return {
    error: !value,
    success: value,
  };
};

export function isURL(str) {
  var urlRegex =
    "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
  var url = new RegExp(urlRegex, "i");
  return str.length < 2083 && url.test(str);
}

export const BG_REGEX = /\b(bg-[^ ]+)/g;
