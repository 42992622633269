import { ReactNode, ReactPortal, isValidElement, cloneElement } from "react";

export function isReactFragment(node: ReactNode): boolean {
  return (
    typeof node === "object" &&
    Symbol.iterator in node &&
    typeof node[Symbol.iterator] === "function"
  );
}

export function isReactPortal(node: ReactNode): boolean {
  return typeof node === "object" && "key" in node && "children" in node;
}

export function applyProps(node: ReactNode, props: object): typeof node {
  if (isValidElement(node)) {
    return cloneElement(node, props);
  }
  return node;
}

export function applyPropsToNodes(
  nodes: ReactNode,
  props: object
): typeof nodes {
  if (typeof nodes !== "object") return nodes;
  if (Object.keys(nodes).length === 0) return nodes;
  if (isReactPortal(nodes)) {
    return applyPropsToNodes((nodes as ReactPortal).children, props);
  }
  if (isReactFragment(nodes)) {
    return [...(nodes as Array<ReactNode>)].map((node) =>
      applyProps(node, props)
    );
  }
  return applyProps(nodes, props);
}
