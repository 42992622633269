import classNames from "classnames";
import { DateDisplay } from "components/atomic/date-display";
import { Link } from "components/atomic/link";
import { useTranslation } from "hooks/translation";
import type { EmployerNotification } from "store/notifications/notification-models";

interface EmployerNotificationProps {
  notification: EmployerNotification;
}

const fifteenMinutes = 15 * 60 * 1000;

export function EmployerNotification({
  notification,
}: EmployerNotificationProps) {
  const { $t } = useTranslation();
  const seen =
    Boolean(notification.seenAt) &&
    Date.now() - notification.seenAt.getTime() > fifteenMinutes;

  const users = Object.values(notification.users);
  const notes = Object.values(notification.notes);

  const notificationLink = notification.seenAt
    ? notification.type === "mentioned-in-note"
      ? `/employer/jobs/applicant/${notification.notes[0].jobApplicantId}`
      : `/employer/applicants?notification=${notification.key}`
    : "";

  return (
    <Link key={notification.key} href={notificationLink}>
      <div
        className={classNames(
          "flex flex-col gap-5 rounded-xl p-5",
          seen ? "bg-white" : "bg-blue-300",
        )}
      >
        <header className="font-poppins">
          <div
            className={classNames(
              "flex justify-between",
              seen ? "font-normal" : "font-semibold",
            )}
          >
            <div className="text-lg">{notification.job.title}</div>
            <DateDisplay date={notification.createdAt} />
          </div>
          <div className="italic">
            {$t(`employer:notification.titles.${notification.type}`)}
          </div>
        </header>
        {notification.type === "mentioned-in-note"
          ? $t(`employer:notification.messages.${notification.type}`, {
              firstName: notes[0].firstName,
              lastName: notes[0].lastName,
              otherUserCount:
                notes.length > 10
                  ? notes.length - 1
                  : $t(`common:numbers.${notes.length - 1}`),
              count: notes.length,
              roundName: notification.round?.name,
              candidateFirstName: users[0].firstName,
              candidateLastName: users[0].lastName,
              jobTitle: notification.job.title,
            })
          : $t(`employer:notification.messages.${notification.type}`, {
              firstName: users[0].firstName,
              lastName: users[0].lastName,
              otherUserCount:
                users.length > 10
                  ? users.length - 1
                  : $t(`common:numbers.${users.length - 1}`),
              count: users.length,
              roundName: notification.round?.name,
            })}
      </div>
    </Link>
  );
}
