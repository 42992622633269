import type { Accuracy } from "./accuracy";

export function discrete(value: number, accuracy = 1) {
  return Math.round(value / accuracy) * accuracy;
}

export function discreteUpdate<R>(
  input: R,
  accuracy: Accuracy<R>,
  previous: R
) {
  const newObj = {} as R;
  let changed = false;

  for (const key in input) {
    const keyAccuracy = accuracy[key];

    if (keyAccuracy && typeof input[key] == "number") {
      newObj[key] = discrete(Number(input[key]), keyAccuracy) as R[typeof key];
    } else {
      newObj[key] = input[key];
    }

    if (newObj[key] !== previous[key]) {
      changed = true;
    }
  }

  return changed ? newObj : previous;
}
