import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import { Variant, variantClasses } from "utility/variant-classes";

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  variant?: Variant;
  faint?: boolean;
  paddingClassName?: string;
}

export function Badge(props: BadgeProps) {
  const {
    children,
    className,
    variant = "primary",
    faint,
    paddingClassName = "px-2.5 py-1",
  } = props;
  return (
    <div
      className={classNames(
        "inline",
        "rounded",
        paddingClassName,
        "font-poppins font-semibold",
        "text-sm",
        variantClasses(
          {
            primary: faint
              ? "bg-primary-200 text-secondary"
              : "bg-primary text-white",
            secondary: faint
              ? "bg-secondary-200 text-secondary"
              : "bg-secondary text-white",
            white: faint
              ? "bg-white text-secondary-accent"
              : "bg-white text-secondary",
            blue: faint ? "bg-blue-200 text-secondary" : "bg-blue text-white",
            success: faint
              ? "bg-success-200 text-secondary"
              : "bg-success text-white",
            danger: faint
              ? "bg-danger-200 text-secondary"
              : "bg-danger text-white",
            warning: faint
              ? "bg-warning-200 text-secondary"
              : "bg-warning text-white",
            disabled: "bg-secondary-300 text-white",
          },
          {
            variant,
            disabled: false,
          }
        ),
        className
      )}
    >
      {children}
    </div>
  );
}
