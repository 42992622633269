export enum PositionType {
  FullTime = "full-time",
  PartTime = "part-time",
  Both = "both",
}

export enum LocationType {
  Office = "office",
  Remote = "remote",
  Hybrid = "hybrid",
}

export enum Requirement {
  MustHave = "must-have",
  Preferred = "preferred",
  Bonus = "bonus",
}

export enum JobStatus {
  Pending = "pending",
  Completed = "completed",
}
