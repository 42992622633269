import { UseComboboxStateChange } from "downshift";
import { useCallback } from "react";
import slugify from "slugify";

export interface SearchItem {
  code: string;
  label: string;
  search: string;
}

export function searchify(input: string) {
  return slugify(input)
    .replace(/[\s\p{P}]/gu, "")
    .toLowerCase();
}

export function useSearchable<T>(
  items: T[],
  key: keyof T
): ({ inputValue }: Pick<UseComboboxStateChange<T>, "inputValue">) => T[] {
  const searchable = useCallback(
    ({ inputValue }: UseComboboxStateChange<T>) => {
      const result = inputValue
        ? items.filter((item) =>
            String(item[key]).includes(searchify(inputValue))
          )
        : items;
      return result;
    },
    [items, key]
  );

  return searchable;
}
