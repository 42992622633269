export function secondsToHms(seconds) {
  let d = Number(seconds);

  if (d <= 0) {
    return "00s";
  } else {
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    let hDisplay = h <= 9 ? "0" + h + "h " : h + "h ";
    let mDisplay = m <= 9 ? "0" + m + "m " : m + "m ";
    let sDisplay = s <= 9 ? "0" + s + "s" : s + "s";

    return h
      ? hDisplay + mDisplay + sDisplay
      : m
      ? mDisplay + sDisplay
      : sDisplay;
  }
}
