import { JobApplicantTestStats } from "store/applicant/applicant-models";
import { JobApplicantTestStatsRequest } from "./requests";

export function serializeJobApplicantTestStats(
  testStats: JobApplicantTestStats
): JobApplicantTestStatsRequest {
  return {
    test: testStats.testId,
    me: testStats.micEnabled,
    mowc: testStats.mouseOffWindowCount,
    pd: testStats.pasteDetected,
    we: testStats.webcamEnabled,
    wmc: testStats.windowMinimizeCount,
  };
}
