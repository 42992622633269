import { NumberFormatting } from "./interfaces";

export function getFormatting(
  precision: number = 4,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 20,
  },
  locale?: string
): NumberFormatting {
  const formatter = Intl.NumberFormat(locale, options);
  const parts = formatter.formatToParts(-100000.1);
  const minus = parts.find((part) => (part.type = "minusSign")).value;
  const group = parts.find((part) => part.type == "group").value;
  const decimal = parts.find((part) => part.type == "decimal").value;

  return { formatter, precision, symbols: { minus, decimal, group } };
}

export function humanize(value: string, formatting: NumberFormatting) {
  if (value === "") return value;
  const sign = value.startsWith("-") ? formatting.symbols.minus : "";
  const number = value.replace(sign, "");
  const parts = number.match(/^([0-9]*)(\.)?([0-9]*)?$/) || [];
  const integer = parts[1] || "0";
  const parsed = parseFloat(integer);
  const formatted = formatting.formatter.format(parsed);
  const decimal = parts[2] ? formatting.symbols.decimal : "";
  const fraction = parts[3] ? parts[3].slice(0, formatting.precision) : "";

  return `${sign}${formatted}${decimal}${fraction}`;
}

export function computerize(value: string, formatting: NumberFormatting) {
  return value
    .replaceAll(formatting.symbols.minus, "-")
    .replaceAll(formatting.symbols.group, "")
    .replaceAll(formatting.symbols.decimal, ".");
}
