import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { authAxios, axios } from "utility/axios";

import { Applicant, ApplicantId } from "./applicant-models";
import { ApplicantRequest } from "./applicant-requests";
import { ApplicantResponse } from "./applicant-responses";
import { deserializeApplicant } from "./applicant-serialization";
import { AxiosError } from "axios";

export function useApplicantManager() {
  const queryClient = useQueryClient();

  const update = useMutation(async (applicant: ApplicantRequest) => {
    const { data }: { data: ApplicantResponse } = await authAxios.patch(
      `/applicant/`,
      applicant,
      {
        hideWaitDialog: true,
        hideSuccessDialog: true,
      }
    );

    queryClient.invalidateQueries("applicant");
    return deserializeApplicant(data);
  });

  return {
    update,
  };
}

export function useApplicant(id: ApplicantId) {
  return useQuery<Applicant, AxiosError>(
    ["applicant", id],
    async () => {
      const { data }: { data: ApplicantResponse } = await authAxios.get(
        `/applicant/${id}/`
      );
      return deserializeApplicant(data);
    },
    {
      enabled: Boolean(id),
    }
  );
}

export function useCurrentApplicant(enabled: boolean = true) {
  return useQuery(
    ["applicant", "current"],
    async () => {
      const { data }: { data: ApplicantResponse } = await authAxios.get(
        `/applicant/`
      );
      return deserializeApplicant(data);
    },
    { enabled }
  );
}

export function useApplicants(
  options: Omit<
    UseQueryOptions<Applicant[], unknown, Applicant[], string[]>,
    "queryKey" | "queryFn"
  > = {}
) {
  return useQuery(
    ["applicants", "list"],
    async () => {
      const { data } = await authAxios.get(`/applicant/`);
      return data.results.map((a) => deserializeApplicant(a)) as Applicant[];
    },
    options
  );
}
