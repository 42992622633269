import { Matcher, ShortMatcher } from "./interfaces";

/**
 * Functional equivalent of a switch statement. Unlike a switch statement
 * a value is returned. Fallthrough is not supported with this syntax,
 * the first match is always returned. Use the alternative overload if
 * fallthrough is required.
 *
 * @param options An object with keys corresponding to switch cases and
 *                values corresponding to results
 * @param test    The value being tested or a function that recieves
 *                the case.
 */
export function match<T, K extends PropertyKey = PropertyKey>(
  options: Record<K, T>,
  test: keyof typeof options | ((value: keyof typeof options) => boolean)
): T | undefined;
/**
 * Functional equivalent of a switch statement or if else block. Unlike
 * a switch statment a value is returned. Only the first matching value
 * is returned, Fallthrough may be achived using a tests that match
 * multiple cases, for example:
 *
 * ```
 * match([
 *   {
 *     test: () => caseA === input,
 *     result: getResult(caseA)
 *   }
 *   {
 *     test: () => [caseB, caseC].includes(input),
 *     result: getResult(caseB)
 *   }
 * ])
 * ```
 *
 * @param options An array of Matcher objects containing a test function
 *                and the result to be returned if the test is returned
 */
export function match<T>(
  options: (Matcher<T> | ShortMatcher<T>)[]
): T | undefined;
export function match<T, K extends PropertyKey = PropertyKey>(
  options: Record<K, T> | (Matcher<T> | ShortMatcher<T>)[],
  test?:
    | keyof typeof options
    | ((value: keyof typeof options) => boolean)
    | undefined
): T | undefined {
  if (Array.isArray(options)) return amatch(options);
  if (typeof test !== undefined) return tmatch(options, test);
  return undefined;
}

export function tmatch<T, K extends PropertyKey = PropertyKey>(
  options: Record<K, T>,
  test: keyof typeof options | ((value: keyof typeof options) => boolean)
): T | undefined {
  if (typeof test !== "function") {
    return options[test];
  }

  for (const key in options) {
    if (test(key)) {
      return options[key];
    }
  }

  return undefined;
}

export function amatch<T>(
  options: (Matcher<T> | ShortMatcher<T>)[]
): T | undefined {
  for (const option of options) {
    if (Array.isArray(option)) {
      if (option[0]()) return option[1];
    } else {
      if (option.test()) return option.result;
    }
  }

  return undefined;
}
