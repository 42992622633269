import { ProgressIndicator } from "components/atomic/progress-indicator";
import { useTranslation } from "hooks/translation";
import { useEffect } from "react";
import {
  useEmployerNotifications,
  useNotificationManager,
} from "store/notifications";
import { EmployerNotification } from "./employer-notification";

export function EmployerNotificationList() {
  const { $t } = useTranslation();
  const { employerNotifications } = useEmployerNotifications();
  const { markAsSeen } = useNotificationManager();

  useEffect(() => {
    const unseenNotifications = employerNotifications?.filter((n) => !n.seen);
    if (!unseenNotifications || !unseenNotifications.length) return;

    markAsSeen(unseenNotifications);
  }, [markAsSeen, employerNotifications]);

  if (!employerNotifications)
    return (
      <div className="flex h-full items-center justify-center">
        <ProgressIndicator />
      </div>
    );

  // Employer notifications are grouped so that multiple candidates
  // for a single job are grouped into a single notification. However,
  // for notes we want to show a separate notification for each
  // candidate. So these are ungrouped here.
  //
  // Notifications are grouped in the backend because it uses a lot of
  // resources and since JS is single treaded it makes the UI unresponsive
  // while grouping.
  //
  // Perhaps in the future we can do this grouping in a web worker. But
  // for now we group in the backend and ungroup here.

  const notifications = employerNotifications.flatMap((notification) =>
    notification.type === "mentioned-in-note"
      ? notification.users.map((user) => ({
          ...notification,
          users: [user],
          notes: notification.notes.filter((n) => n.userId === user.id),
        }))
      : notification
  );

  return (
    <div className="mx-auto flex max-w-2xl flex-col gap-5 py-10">
      {notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <EmployerNotification
            key={
              notification.key +
              ":" +
              notification.users.map((u) => u.id).join(",")
            }
            notification={notification}
          />
        ))
      ) : (
        <div className="flex h-[calc(100vh-300px)] flex-col items-center justify-center">
          <h1 className="text-center text-2xl font-semibold">
            {$t("common:notifications.empty")}
          </h1>
          <p className="text-center">
            {$t("common:notifications.empty-description")}
          </p>
        </div>
      )}
    </div>
  );
}
