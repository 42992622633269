export function safeParseFloat(value: any): number | undefined;
export function safeParseFloat<T>(value: any, invalid: T): number | T;
export function safeParseFloat<T>(
  value: any,
  invalid: T = undefined as T
): number | T {
  if (typeof value === "number") return value;
  if (!value) return invalid;
  const parsed = parseFloat(String(value));
  if (isNaN(parsed)) return invalid;
  return parsed;
}

export function safeParseInt(value: any): number | undefined;
export function safeParseInt<T>(value: any, invalid: T): number | T;
export function safeParseInt<T>(
  value: any,
  invalid: T = undefined as T
): number | T {
  if (typeof value === "number") return value;
  if (!value) return invalid;
  const parsed = parseInt(String(value));
  if (isNaN(parsed)) return invalid;
  return parsed;
}

export function assertFloat(value: unknown, error = "invalid number"): number {
  const parsed = parseFloat(String(value));
  if (isNaN) throw error;
  return parsed;
}

export function assertInt(value: unknown, error = "invalid number"): number {
  const parsed = parseInt(String(value));
  if (isNaN) throw error;
  return parsed;
}
