import classNames from "classnames";

import { ContainerProps } from "../interfaces";
import { isocelesArrow4pxCss } from "./isoceles-arrow-4px-css";

export function TooltipContainer({
  popupProps,
  children,
  deemphasize,
  widthClassNames = "max-w-xs",
  showArrow = true,
}: ContainerProps) {
  return (
    <div {...popupProps} className="tooltip">
      <style>{isocelesArrow4pxCss}</style>
      <div
        className={classNames(
          deemphasize
            ? "border-secondary-light bg-white text-secondary-light"
            : "border-secondary bg-white text-secondary-dark",
          "rounded border p-2 shadow",
          "text-sm",
          "overflow-auto",
          "scrollbar",
          widthClassNames,
        )}
      >
        {children}
      </div>
      {showArrow && (
        <div className="arrow absolute h-2 w-2">
          <div
            className={classNames(
              "absolute h-2 w-2",
              deemphasize
                ? "border-secondary-light bg-white"
                : "border-secondary bg-white",
            )}
          >
            &nbsp;
          </div>
        </div>
      )}
    </div>
  );
}
