import { AssessmentId } from "store/assessment/interfaces";
import { DegreeType } from "store/education";
import { EmailId } from "store/emails";
import { FolderId } from "store/folder";
import { JobCategoryId } from "store/job-category/interfaces";
import { JobId } from "store/job/interfaces";
import { PaginationFilters } from "store/pagination";
import { UserId } from "store/user";
import { standardizeUrlFriendlyDate } from "utility/dates";
import { objectFilter } from "utility/poke";
import { EMAIL_REGEX } from "utility/regex";
import { JobApplicantId } from "./job-applicant-models";

export const jobApplicantSortColumns = [
  "Score",
  "Date",
  // "HourlyPay",
  "AnnualPay",
] as const;

export const jobApplicantSortDirections = ["+", "-"] as const;

export type JobApplicantSortColumn = typeof jobApplicantSortColumns[number];
export type JobApplicantSortDirection =
  typeof jobApplicantSortDirections[number];

const sortingResolution: {
  [C in JobApplicantSortColumn]: {
    [D in JobApplicantSortDirection]: string;
  };
} = {
  Score: {
    "+": "perfectly_hired_score,-created_at",
    "-": "-perfectly_hired_score,-created_at",
  },
  Date: {
    "+": "created_at,-perfectly_hired_score",
    "-": "-created_at,-perfectly_hired_score",
  },
  // HourlyPay: {
  //   "+": "hourly_pay,-created_at",
  //   "-": "-hourly_pay,-created_at",
  // },
  AnnualPay: {
    "+": "annual_pay,-created_at",
    "-": "-annual_pay,-created_at",
  },
};

export type JobApplicantSorting = {
  column: JobApplicantSortColumn;
  direction: JobApplicantSortDirection;
};

let jobApplicantSortings: JobApplicantSorting[] | undefined = undefined;
export function getJobApplicantSortings() {
  if (jobApplicantSortings) return jobApplicantSortings;

  return jobApplicantSortColumns.flatMap((column) => {
    return jobApplicantSortDirections.map((direction) => {
      return {
        column,
        direction,
      };
    });
  });
}

export function serializeJobApplicantSorting(sorting: JobApplicantSorting) {
  return sortingResolution[sorting?.column]?.[sorting?.direction] ?? undefined;
}

export interface JobApplicantFilters extends PaginationFilters {
  country?: string;
  combo?: string;
  id?: JobApplicantId[];
  folderId?: FolderId;
  jobId?: JobId[];
  userId?: UserId[];
  jobCategoryId?: JobCategoryId[];
  degree?: DegreeType[];
  starred?: boolean;
  inWorkflow?: boolean;
  sorting?: JobApplicantSorting;
  receivedEmailId?: EmailId;
  receivedAssessmentId?: AssessmentId;
  notification?: string;
}

export const defaultJobApplicantFilters: JobApplicantFilters = {
  starred: false,
  folderId: undefined,
  country: "",
  combo: "",
  jobId: [],
  degree: [],
  jobCategoryId: [],
  userId: [],
  notification: "",
  sorting: {
    column: "Date",
    direction: "-",
  },
  offset: 0,
  limit: 10,
};

export interface JobApplicantRequestFilters {
  ids?: string;
  folder?: FolderId;
  combo?: string;
  email?: string;
  jobad?: string;
  users?: string;
  jobcategory_id?: string;
  degree?: string;
  in_workflow?: boolean;
  starred?: boolean;
  o?: string;
  offset?: number;
  limit?: number;
  job_archived?: boolean;
  has_email?: EmailId;
  has_assessment?: AssessmentId;
  notification?: string;
  country?: string;
}

export function convertFilters({
  combo,
  id,
  folderId,
  jobId = [],
  userId = [],
  jobCategoryId = [],
  degree = [],
  starred,
  inWorkflow,
  limit,
  offset,
  sorting,
  receivedAssessmentId,
  receivedEmailId,
  notification,
  country,
}: JobApplicantFilters): JobApplicantRequestFilters {
  return objectFilter({
    ids: id?.join(","),
    folder: folderId === "archived" ? undefined : folderId ?? undefined,
    jobad: jobId.join(","),
    users: userId.join(","),
    jobcategory_id: jobCategoryId.join(","),
    combo: EMAIL_REGEX.test(combo) ? "" : combo,
    email: EMAIL_REGEX.test(combo) ? combo : "",
    degree: degree.join(","),
    starred: starred || undefined,
    in_workflow: inWorkflow,
    job_archived: !folderId
      ? undefined
      : folderId === "archived"
      ? true
      : false,
    offset,
    limit,
    has_assessment: receivedAssessmentId,
    has_email: receivedEmailId,
    notification: notification
      ?.split(" ")
      ?.map((part, i) => (i == 1 ? standardizeUrlFriendlyDate(part) : part))
      ?.join(" "),
    o: serializeJobApplicantSorting(sorting),
    country,
  });
}

export function getQueryString(filters: JobApplicantFilters) {
  const validFilters = objectFilter(convertFilters(filters));
  const params = new URLSearchParams(validFilters as any);
  return params.toString();
}
