import classNames from "classnames";
import { useClientPortal } from "components/atomic/hooks/client-portal";
import { usePopup } from "hooks/popup";
import { useCallback, useEffect, useState } from "react";

import { EmptyContainer, PopupProps } from ".";

export function Popup({
  disabled,
  content,
  children,
  timeout = 300,
  options = {},
  Container = EmptyContainer,
  clickToClose = false,
  alwaysShow = false,
  showOnClick,
  widthClassNames,
  deemphasize,
  showArrow = true,
  ...props
}: PopupProps) {
  const { createPortal } = useClientPortal();
  const { anchorRef, popupProps, update } = usePopup(options);

  const [over, setOver] = useState(false);
  const [shown, setShown] = useState(false);
  const [isClicked, setIsClicked] = useState(!showOnClick);

  useEffect(() => {
    if (shown) update?.();
  }, [shown, update]);

  const handleEnter = useCallback(() => {
    if (disabled) return;
    update?.();
    setOver(true);
    setShown(true);
  }, [disabled, update]);

  const handleLeave = useCallback(() => {
    setOver(false);
    setTimeout(() => {
      setOver((over) => {
        if (!over) {
          setShown(false);
          setIsClicked(!showOnClick);
          update();
        }

        return over;
      });
    }, timeout);
  }, [timeout, update, showOnClick]);

  const handleClose = useCallback(() => {
    if (over && isClicked) {
      if (clickToClose) {
        setShown(false);
        setIsClicked(!showOnClick);
        setOver(false);
        update();
        return;
      }
    }

    setShown(true);
    setOver(true);
    update();
  }, [clickToClose, over, update, isClicked, showOnClick]);

  return (
    <div
      {...props}
      onMouseEnter={handleEnter}
      onClick={(e) => {
        if (showOnClick && !disabled) {
          e.stopPropagation();
          e.preventDefault();
          setIsClicked((v) => !v);
        }
      }}
      onMouseLeave={handleLeave}
    >
      <div ref={anchorRef} onClick={handleClose}>
        {children}
      </div>
      {createPortal(
        <div
          className={classNames(
            (shown || alwaysShow) && isClicked ? "block" : "hidden",
            "animate-fade-in",
          )}
          data-testid="popup"
        >
          <Container
            {...{ popupProps, widthClassNames, deemphasize, showArrow }}
          >
            {content}
          </Container>
        </div>,
      )}
    </div>
  );
}
