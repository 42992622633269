import { useEffect, useState } from "react";
import { WindowScrollContext } from "./window-scroll-context";
import { getWindowScrollValues } from "./window-scroll-retrieval";

interface WindowScrollProviderProps {
  children: React.ReactNode;
}

export function WindowScrollProvider(props: WindowScrollProviderProps) {
  const [state, setState] = useState(getWindowScrollValues());

  useEffect(() => {
    const handleWindowScroll = () => setState(getWindowScrollValues());
    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  return (
    <WindowScrollContext.Provider value={state}>
      {props.children}
    </WindowScrollContext.Provider>
  );
}
