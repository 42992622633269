import { GhostButton, SolidButton } from "components/atomic/buttons";
import { cloneElement } from "react";

import { Dialog } from "./";
import { AlertDialogProps } from "./interfaces";

export function AlertDialog({
  message,
  dangerous = false,
  onConfirm = () => {},
  record,
  width = "300px",
  ConfirmButton = dangerous ? GhostButton : SolidButton,
  confirmVariant = dangerous ? "danger" : "success",
  confirmLabel = "Ok",
  confirmElement = (
    <ConfirmButton className="w-full" variant={confirmVariant}>
      {confirmLabel}
    </ConfirmButton>
  ),
  ...props
}: AlertDialogProps) {
  const button = cloneElement(confirmElement, {
    key: 0,
    onClick: async () => !(await onConfirm(record)) && record.delete(),
  });

  return (
    <Dialog {...{ record, width, ...props }}>
      <div className="flex h-full w-full flex-col justify-between">
        <div>{message}</div>
        <div className="flex w-full space-x-4">{button}</div>
      </div>
    </Dialog>
  );
}
