/**
 *
 * @param input The date to convert to a url friendly string.
 *              Must be in ISO format and use Zulu time.
 * @returns A url parameter friendly readable string.
 */
export function makeISODateUrlFriendly(input: string) {
  const date = input.slice(0, 10).replace(/-/g, ".");
  const time = input.slice(11, 16).replace(/:/g, ".");
  const subMinute = input.slice(17, -1);

  return `${date}__${time}__${subMinute}`;
}

/**
 * @param input A url friendly date string
 * @returns A date string in ISO format.
 *          The date string will be in Zulu time.
 */
export function standardizeUrlFriendlyDate(input: string) {
  const [dotDate, dotTime, subMinute] = input.split("__");

  const date = dotDate.replace(/\./g, "-");
  const time = dotTime.replace(/\./g, ":");

  return `${date}T${time}:${subMinute}Z`;
}
