import {
  makeISODateUrlFriendly,
  standardizeUrlFriendlyDate,
} from "./url-friendly";

/**
 * A date with microsecond precision.
 */
export class MicroDate implements Date {
  private readonly microseconds: number;
  private date: Date;

  constructor(private readonly input?: string | number | Date | MicroDate) {
    this.date = input !== undefined ? new Date(input) : new Date();

    if (typeof input === "number") {
      const smallerThanMilliseconds = input * 1000 - Math.floor(input) * 1000;
      // The multiplication by 1000 is spread out to avoid floating point errors.
      // Try running this in your console: (0.1 + 0.2) * 1000 === 300
      // The result will be false, because of floating point errors.
      // We still get floating point errors when we multiply by 1000 first,
      // but they will be smaller than a microsecond, so they will be rounded away.
      this.microseconds = Math.floor(smallerThanMilliseconds);
    } else if (input instanceof MicroDate) {
      this.microseconds = input.getMicroseconds();
    } else if (typeof input === "string") {
      this.microseconds = parseInt(
        (input.match(/[.,]\d{3}(\d*)([+-Z]|$)/)?.[1] || "0")
          //          \  /\   /\   /\       /
          //           \/  \ /  \ /  \     /
          //           /    |    \    \   /
          //       Decimal  |     \    \ /
          //                |      \    \
          //         Milliseconds  |     \
          //                       |      \
          //   ?.[1] Micro, Nano, etc...   \
          //                                \
          // Start of timezone offset, or end of string
          //
          .slice(0, 3) // First 3 digits (microseconds)
      );
    } else {
      this.microseconds = 0;
    }
  }

  static fromUrlFriendlyString(input: string): MicroDate {
    return new MicroDate(standardizeUrlFriendlyDate(input));
  }

  toUrlFriendlyString(): string {
    return makeISODateUrlFriendly(this.toISOString());
  }

  getMicroseconds() {
    return this.microseconds;
  }

  /*
   * The native Date object has a strange API where missing arguments
   * are treated differently than arguments for which undefined is
   * explicitly passed as the value. Explicit undefined values are
   * coerced to 0, but missing arguments are not.
   *
   * Thus we must either implement this logic ourselves when we wrap
   * the native functions, or we can use argument spreading. The latter
   * approach is prefered as it is shorter and shifts the maintenenace
   * burden to the native type definitions.
   *
   * It isn't possible for symbols since we can't refer to the Parameters
   * or the ReturnType of the native functions, and it is unnecessary
   * for the getters since they don't have arguments. But for all the
   * setters, and transform methods, we use argument spreading.
   */

  [Symbol.toPrimitive](hint: "number"): number;
  [Symbol.toPrimitive](hint: "string"): string;
  [Symbol.toPrimitive](hint: "default"): string;
  [Symbol.toPrimitive](hint: string): string | number {
    if (hint === "number") {
      return this.getTime();
    }

    if (hint === "string") {
      return this.toISOString();
    }

    return this.date[Symbol.toPrimitive](hint);
  }

  toDateString(): string {
    return this.date.toDateString();
  }

  toISOString(): string {
    return (
      this.date.toISOString().slice(0, -1) +
      this.microseconds.toString().padStart(3, "0") +
      "Z"
    );
  }

  toJSON(): string {
    return this.date.toJSON();
  }

  toString(): string {
    return this.date.toString();
  }

  toTimeString(): string {
    return this.date.toTimeString();
  }

  toUTCString(): string {
    return this.date.toUTCString();
  }

  toLocaleDateString(
    ...args: Parameters<Date["toLocaleDateString"]>
  ): ReturnType<Date["toLocaleDateString"]> {
    return this.date.toLocaleDateString(...args);
  }

  toLocaleString(
    ...args: Parameters<Date["toLocaleString"]>
  ): ReturnType<Date["toLocaleString"]> {
    return this.date.toLocaleString(...args);
  }

  toLocaleTimeString(
    ...args: Parameters<Date["toLocaleTimeString"]>
  ): ReturnType<Date["toLocaleTimeString"]> {
    return this.date.toLocaleTimeString(...args);
  }

  getDate(): ReturnType<Date["getDate"]> {
    return this.date.getDate();
  }

  getDay(): ReturnType<Date["getDay"]> {
    return this.date.getDay();
  }

  getFullYear(): ReturnType<Date["getFullYear"]> {
    return this.date.getFullYear();
  }

  getHours(): ReturnType<Date["getHours"]> {
    return this.date.getHours();
  }

  getMilliseconds(): ReturnType<Date["getMilliseconds"]> {
    return this.date.getMilliseconds();
  }

  getMinutes(): ReturnType<Date["getMinutes"]> {
    return this.date.getMinutes();
  }

  getMonth(): ReturnType<Date["getMonth"]> {
    return this.date.getMonth();
  }

  getSeconds(): ReturnType<Date["getSeconds"]> {
    return this.date.getSeconds();
  }

  getTime(): ReturnType<Date["getTime"]> {
    return this.date.getTime() + this.microseconds / 1000;
  }

  getTimezoneOffset(): ReturnType<Date["getTimezoneOffset"]> {
    return this.date.getTimezoneOffset();
  }

  getUTCDate(): ReturnType<Date["getUTCDate"]> {
    return this.date.getUTCDate();
  }

  getUTCDay(): ReturnType<Date["getUTCDay"]> {
    return this.date.getUTCDay();
  }

  getUTCFullYear(): ReturnType<Date["getUTCFullYear"]> {
    return this.date.getUTCFullYear();
  }

  getUTCHours(): ReturnType<Date["getUTCHours"]> {
    return this.date.getUTCHours();
  }

  getUTCMilliseconds(): ReturnType<Date["getUTCMilliseconds"]> {
    return this.date.getUTCMilliseconds();
  }

  getUTCMinutes(): ReturnType<Date["getUTCMinutes"]> {
    return this.date.getUTCMinutes();
  }

  getUTCMonth(): ReturnType<Date["getUTCMonth"]> {
    return this.date.getUTCMonth();
  }

  getUTCSeconds(): ReturnType<Date["getUTCSeconds"]> {
    return this.date.getUTCSeconds();
  }

  setDate(...args: Parameters<Date["setDate"]>): ReturnType<Date["setDate"]> {
    return this.date.setDate(...args);
  }

  setFullYear(
    ...args: Parameters<Date["setFullYear"]>
  ): ReturnType<Date["setFullYear"]> {
    return this.date.setFullYear(...args);
  }

  setHours(
    ...args: Parameters<Date["setHours"]>
  ): ReturnType<Date["setHours"]> {
    return this.date.setHours(...args);
  }

  setMilliseconds(
    ...args: Parameters<Date["setMilliseconds"]>
  ): ReturnType<Date["setMilliseconds"]> {
    return this.date.setMilliseconds(...args);
  }

  setMinutes(
    ...args: Parameters<Date["setMinutes"]>
  ): ReturnType<Date["setMinutes"]> {
    return this.date.setMinutes(...args);
  }

  setMonth(
    ...args: Parameters<Date["setMonth"]>
  ): ReturnType<Date["setMonth"]> {
    return this.date.setMonth(...args);
  }

  setSeconds(
    ...args: Parameters<Date["setSeconds"]>
  ): ReturnType<Date["setSeconds"]> {
    return this.date.setSeconds(...args);
  }

  setTime(...args: Parameters<Date["setTime"]>): ReturnType<Date["setTime"]> {
    return this.date.setTime(...args);
  }

  setUTCDate(
    ...args: Parameters<Date["setUTCDate"]>
  ): ReturnType<Date["setUTCDate"]> {
    return this.date.setUTCDate(...args);
  }

  setUTCFullYear(
    ...args: Parameters<Date["setUTCFullYear"]>
  ): ReturnType<Date["setUTCFullYear"]> {
    return this.date.setUTCFullYear(...args);
  }

  setUTCHours(
    ...args: Parameters<Date["setUTCHours"]>
  ): ReturnType<Date["setUTCHours"]> {
    return this.date.setUTCHours(...args);
  }

  setUTCMilliseconds(
    ...args: Parameters<Date["setUTCMilliseconds"]>
  ): ReturnType<Date["setUTCMilliseconds"]> {
    return this.date.setUTCMilliseconds(...args);
  }

  setUTCMinutes(
    ...args: Parameters<Date["setUTCMinutes"]>
  ): ReturnType<Date["setUTCMinutes"]> {
    return this.date.setUTCMinutes(...args);
  }

  setUTCMonth(
    ...args: Parameters<Date["setUTCMonth"]>
  ): ReturnType<Date["setUTCMonth"]> {
    return this.date.setUTCMonth(...args);
  }

  setUTCSeconds(
    ...args: Parameters<Date["setUTCSeconds"]>
  ): ReturnType<Date["setUTCSeconds"]> {
    return this.date.setUTCSeconds(...args);
  }

  valueOf(): ReturnType<Date["valueOf"]> {
    return this.date.valueOf();
  }
}

export function safeParseMicroDate(
  dateString: string,
  invalid?: undefined
): MicroDate | undefined;
export function safeParseMicroDate<Invalid>(
  dateString: string,
  invalid: Invalid
): MicroDate | typeof invalid {
  if (!dateString) return invalid;
  const date = new MicroDate(dateString);
  if (date.toString() === "Invalid Date") return invalid;
  return date;
}
