import { SolidButton } from "components/atomic";
import { ResponsiveText } from "components/games/atomic/responsive-text/responsive-text";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";
import { Icon } from "assets/icons";
import { Variant } from "utility/variant-classes";
import classNames from "classnames";

type Props = {
  message: ReactNode;
  heading: ReactNode;
  icon: Icon;
  buttonText?: string;
  redirectUrl?: string;
  backText?: string;
  onBack?: () => void;
  onButtonClick?: () => void;
  iconVariant?: Variant;
  backButtonVariant?: Variant;
};

const CustomDialog = ({
  message,
  heading,
  icon: Icon,
  buttonText,
  redirectUrl,
  onBack,
  onButtonClick,
  backText = "Back",
  iconVariant = "warning",
  backButtonVariant = "disabled",
}: Props) => {
  const router = useRouter();
  return (
    <section className="">
      <div className="poppins-semibold-secondary mt-3 flex flex-col  items-center">
        <Icon className={classNames("mb-3 h-15 w-15", `fill-${iconVariant}`)} />
        <ResponsiveText className="justify-center" fontSize="2xl">
          {heading}
        </ResponsiveText>
      </div>
      <div className="poppins-regular-secondary my-5 text-center text-lg">
        {message}
      </div>
      <section className="flex flex-col gap-3">
        {buttonText && (
          <SolidButton
            variant={iconVariant}
            onClick={() =>
              redirectUrl ? router.push(redirectUrl) : onButtonClick()
            }
          >
            {buttonText}
          </SolidButton>
        )}
        {onBack && (
          <SolidButton
            variant={backButtonVariant}
            onClick={onBack || (() => router.back())}
          >
            {backText}
          </SolidButton>
        )}
      </section>
    </section>
  );
};

export default CustomDialog;
