import { useDialog } from "components/atomic/dialog";
import { useTranslation } from "hooks/translation";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useMemo, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { userRoot } from "routes/routes";
import { login, setAccessToken } from "store/auth";
import { User } from "store/user";

interface UseLoginManagerOptions {
  email: string;
  password: string;
  onLogin?: () => void;
  setInvalidEmail?: (email: string) => void;
}

export function useLogin({
  email,
  password,
  onLogin,
  setInvalidEmail,
}: UseLoginManagerOptions) {
  const { $t } = useTranslation();
  const dialog = useDialog();
  const router = useRouter();

  const queryClient = useQueryClient();
  const userData = useRef<User>();

  const mutation = useMutation(async () => {
    try {
      const data = await login({ email, password });
      queryClient.setQueryData("user", () => data.user);
      userData.current = data.user;
      setAccessToken(data.access);
    } catch (error) {
      if (!error.response || error.response.status >= 500) {
        dialog.toast({
          message: $t("common:errors.500"),
          variant: "danger",
          timeout: 5000,
        });
        return;
      }

      const errorMessage = error.response.data?.non_field_errors?.[0];
      if (errorMessage === "E-mail is not verified.") {
        router.push(`/auth/verify?email=${encodeURIComponent(email)}`);
        return;
      }

      if (errorMessage.includes("associated with this email")) {
        setInvalidEmail && setInvalidEmail(email);
      }

      dialog.toast({
        message: errorMessage || $t("common:errors.login"),
        variant: "danger",
        timeout: 5000,
      });
    }

    const { next } = router.query;
    if (onLogin) return onLogin();
    if (!next) {
      router.push(userRoot[userData.current?.type]);
    } else {
      router.push(next.toString(), undefined, { shallow: true });
    }
  });

  return useMemo(
    () => ({ login: mutation.mutate, isLoading: mutation.isLoading }),
    [mutation.isLoading, mutation.mutate],
  );
}
