export function getDateDifferenceInDays(
  startDate: Date,
  endDate: Date
): number {
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in days
  const diffInMilliseconds = Math.abs(start.getTime() - end.getTime());
  const diffInDays = Math.round(diffInMilliseconds / oneDay);

  return diffInDays;
}
export function isToday(date: Date): boolean {
  const today = new Date();
  return getDateDifferenceInDays(date, today) === 0;
}

export function isYesterday(date: Date): boolean {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return getDateDifferenceInDays(date, yesterday) === 0;
}
