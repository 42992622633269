import { ReactNode } from "react";

interface CheatProtectionProps {
  enabled: boolean;
  children: ReactNode;
}

export function CheatProtection({ enabled, children }: CheatProtectionProps) {
  return enabled ? (
    <div
      className="select-none"
      onContextMenu={(event) => {
        event.stopPropagation();
        event.preventDefault();
        return false;
      }}
      onCopy={(event) => {
        event.stopPropagation();
        event.preventDefault();
        return false;
      }}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
}
