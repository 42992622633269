import { LogoutIcon, SettingsIcon } from "assets/icons";
import { TextButton } from "components/atomic";
import { Avatar } from "components/atomic/avatar";
import { Popup } from "components/popup";
import { useTranslation } from "hooks/translation";
import Link from "next/link";
import { PublicRoutes } from "routes/routes";
import { logout } from "store/auth";
import { useUser } from "store/user";

type Props = {};

export function PublicMenu({}: Props) {
  const { $t } = useTranslation();

  const { data: user, isFetched: userIsFetched } = useUser();

  return (
    <nav className="flex h-full items-center gap-7" data-testid="public-menu">
      {userIsFetched && user ? (
        <Popup
          showOnClick
          content={
            <nav className="flex min-w-[180px] flex-col items-end bg-white px-6 py-4 drop-shadow">
              <Link href={"/applicant/settings"} className="w-full">
                <TextButton className="w-full" rightIcon={SettingsIcon}>
                  {$t("common:settings")}
                </TextButton>
              </Link>
              <TextButton
                className="w-full"
                rightIcon={LogoutIcon}
                onClick={logout}
              >
                {$t("common:logout")}
              </TextButton>
            </nav>
          }
          options={{ placement: "bottom-end", offset: [-5, 5] }}
        >
          <Avatar>
            {(user?.firstName || user?.lastName
              ? `${user?.firstName?.[0] ?? ""}${user?.lastName?.[0] ?? ""}`
              : user?.email?.[0]
            )?.toUpperCase()}
          </Avatar>
        </Popup>
      ) : (
        <Link className="poppins-medium-primary" href={PublicRoutes.Login}>
          Sign in
        </Link>
      )}
    </nav>
  );
}
