export type Accessor<
  Result = string,
  Source extends {} = {},
  SecondarySource extends {} | undefined = undefined
> = keyof Source | ((source: Source, secondary?: SecondarySource) => Result);

export function access<
  Result = string,
  Source extends {} = {},
  SecondSource extends object | undefined = undefined
>(
  accessor: Accessor<Result, Source, SecondSource>,
  source: Source,
  second?: SecondSource
): Result {
  if (typeof accessor == "function") {
    return accessor(source, second);
  }

  return source[accessor] as unknown as Result;
}

export type Replacer<Value = any, Destination extends object = {}> =
  | keyof Destination
  | ((destination: Destination, value: Value) => void);

export function replace<Value, Destination extends object>(
  replacer: Replacer<Value, Destination>,
  destination: Destination,
  value: Value
) {
  if (typeof replacer == "function") {
    replacer(destination, value);
  } else {
    destination[replacer] = value as any;
  }
}
