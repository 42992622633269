import {
  accessToken,
  clearLoginStatus,
  refreshAccessToken,
  setAccessToken,
} from "store/auth";
import { decodeToken } from "utility/auth";
import { delay } from "utility/delay/delay";
import { showWaitDialog } from "utility/dialog/show-wait-dialog";
import { DialogAxiosRequestConfig } from "./interfaces";

export const authRequestInterceptor = async (
  config: DialogAxiosRequestConfig<{}>
) => {
  const { isExpired: tokenIsExpired } = decodeToken(accessToken);
  if (tokenIsExpired) {
    setAccessToken(
      (await refreshAccessToken().catch(() => clearLoginStatus())) || ""
    );
  }
  const { hideWaitDialog, method, url } = config;
  if (!hideWaitDialog && method !== "get") {
    showWaitDialog(config);
  }
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  if (process.env.NEXT_PUBLIC_API_DELAY) {
    await delay(process.env.NEXT_PUBLIC_API_DELAY);
  }
  return config;
};

export const requestInterceptor = async (
  config: DialogAxiosRequestConfig<{}>
) => {
  const { hideWaitDialog, method } = config;
  if (!hideWaitDialog && method !== "get") {
    showWaitDialog(config);
  }
  if (process.env.NEXT_PUBLIC_API_DELAY) {
    await delay(process.env.NEXT_PUBLIC_API_DELAY);
  }
  return config;
};
