import { QueryKey, useQueryClient } from "react-query";
import { PageData, paginateArray } from "store/pagination";
import { mergeObjects } from "utility/poke";
import { RecursivePartial } from "utility/types";
import {
  JobApplicant,
  JobApplicantList,
  JobApplicantListItem,
} from "../job-applicant-models";

export function getRetrieveUpdatesFromListItem(
  freshItem: JobApplicantListItem
): RecursivePartial<JobApplicant> {
  return {
    firstName: freshItem.firstName,
    lastName: freshItem.lastName,
    fullName: freshItem.fullName,
    starred: freshItem.starred,
    status: freshItem.status,
    phScore: freshItem.phScore,
    experience: freshItem.experience,
    owner: {
      email: freshItem.email,
    },
    folderId: freshItem.folderId,
    inWorkflow: freshItem.inWorkflow,
  };
}

export function useSyncJobApplicantList(key: QueryKey) {
  const queryClient = useQueryClient();

  return (updatedList: PageData<JobApplicantListItem>) => {
    if (updatedList.length <= 0) {
      return;
    }

    const listQueries = queryClient.getQueriesData<JobApplicantList>([
      "job-applicant",
      "list",
    ]);

    listQueries.forEach(([key, cachedList]) => {
      let updateIsAvailable = false;
      const updatedCache = cachedList?.map((cachedItem, index) => {
        const freshItem = updatedList.find(
          ({ id: uid }) => uid === cachedItem?.id
        );

        if (!freshItem) {
          return cachedItem;
        }

        const updatedItem = mergeObjects(freshItem, cachedItem);
        if (updatedItem !== cachedItem) {
          updateIsAvailable = true;
          return updatedItem;
        }

        return cachedItem;
      });

      if (updateIsAvailable) {
        queryClient.setQueryData<JobApplicantList>(
          key,
          "pagination" in cachedList
            ? paginateArray(updatedCache, cachedList.pagination)
            : updatedCache
        );
      }
    });

    const retrieveQueries = updatedList.flatMap(({ id }) =>
      queryClient.getQueriesData<JobApplicant>(["job-applicant", "item", id])
    );

    retrieveQueries.forEach(([key, cachedItem]) => {
      const freshItem = updatedList.find(({ id }) => id === cachedItem.id);
      if (!freshItem) return;

      const updatedItem = mergeObjects(
        getRetrieveUpdatesFromListItem(freshItem),
        cachedItem
      );

      if (updatedItem !== cachedItem) {
        queryClient.setQueryData<JobApplicant>(key, updatedItem);
      }
    });
  };
}
