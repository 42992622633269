import {
  autoUpdate,
  flip,
  offset,
  size,
  useFloating,
} from "@floating-ui/react-dom";
import { MenuDownIcon, SmallXIcon } from "assets/icons";
import classNames from "classnames";
import AdornedNode from "components/atomic/form-field/adorned-node";
import BorderBox from "components/atomic/form-field/border-box";
import { useClientPortal } from "components/atomic/hooks/client-portal";
import { useTranslation } from "hooks/translation";
import { MouseEvent, ReactNode, useCallback } from "react";
import { extractSizeProps } from "utility/component-size";
import { hoverAvailable } from "utility/hover";
import { isNotEmpty } from "utility/poke";
import { resolveAccessor } from "./accessor";
import { ControlledSelectProps } from "./interfaces";
import OptionList from "./option-list";

export function ControlledSelect<Option extends any>({
  value,
  options,
  optionId,
  optionText = optionId,
  optionDisplay = optionText,

  disabled,

  getItemProps,
  getMenuProps,
  getToggleButtonProps,
  isOpen,
  reset,
  openMenu,
  closeMenu,
  highlightedIndex,
  onClick,
  onChange,

  style,
  compact,
  className,
  leftAdornment,
  rightAdornment,
  listToggle = <MenuDownIcon width="14px" />,
  resetButton = <SmallXIcon width="30px" />,
  unresettable = false,
  placeholder = null,
  resetOnDoubleSelection,
  ...props
}: ControlledSelectProps<Option>) {
  const { $t } = useTranslation();
  const { createPortal } = useClientPortal();

  placeholder = placeholder ?? $t("common:option-placeholder-text");
  const { componentSize, otherProps } = extractSizeProps(props);

  const { refs, floatingStyles, update } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "absolute",
    placement: "bottom",
    middleware: [
      offset(10),
      flip(),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${elements.reference.offsetWidth}px`,
            maxHeight: `${Math.min(Math.max(availableHeight, 300), 400)}px`,
          });
        },
      }),
    ],
  });

  const handleResetButtonClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      reset();
    },
    [reset]
  );
  return (
    <div className={className} style={componentSize.apply(style)}>
      <div ref={refs.setReference} className="group">
        <div {...(!disabled && getToggleButtonProps())}>
          <BorderBox
            {...otherProps}
            className={disabled ? "pointer-events-none" : "cursor-pointer"}
            compact={compact}
            disabled={disabled}
          >
            <AdornedNode
              leftAdornment={leftAdornment}
              rightAdornment={
                unresettable || disabled ? (
                  rightAdornment || (
                    <span className="flex items-center gap-x-1">
                      <div>{listToggle}</div>
                    </span>
                  )
                ) : (
                  <span className="flex items-center gap-x-1">
                    <div
                      onClick={handleResetButtonClick}
                      className={classNames(
                        hoverAvailable && [
                          value && "group-hover:block",
                          "hidden",
                        ]
                      )}
                    >
                      {resetButton}
                    </div>
                    <div>{listToggle}</div>
                  </span>
                )
              }
            >
              <button
                {...{
                  className: classNames(
                    disabled ? "bg-secondary-100" : "bg-white",
                    "flex items-center",
                    "font-poppins",
                    compact ? "text-sm" : "text-base",
                    "focus:shadow-outline",
                    "focus:outline-none",
                    "w-full",
                    "appearance-none",
                    value && !compact && !disabled && "font-semibold",
                    isOpen || !value || disabled
                      ? "text-charcoal-600"
                      : "text-secondary"
                  ),
                  style: {
                    height: isNotEmpty(componentSize.height) ? "100%" : 28,
                  },
                }}
              >
                <div className="truncate">
                  {value
                    ? resolveAccessor<Option, ReactNode>(value, optionDisplay)
                    : placeholder}
                </div>
              </button>
            </AdornedNode>
          </BorderBox>
        </div>
      </div>
      {createPortal(
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="scrollbar-thick z-50 overflow-y-auto rounded bg-white shadow"
        >
          <OptionList
            {...{
              getItemProps,
              getMenuProps,
              highlightedIndex,
              isOpen,
              value,
              reset: !unresettable && reset,
              options,
              optionId,
              optionDisplay,
              resetOnDoubleSelection,
            }}
          />
        </div>
      )}
    </div>
  );
}
