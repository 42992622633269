import { type ScreenSizeState } from "./screen-size-context";

export function getScreenValues(): ScreenSizeState {
  if (typeof window === "undefined") {
    return {
      width: 0,
      height: 0,
      canHover: false,
    };
  }

  return {
    width: window.innerWidth,
    height: window.innerHeight,
    canHover: window.matchMedia("(hover: hover)").matches,
  };
}

interface ExtendedScreenSizeState extends ScreenSizeState {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
}

export function extendScreenValues(
  values: ScreenSizeState
): ExtendedScreenSizeState {
  return {
    ...values,
    isDesktop: values.width >= 1024,
    isTablet: values.width >= 768 && values.width < 1024,
    isMobile: values.width < 768,
  };
}
