import { NotificationIcon } from "assets/icons";
import classNames from "classnames";
import { Link } from "components/atomic/link";
import { useEmployerNotificationCount } from "store/notifications/notification-queries";

export function EmployerNotificationIcon() {
  const notificationCount = useEmployerNotificationCount();

  return (
    <Link href={"/employer/notifications"} data-testid="employer-notifications">
      <div className="relative ">
        <NotificationIcon className="w-6 fill-primary lg:w-7 " />
        {notificationCount > 0 && (
          <div
            className={classNames(
              "h-6 w-6 rounded-full",
              "absolute top-0 right-0",
              "-translate-y-1.5 translate-x-0.5",
              "bg-danger text-white",
              notificationCount > 99 ? "text-[11px]" : "text-xs",
              "flex items-center justify-center"
            )}
          >
            {notificationCount > 99 ? "99+" : notificationCount}
          </div>
        )}
      </div>
    </Link>
  );
}
