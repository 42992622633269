import { RegistrationResult } from "./registration-models";

export function handleAccountError(error: any): RegistrationResult {
  if (!error.response || !error.response.data) throw error;
  const errors = { email: [], password: [] };

  const nonFieldErrors = error.response.data.non_field_errors || [];
  nonFieldErrors.forEach((error: string) => {
    if (error.toLowerCase().includes("password")) {
      errors.password.push(error);
      return;
    }

    if (error.toLowerCase().includes("email")) {
      errors.email.push(error);
    }
  });

  Object.keys(error.response.data).forEach((key) => {
    const lcKey = key.toLowerCase();

    if (lcKey === "non_field_errors") return;
    if (lcKey.includes("password")) {
      if (Array.isArray(error.response.data[key])) {
        error.response.data[key].forEach((error: string) => {
          errors.password.push(error);
        });
      } else if (typeof error.response.data[key] === "string") {
        errors.password.push(error.response.data[key]);
      } else {
        throw error;
      }

      return;
    }

    if (lcKey.includes("email")) {
      if (Array.isArray(error.response.data[key])) {
        error.response.data[key].forEach((error: string) => {
          errors.email.push(error);
        });
      } else if (typeof error.response.data[key] === "string") {
        errors.email.push(error.response.data[key]);
      } else {
        throw error;
      }

      return;
    }

    throw error;
  });

  return {
    errors: {
      email: errors.email.length ? errors.email.join(" ") : undefined,
      password: errors.password.length ? errors.password.join(" ") : undefined,
    },
  };
}
