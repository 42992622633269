import { DialogManager } from "components/atomic/dialog";
import { flattenObject } from "utility/poke";
import { INTEGER_REGEX } from "utility/regex";
import { sendErrorToSentry } from "utility/sentry";
import { DialogAxiosError } from "./interfaces";

export const errorHandler = (error: DialogAxiosError, throwError = true) => {
  console.error("DialogAxiosError", error);
  const {
    hideErrorDialog,
    timestamp,
    errorMessage: defaultErrorMessage,
    method,
  } = error.config;
  let errorMessage;

  const name = `${method.toUpperCase()} request to ${
    error.config.url
  } failed with status code ${error.response?.status}`;
  if (error.response?.status === 400) {
    const flatErrors = flattenObject(error.response.data);
    errorMessage = Object.entries(flatErrors).map(([key, value]) => {
      if (key === "non_field_errors" && Array.isArray(value)) {
        return value.map((v) => v.replace(/\.$/, "")).join("\n");
      }
      if (key === "non_field_errors" && !Array.isArray(value)) {
        return value.replace(/\.$/, "");
      }
      if (INTEGER_REGEX.test(key)) {
        return value;
      }
      return `${key[0].toUpperCase()}${key.slice(1)}: ${value.replace(
        /\.$/,
        ""
      )}`;
    });
    error.message = errorMessage.join(". ");
  } else if (error.response?.status === 401) {
    error.message = "Unauthorized";
  } else if (error.response?.status === 403) {
    error.message = error.response?.data["detail"] || "Forbidden";
  } else if (error.response?.status === 404) {
    error.message = "Not found";
  } else if (error.response?.status === 500) {
    error.message = "Internal server error";
  } else if (error.response?.status === 501) {
    error.message = "Not implemented";
  } else if (error.response?.status === 502) {
    error.message = "Bad gateway";
  } else if (error.response?.status === 503) {
    error.message = "Service unavailable";
  } else if (error.response?.status === 504) {
    error.message = "Gateway timeout";
  } else if (error.message.includes("timeout")) {
    error.message = "Request timed out. Please try again.";
  } else if (!navigator.onLine) {
    error.message = `Please check your connection and try again`;
  } else {
    error.message = `Unknown error (${
      error?.code || "No error code"
    } - ${JSON.stringify(error?.response?.data || error).slice(0, 50)})`;
  }

  if (!hideErrorDialog && method !== "get") {
    sendErrorToSentry(error);
    const dialog = window["dialog"] as DialogManager;
    dialog.closeByIdentifier("axios-wait" + timestamp);
    dialog.toast({
      message:
        errorMessage ||
        error.message ||
        defaultErrorMessage ||
        "Unable to process your request at this time. Please try again later or contact customer support for assistance.",
      variant: "danger",
      timeout: 5000,
    });
  }
  error.name = name;
  if (throwError) throw error;
};
