import { useQueryClient } from "react-query";
import { MicroDate } from "utility/dates";

import {
  EmployerNotification,
  EmployerNotificationKey,
  EmployerNotificationList,
} from "./notification-models";

export function useEmployerNotificationCache() {
  const queryClient = useQueryClient();

  return {
    delete: (keys: EmployerNotificationKey[]) => {
      queryClient.setQueryData<EmployerNotification[]>(
        ["notifications", "employer"],
        (cached) => {
          if (!cached) return cached;
          const keySet = new Set(keys);

          let madeChanges = false;
          let modified: EmployerNotification[] = [];
          for (const notification of cached) {
            if (keySet.has(notification.key)) {
              madeChanges = true;
            } else {
              modified.push(notification);
            }
          }

          return madeChanges ? modified : cached;
        }
      );
    },

    show: (keys: EmployerNotificationKey[], seenAt: MicroDate) => {
      queryClient.setQueryData<EmployerNotificationList>(
        ["notifications", "employer"],
        (cached) => {
          if (!cached) return cached;
          const keySet = new Set(keys);

          let madeChanges = false;
          let modified: EmployerNotification[] = [];
          for (const notification of cached) {
            if (keySet.has(notification.key)) {
              madeChanges = true;
              const newKey = notification.key
                .split(" ")
                .map((part, index) =>
                  index === 1 ? seenAt.toUrlFriendlyString() : part
                )
                .join(" ") as EmployerNotificationKey;

              modified.push({
                ...notification,
                key: newKey,
                seenAt,
                seen: true,
              });
            } else {
              modified.push(notification);
            }
          }

          return madeChanges ? modified : cached;
        }
      );
    },
  };
}
