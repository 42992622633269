import { useDialog } from "components/atomic/dialog";
import { useScreenSize } from "utility/detection";
import { TooltipContainer } from "./containers/tooltip-container";
import { TooltipProps } from "./interfaces";
import { Popup } from "./popup";

export function Tooltip({
  tip,
  Container = TooltipContainer,
  options,
  clickToClose = true,
  timeout = 0,
  ...props
}: TooltipProps) {
  const { isMobile } = useScreenSize();
  const dialog = useDialog();

  const divProps = {
    id: props.id,
    className: props.className,
    style: props.style,
    "data-testid": props["data-testid"],
    children: props.children,
  };

  if (!tip) return <div {...divProps} />;

  if (isMobile) {
    return <div onClick={() => dialog.open(tip)} {...divProps} />;
  }

  return (
    <Popup
      {...{
        content: tip,
        Container,
        options: { ...options },
        clickToClose,
        timeout,
        ...props,
      }}
    />
  );
}
