export const firstMillion = "offset=0&limit=1000000";
export const firstMillionPagination = {
  offset: 0,
  limit: 1_000_000,
};

export interface OffsetLimitPagination {
  offset?: number;
  limit?: number;
}

export interface SimplePagination {
  page?: number;
  pageSize?: number;
}
