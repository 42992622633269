import { SolidButton } from "components/atomic/buttons";
import InputField from "components/atomic/input";
import { ChangeEvent, cloneElement, useState } from "react";
import { Dialog, InputDialogProps } from ".";

export function InputDialog({
  message = "",
  onInput,
  onCancel = () => {},
  validate = () => false,
  record,
  inputPlaceholder,
  Input = InputField,
  Button = SolidButton,
  inputType = "text",
  inputLabel = "",
  buttonLabel = "Input",
  inputVariant,
  buttonVariant,
  defaultValue,
  inputElement = (
    <Input
      className="w-full"
      label={inputLabel}
      variant={inputVariant}
      placeholder={inputPlaceholder}
      defaultValue={defaultValue || ""}
    />
  ),
  buttonElement = (
    <Button className="w-full" variant={buttonVariant}>
      {buttonLabel}
    </Button>
  ),
  ...props
}: InputDialogProps) {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  return (
    <Dialog {...{ onClose: onCancel, record, ...props }}>
      <div className="flex h-full w-full flex-col justify-start space-y-8">
        {message && <div>{message}</div>}
        {cloneElement(inputElement, {
          key: 0,
          error,
          onChange: (arg1: ChangeEvent<any>, arg2: ChangeEvent<any>) => {
            const target = arg1?.currentTarget || arg2?.currentTarget;
            setValue(target.value);
          },
        })}
        {cloneElement(buttonElement, {
          key: 1,
          onClick: async () => {
            const error = validate(value);
            if (error) {
              setError(error);
              return;
            }

            !(await onInput(value, record)) && record.delete();
          },
        })}
      </div>
    </Dialog>
  );
}
