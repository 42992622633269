import axios from "axios";
import { useQuery } from "react-query";

import loadedBuildId from "../../../public/app/build-id.json";

export function BuildChecker() {
  const {} = useQuery({
    queryKey: "build",
    queryFn: async () => {
      const { data: latestBuildId } = await axios.get("/app/build-id.json");
      if (loadedBuildId !== latestBuildId) {
        console.info("Build is outdated, reloading...");

        window.location.reload();
      }
    },
    refetchInterval: 900_000, // 900_000 ms = 15 minutes
    refetchOnWindowFocus: false,
  });

  return <></>;
}
