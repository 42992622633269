import { Currency } from "store/enums/currencies";
import {
  deserializeFollowUpQuestion,
  deserializeQuestionType,
} from "store/question";
import { safeParseFloat, safeParseInt } from "utility/numbers";
import { searchify } from "utility/search";

import { KeywordContext } from "components/jobs/interfaces";
import {
  Job,
  JobId,
  JobListItem,
  JobQuestion,
  JobQuestionOption,
  SavedJobListItem,
} from "./interfaces";
import {
  JobListResponseItem,
  JobQuestionResponse,
  JobResponse,
  OptionResponse,
  SavedJobListItemResponse,
} from "./responses";

export function deserializeJobListItem({
  applicants_count,
  id,
  job_category,
  publish_date,
  title,
  views_count,
  applicant_source_stats,
  is_assessment_jobad,
}: JobListResponseItem): JobListItem {
  return {
    jobId: id,
    title,
    publishDate: new Date(publish_date),
    jobCategoryId: job_category.id,
    jobCategoryName: job_category.name,
    viewsCount: views_count,
    applicantCount: applicants_count,
    sourceStats: applicant_source_stats?.map((source) => ({
      name: source.source,
      count: source.count,
    })),
    search: searchify(title),
    isAssessmentJob: is_assessment_jobad,
  };
}

export function deserializeJobAd(response: JobResponse): Job {
  return {
    id: response.id,
    employerId: response.company,
    jobCategoryId: response.job_category,
    title: response.title,
    description: response.description,
    skills: response.skills?.map((skill) => ({
      id: skill.id,
      isVisible: skill.is_visible,
      name: skill.skill.name,
      search: searchify(skill.skill.name),
      requirement: skill.skill_preference,
    })),
    payment: {
      id: response.payment?.id,
      isVisible: response.payment?.is_visible,
      annualMin: safeParseInt(String(response.payment?.annual_min), undefined),
      annualMax: safeParseInt(String(response.payment?.annual_max), undefined),
      hourlyMin: safeParseFloat(response.payment?.hourly_min, undefined),
      hourlyMax: safeParseFloat(response.payment?.hourly_max, undefined),
      currency: response.payment?.currency as Currency,
    },
    benefits: response.benefits,
    experience: {
      id: response.experience?.id,
      isVisible: response.experience?.is_visible,
      preferred: response.experience?.preferred,
      required: response.experience?.required,
    },
    location: {
      id: response.location?.id,
      locationType: response.location?.required,
      address: response.location?.addresses[0] || {
        address_line_one: "",
        address_line_two: "",
        city: "",
        country: undefined,
        postal_code: "",
        state: "",
      },
      regions: response.location?.regions,
      countries: response.location?.countries,
      timeZones: response.location?.timezones,
    },
    position: {
      id: response.position?.id,
      isVisible: response.position?.is_visible,
      preferred: response.position?.preferred,
      required: response.position?.required,
      hours: response.position?.desired_working_hours,
    },
    keywords:
      response.keywords?.map((k) => ({
        context: KeywordContext.USER,
        title: k,
      })) || [],
    minimumDegree: response.minimum_degree,
    publishDate: new Date(response.publish_date),
    questions: response.questions?.map((quesiton) => ({
      context: "job",
      __context: "JobQuestion",
      jobId: response.id,
      id: quesiton.id,
      questionId: quesiton.question.id,
      order: quesiton.order,
      followUpCount: quesiton.question.followups_count,
      title: quesiton.question.title,
      type: deserializeQuestionType(quesiton.question),
      archived: quesiton.question.archived,
      stock: quesiton.question.company !== response.company,
    })),
    viewsCount: response.views_count,
    isPrivate: response.is_private,
    firstRound: response.round,
    isExpireable: response.is_expireable,
    expirationDate: new Date(response.expiration_date),
    draft: response.draft,
    archived: response.is_archived,
    isAssessmentJobAd: response.is_assessment_jobad,
  };
}

export function deserializeJobAdQuestion(
  jobId: JobId,
  response: JobQuestionResponse,
): JobQuestion {
  return {
    context: "job",
    __context: "JobQuestion",
    id: response.id,
    jobId,
    questionId: response.question.id,
    order: response.order,
    type: deserializeQuestionType(response.question),
    title: response.question.title,
    followUpCount: response.question.followups_count,
    followUp: response.question.followup.map((followUp) =>
      deserializeFollowUpQuestion(followUp, response.question.id),
    ),
    text: response.question.text,
    options: response.question.options.map((o) => deserializeOption(o)),
    archived: response.question.archived,
  };
}

export function deserializeOption(response: OptionResponse): JobQuestionOption {
  return {
    optionId: response.id,
    text: response.text,
  };
}
export function deserializeSavedJobListItem(
  response: SavedJobListItemResponse,
): SavedJobListItem {
  return {
    jobTitle: response.job_title,
    employerName: response.employer_name,
    jobId: response.jobad,
    savedJobId: response.id,
    savedOn: new Date(response.created_at),
  };
}
