import { useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { authAxios } from "utility/axios";
import { emptyArray } from "utility/empty";

import { refactorQueryResult } from "utility/refactor";
import {
  EmpoyerNotificationFilters,
  serializeNotificationFilters,
} from "./notification-filters";
import {
  EmployerNotificationList,
  validEmployerNotificationTypes,
} from "./notification-models";
import { EmployerNotificationListResponse } from "./notification-responses";
import { deserializeNotificationList } from "./notification-serialization";

export async function getEmployerNotifications(
  filters: EmpoyerNotificationFilters
) {
  const { data } = await authAxios.get<EmployerNotificationListResponse>(
    "/notifications/company/",
    {
      params: serializeNotificationFilters(filters),
    }
  );

  return deserializeNotificationList(data);
}

const maxNotifications = 100;

export function useEmployerNotifications(enabled = true) {
  const queryClient = useQueryClient();
  return refactorQueryResult(
    "employerNotifications",
    useQuery<EmployerNotificationList>(
      ["notifications", "employer"],
      async () => {
        const cached =
          queryClient.getQueryData<EmployerNotificationList>([
            "notifications",
            "employer",
          ]) || (emptyArray as EmployerNotificationList);

        const retrieved = await getEmployerNotifications({
          dismissed: false,
          types: validEmployerNotificationTypes.slice(),
          // In the first query, we want to retrieve the most recent
          // *x* notifications, but after that, we only want to retrieve
          // notifications that are newer than the most recent notification
          // since would be undefined if there is no cache, and limit
          // is undefined if there is a cache.
          since: cached[0]?.createdAt,
          limit: cached.length ? undefined : maxNotifications,
        });

        const nFromRetrieved = Math.min(retrieved.length, maxNotifications);
        const nFromCached = Math.min(
          cached.length,
          maxNotifications - nFromRetrieved
        );

        let res = new Array(nFromRetrieved + nFromCached);
        for (let i = 0; i < nFromRetrieved; i++) {
          res[i] = retrieved[i];
        }

        for (let i = 0; i < nFromCached; i++) {
          res[i + nFromRetrieved] = cached[i];
        }

        return res;
      },
      {
        enabled,
        refetchInterval: 900_000,
      }
    )
  );
}

export function useEmployerNotificationCount() {
  const { employerNotifications } = useEmployerNotifications();

  return useMemo(
    () =>
      (employerNotifications || []).reduce((count, notification) => {
        if (!notification.seenAt) {
          // See the comment in @src/components/notifications/employer-notification-list.tsx
          const increment =
            notification.type === "mentioned-in-note"
              ? notification.users.length
              : 1;

          return count + increment;
        }

        return count;
      }, 0),
    [employerNotifications]
  );
}
