import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types";

export function renameError(error: Error, name: string): Error {
  const newError = new Error(error.message);
  newError.name = name;
  newError.stack = error.stack;
  return newError;
}

export function sendErrorToSentry(error: Error, context: CaptureContext = {}) {
  Sentry.captureException(error, context);
}

export function setSentryUser(user: Sentry.User) {
  Sentry.setUser(user);
}

export function addSentryData(name: string, context: any) {
  Sentry.setContext("data", context);
}
