// use-stripe-checkout.tsx
import { Dispatch, SetStateAction, useCallback } from "react";
import { useDialog } from "components/atomic/dialog";
import { CheckoutPage } from "components/employer/payment/checkout";
import { invalidateQueryForDuration } from "hooks/invalidator";
import { getStripeClientSecret } from "store/subscription";
import { useQueryClient } from "react-query";
import { useAuth } from "hooks/auth";
import { useEmployerManager } from "store/employer/employer-manager";
import { NumberInput } from "components/atomic/input/interfaces";
import CustomDialog from "components/employer/subscription-plans/custom-dialog";
import { FaCheckCircle } from "react-icons/fa";
import { MdPayment } from "react-icons/md";

interface Props {
  credits?: NumberInput;
  setCredits?: Dispatch<SetStateAction<NumberInput>>;
}

export const useStripeCheckout = ({ credits, setCredits }: Props = {}) => {
  const { user } = useAuth();
  const { invalidateOwn } = useEmployerManager();
  const queryClient = useQueryClient();
  const dialog = useDialog();

  const fetchClientSecret = useCallback(
    async (setupMode: boolean) => {
      return getStripeClientSecret({
        email: user?.email,
        setupMode,
        credits: setupMode ? undefined : credits,
      });
    },
    [credits, user?.email],
  );

  const processStripeCheckout = async (setupMode: boolean) => {
    dialog.open(
      <CheckoutPage
        message={setupMode ? "Payment Updated" : undefined}
        fetchClientSecret={() => fetchClientSecret(setupMode)}
        handleComplete={() => {
          setCredits && setCredits(undefined);
          if (setupMode) {
            dialog.open(
              <CustomDialog
                heading="Payment Method Added Successfully!"
                message="You are all set and ready to go. Your payment method has been securely saved."
                icon={MdPayment}
                iconVariant="success"
                buttonText="Close"
                onButtonClick={() => dialog.closeByIdentifier("stripe-success")}
              />,
              {
                width: "30vw",
                identifier: "stripe-success",
              },
            );
            invalidateQueryForDuration({
              queryClient,
              queryKey: ["payment-method", "list"],
            });
          } else {
            dialog.open(
              <CustomDialog
                heading="Credits Added Successfully!"
                message="Your account has been topped up."
                icon={MdPayment}
                iconVariant="success"
                buttonText="Close"
                onButtonClick={() => dialog.closeByIdentifier("stripe-success")}
              />,
              {
                width: "30vw",
                identifier: "stripe-success",
              },
            );
            invalidateQueryForDuration({
              queryClient,
              queryFn: invalidateOwn,
            });
          }
        }}
      />,
      {
        identifier: "checkout-dialog",
      },
    );
  };

  return { processStripeCheckout };
};
