export { default as ArrowLeftIcon } from "./arrow-left.svg";
export { default as ArrowRightIcon } from "./arrow-right.svg";
export { default as PadlockIcon } from "./padlock.svg";
export { default as SearchIcon } from "./ios-search.svg";
export { default as EmailIcon } from "./email.svg";
export { default as EmailOpenIcon } from "./email-open.svg";
export { default as PrimaryEmailIcon } from "./primary-email.svg";
export { default as SendIcon } from "./send.svg";
export { default as CircledCheck } from "./green-check.svg";
export { default as ErrorIcon } from "./error.svg";
export { default as DisabledIcon } from "./dnd.svg";
export { default as MenuDownIcon } from "./menu-down.svg";
export { default as DownCaretIcon } from "./caret-down.svg";
export { default as UpCaretIcon } from "./caret-up.svg";
export { default as RightCaretIcon } from "./caret-right.svg";
export { default as LeftCaretIcon } from "./caret-left.svg";
export { default as SettingsIcon } from "./settings.svg";
export { default as EditIcon } from "./edit.svg";
export { default as DeleteIcon } from "./delete.svg";
export { default as LogoutIcon } from "./logout.svg";
export { default as PlusIcon } from "./plus-circle.svg";
export { default as ShareIcon } from "./share.svg";
export { default as SortAscIcon } from "./sort-asc.svg";
export { default as SortDescIcon } from "./sort-desc.svg";
export { default as SortNoneIcon } from "./sort-none.svg";
export { default as AddIcon } from "./add.svg";
export { default as SubtractIcon } from "./subtract.svg";
export { default as CopyIcon } from "./copy.svg";
export { default as StatisticsIcon } from "./statistics.svg";
export { default as HandleIcon } from "./handle.svg";
export { default as CloseIcon } from "./close.svg";
export { default as InfoIcon } from "./info.svg";
export { default as GameIcon } from "./game.svg";
export { default as InfoSquareIcon } from "./info-square.svg";
export { default as InfoCircleIcon } from "./info-circle.svg";
export { default as XSquareIcon } from "./x-square.svg";
export { default as MoveIcon } from "./move.svg";
export { default as CheckIcon } from "./check.svg";
export { default as DoubleTickIcon } from "./double-tick.svg";
export { default as CircleIcon } from "./circle.svg";
export { default as CategoryIcon } from "./category.svg";
export { default as SmallXIcon } from "./small-x.svg";
export { default as FacebookIcon } from "./facebook.svg";
export { default as LinkedInIcon } from "./linkedin.svg";
export { default as TwitterIcon } from "./twitter.svg";
export { default as LinkIcon } from "./link.svg";
export { default as ArrowUpCircleIcon } from "./arrow-up-circle.svg";
export { default as SlideDownCircleIcon } from "./slide-down-circle.svg";
export { default as EyeIcon } from "./eye.svg";
export { default as PaperIcon } from "./paper.svg";
export { default as FolderIcon } from "./folder.svg";
export { default as PhoneIcon } from "./phone.svg";
export { default as HomeIcon } from "./home.svg";
export { default as LocationIcon } from "./location.svg";
export { default as RejectIcon } from "./reject.svg";
export { default as RestoreIcon } from "./restore.svg";
export { default as CircularInfoIcon } from "./warning-info.svg";
export { default as ArchiveIcon } from "./archive.svg";
export { default as PencilIcon } from "./pencil.svg";
export { default as PipelineIcon } from "./pipeline.svg";
export { default as ListIcon } from "./list.svg";
export { default as ClockIcon } from "./clock.svg";
export { default as WorkIcon } from "./work.svg";
export { default as GradIcon } from "./grad.svg";
export { default as EditSquareIcon } from "./edit-square.svg";
export { default as MoreSquareIcon } from "./more-square.svg";
export { default as StarIcon } from "./star.svg";
export { default as StarFilledIcon } from "./star-filled.svg";
export { default as FilterIcon } from "./filter.svg";
export { default as MenuIcon } from "./menu.svg";
export { default as DownloadIcon } from "./download.svg";
export { default as BookmarkIcon } from "./bookmark.svg";
export { default as HourGlassIcon } from "./hour-glass.svg";
export { default as CheckMarkIcon } from "./checkmark.svg";
export { default as PendingIcon } from "./three-dots.svg";
export { default as SingleSelectIcon } from "./single-select.svg";
export { default as MultipleSelectIcon } from "./multiple-select.svg";
export { default as WeightedIcon } from "./weighted.svg";
export { default as ManyToOneIcon } from "./many-to-one.svg";
export { default as WriteIcon } from "./write.svg";
export { default as NumericIcon } from "./numeric.svg";
export { default as NotificationIcon } from "./notification.svg";
export { default as ProgressIcon } from "./progress.svg";
export { default as KebabMenuIcon } from "./kebab-menu.svg";
export { default as HorizontalDragHandleIcon } from "./drag-handle-horizontal.svg";
export { default as BlockedCameraIcon } from "./blocked-camera.svg";
export { default as BrokenDocIcon } from "./broken-doc.svg";
export { default as VideoCameraIcon } from "./video-camera.svg";
export { default as CloseCircleIcon } from "./close-circle.svg";
export { default as SendLaterIcon } from "./send-later.svg";
export { default as CalenderIcon } from "./calendar.svg";
export { default as HospitalIcon } from "./hospital.svg";
export { default as GymIcon } from "./gym.svg";
export { default as BeachIcon } from "./beach.svg";
export { default as AssistIcon } from "./assist.svg";
export { default as RemoteWorkIcon } from "./remote-work.svg";
export { default as ChildIcon } from "./child.svg";
export { default as HomeOfficeIcon } from "./home-office.svg";
export { default as ShieldCheckIcon } from "./shield-check.svg";
export { default as SignOnBonusIcon } from "./sign-on-bonus.svg";
export { default as RetirementFundIcon } from "./retirement-fund.svg";
export { default as Retirement401KIcon } from "./401k.svg";
export { default as CustomBenefitIcon } from "./custom-benefit.svg";
export { default as MessageIcon } from "./message.svg";
export { default as WalletIcon } from "./wallet.svg";
export { default as Work2Icon } from "./work-2.svg";
export { default as Location2Icon } from "./location-2.svg";
export { default as CreditCardChip } from "./credit-card-chip.svg";

export * from "./interfaces";
