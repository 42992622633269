export enum AttainedDegree {
  None = "none",
  SomeCollege = "some-college",
  Associate = "associate-degree",
  Bachelor = "bachelor-degree",
  Master = "master-degree",
  Doctorate = "doctoral-degree",
}

export enum DegreeType {
  Associate = "associate-degree",
  Bachelor = "bachelor-degree",
  Master = "master-degree",
  Doctorate = "doctoral-degree",
}
