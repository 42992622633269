import classNames from "classnames";
import { ProgressIndicator } from "components/atomic/progress-indicator";
import { useTranslation } from "hooks/translation";

type Props = {
  context?: string;
  position?: string;
  text?: string;
};

export default function Loader({ context, position = "fixed", text }: Props) {
  const { $t } = useTranslation();
  console.debug("LOADER ==>", context);
  return (
    <ProgressIndicator
      className={classNames(
        position,
        `bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2`,
        `font-poppins tracking-[0.3px] text-primary-600`,
        `flex items-center justify-center`,
      )}
    >
      {text || $t("common:actions.loading")}
    </ProgressIndicator>
  );
}
