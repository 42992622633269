import { useInterval } from "hooks/timer";
import { ReactNode, useEffect, useState } from "react";
import { useInjections } from "../injection";

import { StyleProps } from "../interfaces";

interface ProgressIndicatorProps extends StyleProps {
  children?: ReactNode;
}

export function ProgressIndicator({
  children,
  ...props
}: ProgressIndicatorProps) {
  const { translate } = useInjections();
  children = children || translate("actions.loading");

  const { setInterval } = useInterval();
  const [dots, setDots] = useState(0);
  useEffect(() => {
    setInterval(() => {
      setDots((dots) => (dots + 1) % 4);
    }, 500);
  }, [setInterval]);

  return (
    <div {...props}>
      <div className={`flex w-fit items-center justify-center gap-2`}>
        <div
          style={{ borderTopColor: "transparent" }}
          className="h-6 w-6 animate-slow-spin rounded-full border-4 border-solid border-primary-light"
        ></div>
        <span>
          {children}
          <span className="ml-[2px] tracking-widest">
            {".".repeat(dots)}
            <span className="text-transparent">{".".repeat(3 - dots)}</span>
          </span>
        </span>
      </div>
    </div>
  );
}
