import { UrlObject } from "url";
import { createContext, ReactNode, useContext } from "react";

export interface LinkProps {
  href: string | UrlObject;
  children: ReactNode;
  target?: string;
}

export type Translator = (key: string, context?: any) => string;

interface Injections {
  router: {
    push: (path: string) => void;
    replace: (path: string) => void;
  };
  Link: React.ComponentType<LinkProps>;
  language: string;
  translate: Translator;
}

const InjectionContext = createContext<Injections>({
  router: {
    push: () => {},
    replace: () => {},
  },
  Link: () => null,
  language: "",
  translate: (key) => key,
});

export function InjectionProvider({
  children,
  ...injections
}: Injections & { children: ReactNode }) {
  return (
    <InjectionContext.Provider value={injections}>
      {children}
    </InjectionContext.Provider>
  );
}

export function useInjections() {
  return useContext(InjectionContext);
}
