import { QueryClient } from "react-query";
import {
  JobApplicant,
  JobApplicantId,
  JobApplicantListItem,
} from "store/job-applicant";
import { JobApplicantRoundStatus } from "store/job-applicant/job-applicant-models";

type Props = {
  queryClient: QueryClient;
  jobApplicantIds: JobApplicantId[];
  status: JobApplicantRoundStatus;
};
export function syncJobApplicantRoundCache({
  queryClient,
  jobApplicantIds,
  status,
}: Props) {
  const listQueries = queryClient.getQueriesData<JobApplicantListItem[]>([
    "job-applicant",
    "list",
  ]);

  listQueries.forEach(([key, data]) => {
    if (data) {
      data.forEach((jobApplicant) => {
        if (jobApplicantIds.includes(jobApplicant.id)) {
          jobApplicant.status = status;
        }
      });
      queryClient.setQueryData(key, data);
    }
  });

  jobApplicantIds.forEach((jobApplicantId) => {
    const detailQueries = queryClient.getQueriesData<JobApplicant>([
      "job-applicant",
      "item",
      jobApplicantId,
    ]);

    detailQueries.forEach(([key, jobApplicant]) => {
      jobApplicant.status = status;
      queryClient.setQueryData(key, jobApplicant);
    });
  });
}
