export enum ApplicationStatusType {
  Round = "round",
  Step = "step",
}

export enum ApplicantDetailTab {
  Info = "Info",
  Overview = "Overview",
  Tests = "Tests",
  EmailHistory = "Email History",
}
export enum MarketplaceApplicantDetailTab {
  Info = "Info",
  Overview = "Overview",
  Tests = "Tests",
}

export enum JobApplicantStatus {
  Rejected = "rejected",
  Closed = "closed",
  InProgress = "in-progress",
}

export enum OverviewSection {
  CognitiveScore = "Cognitive score",
  QualityScore = "Quality score",
  CompanyFitScore = "Company fit score",
  QAndA = "Q&A",
  References = "References",
}
export enum MarketplaceOverviewSection {
  CognitiveScore = "Cognitive score",
  QualityScore = "Quality score",
  References = "References",
}
