import Link from "next/link";
import { ReactElement, ReactNode } from "react";
import { UrlObject } from "url";

export type Url = string | UrlObject;

export function linkWrap(node: ReactElement, href?: Url, target?: string) {
  if (href) {
    return (
      <Link href={href} passHref target={target}>
        {node}
      </Link>
    );
  }

  return node;
}
