import { AxiosError } from "axios";
import {
  QueryClient,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  EmailThread,
  EmailThreadId,
  EmailThreadItem,
} from "store/job-applicant/job-applicant-models";
import {
  EmailConversationResponseItem,
  EmailThreadResponse,
  EmailThreadResponseItem,
} from "store/job-applicant/job-applicant-responses";
import {
  deserializeEmailConversation,
  deserializeEmailThread,
  deserializeEmailThreadList,
} from "store/job-applicant/job-applicant-serialization";
import { PageData, PaginatedList, paginateArray } from "store/pagination";
import { AxiosDialogProps, authAxios } from "utility/axios";
import { refactorQueryResult } from "utility/refactor";

export function useEmailThreads(queryParams: any) {
  return refactorQueryResult(
    "emailThreads",
    useQuery<PageData<EmailThreadItem>, AxiosError>(
      getEmailThreadsQuery(queryParams),
    ),
  );
}
export function useEmailThread(threadId: EmailThreadId) {
  const queryClient = useQueryClient();
  return refactorQueryResult(
    "emailThread",
    useQuery<EmailThread, AxiosError>(
      getEmailThreadQuery(threadId, queryClient),
    ),
  );
}

export function getEmailThreadsQuery(queryParams: any = {}) {
  return {
    queryKey: ["email-threads", "list", JSON.stringify(queryParams)],
    queryFn: async () => {
      const { data } = await authAxios.get<
        PaginatedList<EmailThreadResponseItem>
      >(`jobs/email-threads/`, { params: queryParams });
      return paginateArray(deserializeEmailThreadList(data), {
        count: data.count,
        previous: data.previous,
        next: data.next,
        offset: queryParams.offset,
        limit: queryParams.limit,
      });
    },
    enabled: queryParams.enabled,
    staleTime: 10 * 60 * 1000,
  };
}
export function getEmailThreadQuery(
  threadId: EmailThreadId,
  queryClient: QueryClient,
): UseQueryOptions<EmailThread> {
  return {
    queryKey: ["email-threads", "item", threadId as string],
    staleTime: 10 * 60 * 1000,
    queryFn: async () => {
      const { data } = await authAxios.get<EmailThreadResponse>(
        `jobs/email-threads/${threadId}`,
      );
      queryClient.invalidateQueries(["email-threads", "list"]);
      return deserializeEmailThread(data);
    },
  };
}

export function useEmailThreadsManager() {
  const queryClient = useQueryClient();

  const patch = async (
    emailThread: Partial<EmailThreadItem>,
    dialogConfig: AxiosDialogProps = {},
  ) => {
    await authAxios.patch(
      `jobs/email-threads/${emailThread.id}/`,
      emailThread,
      {
        ...dialogConfig,
      },
    );

    queryClient.invalidateQueries(["email-threads", "list"]);
  };

  return {
    patch,
  };
}
