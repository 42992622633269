import { DialogManager } from "components/atomic/dialog";
import { DialogAxiosRequestConfig } from "utility/axios/interfaces";
import { getAxiosReqDialogMessage } from "./get-axios-config-message";

export const showWaitDialog = (config: DialogAxiosRequestConfig<{}>) => {
  if (typeof window === "undefined") return;

  const { isBlockingAction } = config;
  const dialog = window["dialog"] as DialogManager;

  config.timestamp = Date.now();
  const message = getAxiosReqDialogMessage(config);

  if (isBlockingAction) {
    dialog.working({
      message,
      identifier: config.customIdentifier || "axios-wait" + config.timestamp,
    });
  } else {
    dialog.toast({
      message,
      variant: "warning",
      timeout: 5000,
      identifier: config.customIdentifier || "axios-wait" + config.timestamp,
    });
  }
};
