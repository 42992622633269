export function serializeFollowUpQuestionData({
  questionId,
  questionOptionId,
  optionIsSelected,
}) {
  return {
    next: questionId,
    option: questionOptionId,
    is_selected: optionIsSelected,
  };
}
