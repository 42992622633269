import hook from "next-translate/useTranslation";
import { useMemo } from "react";

import { I18n } from ".";

export function useTranslation(): I18n {
  const { t, lang } = hook();
  const $t = useMemo(
    () => t,
    // Allow $t to be included in dependency arrays without causing a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return { $t, lang };
}
