import allDomains from "utility/domain/all-domains.json";
const DEFAULT_OPTIONS = {
  paste: true,
  type: true,
};

const REGEXP_GLOBAL =
  /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
const REGEXP_WITH_PRECEDING_WS =
  /(?:\s|^)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

const REGEXP_WITH_PROTOCOL =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const sliceFromLastWhitespace = (str) => {
  const whitespaceI = str.lastIndexOf(" ");
  const sliceI = whitespaceI === -1 ? 0 : whitespaceI + 1;
  return str.slice(sliceI);
};
function registerTypeListener(quill) {
  quill.keyboard.addBinding({
    collapsed: true,
    key: " ",
    prefix: REGEXP_WITH_PRECEDING_WS,
    handler: (range, context) => {
      const url = sliceFromLastWhitespace(context.prefix);
      const retain = range.index - url.length;
      const ops: Array<any> = retain ? [{ retain }] : [];
      const domain = url.split(".").pop();
      if (!allDomains.includes(domain.toUpperCase())) {
        ops.push({ delete: url.length }, { insert: url + " " });
        quill.updateContents({ ops });

        return;
      }
      ops.push(
        { delete: url.length },
        {
          insert: url,
          attributes: {
            link: REGEXP_WITH_PROTOCOL.test(url) ? url : `http://${url}`,
            target: "_blank",
          },
        }
      );
      quill.updateContents({ ops });
    },
  });
}

function registerPasteListener(quill) {
  quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
    if (typeof node.data !== "string") {
      return;
    }
    const matches = node.data.match(REGEXP_GLOBAL);
    if (matches && matches.length > 0) {
      const ops = [];
      let str = node.data;
      matches.forEach((match) => {
        const split = str.split(match);
        const beforeLink = split.shift();
        ops.push({ insert: beforeLink });
        ops.push({
          insert: match,
          attributes: {
            link: REGEXP_WITH_PROTOCOL.test(match) ? match : `http://${match}`,
            target: "_blank",
          },
        });
        str = split.join(match);
      });
      ops.push({ insert: str });
      delta.ops = ops;
    }
    return delta;
  });

  quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
    for (const op of delta.ops) {
      for (const key in op.attributes) {
        // remove background and color
        if (["background", "color"].includes(key)) {
          op.attributes[key] = undefined;
        }
      }
    }
    return delta;
  });
}

export class AutoLinks {
  constructor(quill, options = {}) {
    const opts = { ...DEFAULT_OPTIONS, ...options };

    if (opts.type) {
      registerTypeListener(quill);
    }
    if (opts.paste) {
      registerPasteListener(quill);
    }
  }
}
