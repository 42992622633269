import React from "react";
import { applyPropsToNodes } from "utility/react-nodes";
import { AdornedNodeProps } from "components/atomic/form-field/interfaces";

export default function AdornedNode({
  children,
  leftAdornment,
  rightAdornment,
  style,
  ...props
}: AdornedNodeProps) {
  return (
    <div
      {...{
        ...props,
        style: { height: "100%", ...style },
      }}
    >
      <div className="flex h-full w-full items-center">
        {leftAdornment && (
          <div className="mr-2 ">
            {applyPropsToNodes(leftAdornment, { style: { height: "16px" } })}
          </div>
        )}
        <div className="min-w-0 flex-1">{children}</div>
        {rightAdornment && (
          <div className="ml-2">
            {applyPropsToNodes(rightAdornment, { style: { height: "16px" } })}
          </div>
        )}
      </div>
    </div>
  );
}
