import { LayoutProps } from "./";
import { useMouseLeave } from "hooks/cursor/use-mouse-leave";
import { usePasteDetected } from "hooks/clipboard/use-paste-detected";
import useVideoStream from "hooks/media-permissions/use-video-stream";
import { useRouter } from "next/router";
import { TestId } from "store/test/interfaces";
import { JobApplicantId } from "store/job-applicant";
import { useMediaPermission } from "hooks/media-permissions/use-media-permission";
import { BlockedCameraIcon, BrokenDocIcon } from "assets/icons";

export function GameLayout({ children }: LayoutProps) {
  const router = useRouter();
  const jobApplicantId = router.query["jobapplicant_id"] as JobApplicantId;
  const testId = router.query["test_id"] as TestId;
  const { isCameraPermissionGranted, isMicPermissionGranted } =
    useMediaPermission();

  useMouseLeave({ jobApplicantId, testId });
  usePasteDetected({ jobApplicantId, testId });
  const { setVideoRef } = useVideoStream({
    isCameraPermissionGranted,
    isMicPermissionGranted,
    jobApplicantId,
    testId,
  });
  const disabledDevice = isCameraPermissionGranted ? "microphone" : "camera";

  return (
    <section className="grid h-screen w-screen grid-rows-[max-content,1fr] overflow-x-hidden bg-darkPurple-500 sm:grid-cols-[max-content,1fr] sm:grid-rows-1">
      {!isCameraPermissionGranted ? (
        <div className="  w-full rounded sm:w-28 md:w-40 xl:w-60 ">
          {!isCameraPermissionGranted || !isMicPermissionGranted ? (
            <section className="">
              <div className="flex p-2 text-center  font-poppins text-[7px] text-white  xs:text-[9px] sm:flex-col md:mb-2  ">
                <div className="flex  justify-center ">
                  <BrokenDocIcon className="mb-3 mr-2 h-full  w-20 rounded bg-white sm:w-4/5   " />
                </div>
                <div>
                  You have not granted the permissions for {disabledDevice}.{" "}
                  <span className="font-semibold">
                    If you don&apos;t enable your {disabledDevice}, you can
                    still take the tests, but PerfectlyHired will not be able to
                    guarantee fair play.
                  </span>{" "}
                  To enable the {disabledDevice}, click the camera blocked icon{" "}
                  <BlockedCameraIcon className="mx-1 mt-1 inline-block h-5 w-5" />{" "}
                  in your browser address bar to change permissions and reload
                  the page.
                </div>
              </div>
            </section>
          ) : null}
        </div>
      ) : (
        <video
          className="mx-auto w-32 rounded p-2 md:w-52"
          ref={setVideoRef}
          autoPlay
          muted
        />
      )}
      {children}
    </section>
  );
}
