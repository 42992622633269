import {
  RegisterApplicantMutation,
  RegisterEmployerMutation,
  ResetPasswordMutation,
  VerifyEmailAddressMutation,
} from "./registration-mutations";
import {
  RegisterApplicantRequest,
  RegisterApplicantRequestParams,
  RegisterEmployerRequest,
  ResetPasswordRequest,
  VerifyEmailAddressRequest,
} from "./registration-requests";

export function serializeEmployerRegistration(
  mutation: RegisterEmployerMutation
): RegisterEmployerRequest {
  return {
    first_name: mutation.firstName,
    last_name: mutation.lastName || undefined,
    company: { name: mutation.company },
    email: mutation.email,
    password1: mutation.password,
    password2: mutation.password,
  };
}

export function serializeApplicantRegistration(
  mutation: RegisterApplicantMutation
): {
  data: RegisterApplicantRequest;
  params: RegisterApplicantRequestParams;
} {
  return {
    data: {
      first_name: mutation.firstName,
      last_name: mutation.lastName || undefined,
      email: mutation.email,
      password1: mutation.password,
      password2: mutation.password,
    },
    params: {
      jobad: mutation.jobId,
      shared_assessment: mutation.sharedAssessmentId,
    },
  };
}

export function serializeEmailVerification(
  mutation: VerifyEmailAddressMutation
): VerifyEmailAddressRequest {
  return {
    key: mutation.verificationToken,
  };
}

export function serializePasswordReset({
  password,
  token,
  userId,
  firstName,
  lastName,
}: ResetPasswordMutation): ResetPasswordRequest {
  return {
    uid: userId,
    new_password1: password,
    new_password2: password,
    token,
    first_name: firstName ?? undefined,
    last_name: lastName ?? undefined,
  };
}
