import { ClassNameCollection } from "./interfaces";

export class ClassNames {
  className?: string | undefined;

  constructor(classNames: ClassNameCollection) {
    const realClassNames = classNames
      .map((c) => (c instanceof ClassNames ? c.toString() : c))
      .map((c) => (c ? c.trim() : ""))
      .filter((c) => c);

    if (realClassNames.length) this.className = realClassNames.join(" ");
  }

  override(...classNames: ClassNameCollection): ClassNames {
    const newClassNames = new ClassNames(classNames);
    return newClassNames.className ? newClassNames : this;
  }

  toString(): string {
    return this.className || "";
  }
}

export default function classnames(...classNames: ClassNameCollection) {
  return new ClassNames(classNames);
}
