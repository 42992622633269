import { useRouter } from "next/router";
import { ComponentType, ReactNode, useCallback, useContext } from "react";

import { sendErrorToSentry } from "utility/sentry";

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  Dialog,
  DialogContext,
  DialogProps,
  DialogRecord,
  InputDialog,
  InputDialogProps,
  ToastDialog,
  ToastProps,
  WorkingDialog,
  WorkingProps,
} from "./";
import { AlertDialog } from "./alert-dialog";
import { UnsavedChangesDialog } from "./unsaved-changes-dialog";
import {
  ActionOptions,
  AlertDialogProps,
  CloseDialogProps,
  DialogRecordInterface,
  UnsavedChangesDialogProps,
} from "./interfaces";

export function useDialog() {
  const { dialogRecords, setDialogRecords } = useContext(DialogContext);

  const router = useRouter();

  const current = useCallback(
    () => dialogRecords[dialogRecords.length - 1],
    [dialogRecords]
  );

  const previous = useCallback(
    () => dialogRecords.slice(0, dialogRecords.length - 1),
    [dialogRecords]
  );

  const close = useCallback(
    async ({ identifier, dialog }: CloseDialogProps = {}) => {
      setDialogRecords((localDialogRecords) => {
        const localDialogRecordsCopy = [...localDialogRecords];
        const dialogIndex = dialog
          ? localDialogRecordsCopy.findIndex((record) => record.id == dialog.id)
          : identifier
          ? localDialogRecordsCopy.findIndex(
              (record) => record.props.identifier == identifier
            )
          : localDialogRecordsCopy.length - 1;

        if (dialogIndex == -1) {
          return localDialogRecordsCopy;
        }

        const record = localDialogRecordsCopy[dialogIndex];
        if (record && record.props.clearQuery) {
          router.replace(record.path + record.query, undefined, {
            shallow: true,
            scroll: false,
          });
        }
        const [deletedRecord] = localDialogRecordsCopy.splice(dialogIndex, 1);
        return localDialogRecordsCopy;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router, setDialogRecords]
  );

  const closeByIdentifier = useCallback(
    (identifier: string) => {
      close({ identifier });
    },
    [close]
  );

  const open = useCallback(
    (
      children: ReactNode,
      props: Omit<DialogProps, "record"> = {},
      Element: ComponentType<DialogProps> = Dialog
    ): Promise<unknown> => {
      return new Promise(async (resolve, reject) => {
        const { pathname, search } = window.location;
        if (props.clearQuery) {
          await router.replace(pathname, undefined, {
            shallow: true,
            scroll: false,
          });
        }
        // Update the local copy. The state is updated below
        // See comment at the top of the hook for more info
        // eslint-disable-next-line react-hooks/exhaustive-deps

        setDialogRecords((localDialogRecords) => [
          ...localDialogRecords,
          new DialogRecord({
            Element,
            children,
            props,
            path: pathname,
            query: search,
            resolve,
            reject,
            close,
          }),
        ]);
      });
    },

    [close, router, setDialogRecords]
  );

  const alert = useCallback(
    (props: Omit<AlertDialogProps, "record">) => open(null, props, AlertDialog),
    [open]
  );

  const confirm = useCallback(
    (props: Omit<ConfirmationDialogProps, "record">) => {
      return open(null, props, ConfirmationDialog);
    },
    [open]
  );

  const unsavedChanges = useCallback(
    (props: Omit<UnsavedChangesDialogProps, "record"> = {}) => {
      return open(null, props, UnsavedChangesDialog);
    },
    [open]
  );

  const input = useCallback(
    (props: Omit<InputDialogProps, "record">) => {
      return open(null, props, InputDialog);
    },
    [open]
  );
  const toast = useCallback(
    (props: Omit<ToastProps, "record">) => {
      return open(
        null,
        { ...props, type: "toast" },
        ToastDialog
      ) as Promise<DialogRecordInterface>;
    },
    [open]
  );

  const working = useCallback(
    ({ message, identifier }: Omit<WorkingProps, "record"> = {}) => {
      return open(
        message,
        { canClose: false, identifier, type: "working" },
        WorkingDialog
      );
    },
    [open]
  );

  const action = useCallback(
    <T>(
      action: () => Promise<T>,
      { doing, done, failed }: ActionOptions
    ): Promise<T> => {
      const identifier = "working-dialog" + Date.now();
      working({ message: doing, identifier });
      return action()
        .then((result) => {
          closeByIdentifier(identifier);
          if (done) {
            toast({
              message: done,
              variant: "success",
              timeout: 2000,
              className: "animate-fade-in",
            });
          }

          return result;
        })
        .catch((error) => {
          closeByIdentifier(identifier);
          if (typeof failed === "function") {
            const result = failed(error);
            if (typeof result === "string")
              toast({
                message: result,
                variant: "danger",
                timeout: 2000,
                className: "animate-fade-in",
              });

            return result;
          } else {
            sendErrorToSentry(error);
            toast({
              message: failed,
              variant: "danger",
              timeout: 2000,
              className: "animate-fade-in",
            });
          }
        });
    },
    [closeByIdentifier, toast, working]
  );

  return {
    all: dialogRecords,
    isOpen: Boolean(dialogRecords.length),
    closeByIdentifier,
    current,
    previous,
    action,
    close,
    open,
    alert,
    confirm,
    unsavedChanges,
    working,
    input,
    toast,
  };
}
