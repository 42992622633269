import { useBulkAction } from "store/bulk-action";
import { authAxios } from "utility/axios";
import { MicroDate } from "utility/dates";

import { useEmployerNotificationCache } from "./notification-cache";
import { EmployerNotification } from "./notification-models";
import { MarkAsSeenResponse } from "./notification-responses";

export function useNotificationManager() {
  const cache = useEmployerNotificationCache();

  return {
    markAsSeen: useBulkAction(async (notifications: EmployerNotification[]) => {
      const { data } = await authAxios.patch<MarkAsSeenResponse>(
        "/notifications/mark-as-seen/",
        { ids: notifications.flatMap((n) => Array.from(n.ids)) },
        {
          hideWaitDialog: true,
          hideSuccessDialog: true,
          hideErrorDialog: true,
        }
      );

      cache.show(
        notifications.map((n) => n.key),
        new MicroDate(data.seen_at)
      );
    }, []),
    markAsOpened: useBulkAction(
      async (notifications: EmployerNotification[]) => {
        await authAxios.patch(
          "/notifications/mark-as-opened/",
          { ids: notifications.flatMap((n) => Array.from(n.ids)) },
          {
            hideWaitDialog: true,
            hideSuccessDialog: true,
            hideErrorDialog: true,
          }
        );

        cache.delete(notifications.map((n) => n.key));
      },
      []
    ),
    markAsDismissed: useBulkAction(
      async (notifications: EmployerNotification[]) => {
        await authAxios.patch(
          "/notifications/mark-as-dismissed/",
          { ids: notifications.flatMap((n) => Array.from(n.ids)) },
          {
            hideWaitDialog: true,
            hideSuccessDialog: true,
            hideErrorDialog: true,
          }
        );

        cache.delete(notifications.map((n) => n.key));
      },
      []
    ),
  };
}
