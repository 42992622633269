import classNames from "classnames";
import { LabelProps } from "components/atomic/form-field/interfaces";
import { ResponsiveText } from "components/games/atomic/responsive-text/responsive-text";
import { IconButton } from "../buttons";
import { CircularInfoIcon } from "assets/icons";

export function Label({
  required,
  optional,
  disabled,
  light,
  children,
  marginClasses = "mb-2",
  nativeProps,
  fontSize = "lg",
  tooltip,
  variant = "secondary",
  ...props
}: LabelProps) {
  return (
    <div {...props}>
      <div
        className={classNames(
          "font-poppins text-xs sm:text-base",
          light ? "font-light" : "font-semibold",
          disabled ? `text-${variant}-300` : `text-${variant}`,
          marginClasses,
        )}
      >
        <label {...nativeProps}>
          <ResponsiveText className="font-poppins" fontSize={fontSize}>
            {children}
            {tooltip && (
              <div className="inline-block">
                <IconButton
                  actualButton
                  icon={CircularInfoIcon}
                  className="ml-1 fill-primary"
                  height={14}
                  width={14}
                  tooltip={tooltip}
                />
              </div>
            )}
            {required && (
              <span className="ml-1 text-xs font-light text-charcoal-600">
                (required)
              </span>
            )}
            {optional && (
              <span className="ml-1 text-xs font-light text-charcoal-600">
                (optional)
              </span>
            )}
          </ResponsiveText>
        </label>
      </div>
    </div>
  );
}
