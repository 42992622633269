import { useCallback, useMemo } from "react";
import { FetchQueryOptions, useQuery, useQueryClient } from "react-query";
import { deserializePublicJobAdEmployer } from "store/public-job/public-job-serialization";
import { authAxios, axios } from "utility/axios";
import { AxiosDialogProps } from "utility/axios/interfaces";
import { refactorQueryResult } from "utility/refactor";

import { Employer, EmployerListItem } from "./employer-models";
import {
  deserializeEmployer,
  deserializeEmployerList,
  serializeEmployer,
} from "./employer-serialization";

export async function getEmployer() {
  const { data } = await axios.get(`company/`);
  return deserializeEmployer(data) as Employer;
}
export async function getPersonalizedUrlSuggestions(personalized_url: string) {
  const { data } = await authAxios.get(`is-personalized-url-available`, {
    params: {
      personalized_url,
    },
  });
  return data as { available: boolean; suggestions: string[] };
}
export async function getPublicEmployer(personalized_url: string) {
  const { data } = await axios.get(`public-company/`, {
    params: { personalized_url },
  });
  return data.results.map((e) => deserializePublicJobAdEmployer(e))[0];
}
export async function getEmployers() {
  const { data } = await authAxios.get(`companies/list/`);

  return deserializeEmployerList(data) as EmployerListItem[];
}

export function usePublicEmployer(personalized_url: string) {
  return useQuery(["public-employer", "list", personalized_url], () =>
    getPublicEmployer(personalized_url),
  );
}
export function useCurrentEmployer() {
  return useQuery(["employer", "current"], () => getEmployer());
}
export function useEmployers() {
  return refactorQueryResult(
    "employers",
    useQuery(["employer", "list"], getEmployers),
  );
}

export function usePersonalizedUrlSuggestions(personalizedUrl: string) {
  return useQuery(
    ["employer", "personalizedUrl", personalizedUrl],
    () => getPersonalizedUrlSuggestions(personalizedUrl),
    {
      enabled: Boolean(personalizedUrl),
    },
  );
}
export function useOwnEmployer() {
  const employerManager = useEmployerManager();
  return refactorQueryResult(
    "ownEmployer",
    useQuery(employerManager.retrieveEmployer()),
  );
}

export function useEmployerManager() {
  const queryClient = useQueryClient();

  const invalidateOwn = useCallback(() => {
    queryClient.invalidateQueries(["employer", "own"]);
    queryClient.invalidateQueries("user");
  }, []);

  const retrieveEmployer = useCallback(
    (): FetchQueryOptions<Employer> => ({
      queryKey: ["employer", "own"],
      queryFn: async () => {
        const { data } = await authAxios.get(`company/`);
        return deserializeEmployer(data);
      },
      cacheTime: 10000,
    }),
    [],
  );

  const patch = useCallback(
    async (
      employer: Partial<Employer>,
      dialogConfig: AxiosDialogProps = {},
    ) => {
      await authAxios.patch(`/company/`, serializeEmployer(employer), {
        ...dialogConfig,
      });

      queryClient.invalidateQueries(["employer", "own"]);
    },
    [queryClient],
  );

  return useMemo(
    () => ({
      patch,
      retrieveEmployer,
      invalidateOwn,
    }),
    [retrieveEmployer, patch, invalidateOwn],
  );
}
