import { useQuery } from "react-query";
import { UserType, useUser } from "store/user";
import { useEmployerManager } from "store/employer";

import { useAuth } from "./auth-hook";

export function useUserStatus(validTypes: UserType[]) {
  const { isAuthenticated } = useAuth();
  const { data: user, isFetched } = useUser({
    enabled: isAuthenticated,
  });

  const { retrieveEmployer } = useEmployerManager();
  const { data: employer, isFetched: employerFetched } = useQuery({
    ...retrieveEmployer(),
    enabled: isAuthenticated,
  });

  const companyQuestionnaireStep = employer?.questionnaireStep;

  const IsQuestionnaireComplete = employer?.isQuestionnaireCompleted;

  return {
    userIsFetched: isAuthenticated && isFetched,
    employerFetched: isAuthenticated && employerFetched,
    userIsValid: validTypes.includes(user?.type),
    companyProfileIsCompleted: employer?.personalizedUrl,
    companyQuestionnaireStep,
    IsQuestionnaireComplete,
    paymentFailed: employer?.paymentFailed,
  };
}
