import { access, Accessor } from "utility/accessor";

export type ValueOf<T> = T[keyof T];

/**
 * Delete the first element that matches the criteria from the array and return it.
 * WARNING! This function modifies its first parameter
 *
 * @param from Array to delete from
 * @param where Test to find the item to delete
 * @returns The deleted item
 */
export function del<T>(from: T[], where: (t: T) => boolean) {
  return from.splice(from.findIndex(where), 1)[0];
}

/**
 * Check if given value is empty:
 *
 * - Returns `true` if value is: `""`,  `[]`, `{}`, or `undefined`.
 * - Returns `false` if value is anything else including `null`, `0`, and `false`.
 *
 * @param value The value to be checked
 */
export function isEmpty(value: any) {
  if (value === 0) return false;
  if (value === null) return false;
  if (value === false) return false;
  return !(typeof value == "object" ? Object.keys(value).length : value);
}

/**
 * Check if given value is not empty:
 *
 * - Returns `false` if value is: `""`,  `[]`, `{}`, or `undefined`.
 * - Returns `true` if value is anything else including `null`, `0`, and `false`.
 *
 * @param value The value to be checked
 */
export function isNotEmpty(value: any) {
  if (value === 0) return true;
  if (value === null) return true;
  if (value === false) return true;
  return Boolean(typeof value == "object" ? Object.keys(value).length : value);
}

/**
 * @returns `replacement` if `value` is empty, `value` otherwise
 * @see isEmpty
 */
export function replaceEmpty(value: any, replacement: any) {
  return isEmpty(value) ? replacement : value;
}

/**
 * Creates a new object with only the key/value pairs
 *
 * @param input The object to apply filtering to
 * @param test How to filter the objects, defaults to {@link isNotEmpty}
 */
export function objectFilter<T>(
  input: T,
  test: (value: ValueOf<T>, key: string) => boolean = isNotEmpty
): Partial<T> {
  return Object.keys(input).reduce((result, current) => {
    if (test(input[current], current)) {
      result[current] = input[current];
    }

    return result;
  }, {});
}

export function keySort<T>(
  input: T,
  comparison: (a: string, b: string) => number = (a, b) => a.localeCompare(b)
) {
  const keys = Object.keys(input).sort(comparison);

  return keys.reduce((result, key) => {
    result[key] = input[key];
    return result;
  }, {});
}

/**
 * Creates a new array with each element of the input array seperated by the given
 * delimeter.
 *
 * @param input The array to be delimited
 * @param delimeter The item to use as a delimiter
 */
export function delimit(input: any[], delimeter: any = ", ") {
  return input.reduce(
    (output: any[], current, index) => (
      index ? output.push(delimeter, current) : output.push(current), output
    ),
    []
  );
}

/**
 * Returns a new array that contains only the first copy of each element of the
 * input array that has the same identifying accesor.
 *
 * @param input     An array that contains members of the same type
 * @param accessor  The name of a first level scalar property of the array's
 *                  elements or a function that resolves an element to a string.
 *                  The result will be used to determine uniqueness. Defaults
 *                  to `JSON.stringify`
 */
export function unique<T>(
  input: T[],
  accessor: Accessor<string, T> = JSON.stringify
): T[] {
  return Object.values(
    input.reduce(
      (dict, current) => ((dict[access(accessor, current)] = current), dict),
      {}
    )
  );
}

/**
 * Computes and returns the intersection of the scalar arrays provided
 * as parameters.
 */
export function intersection(...arrays: (string | number)[][]) {
  const counts = arrays.reduce((counts, current) => {
    current.forEach((item) => {
      counts[item] ??= { value: item, count: 0 };
      counts[item].count++;
    });

    return counts;
  }, {} as Record<string, { value: string | number; count: number }>);

  const requiredCount = arrays.length;

  return Object.values(counts)
    .filter((item) => item.count == requiredCount)
    .map((item) => item.value);
}

/**
 * Returns the union of the scalar arrays provided as an argument.
 *
 */
export function union(...arrays: (string | number)[][]) {
  return Array.from(
    arrays.reduce(
      (union, current) => (current.map((item) => union.add(item)), union),
      new Set<string | number>()
    )
  );
}

export function setEquality<T>(setA: Set<T>, setB: Set<T>) {
  if (setA.size !== setB.size) return false;
  for (const a of Array.from(setA)) if (!setB.has(a)) return false;
  return true;
}

export function chunk(input: any[], chunkSize: number) {
  let count = Math.ceil(input.length / chunkSize);
  const chunks = [];

  let startIndex = 0;
  while (count--) {
    chunks.push(input.slice(startIndex, startIndex + chunkSize));
    startIndex += chunkSize;
  }

  return chunks;
}

export function flip<K extends PropertyKey>(input: Record<K, any>) {
  const keys = Object.keys(input);
  if (!keys.length) return {};
  return keys.reduce((flipped, current) => {
    flipped[input[current]] ??= current;

    return flipped;
  }, {});
}

export function flattenObject(
  input: Record<PropertyKey, any>,
  separator = ".",
  prefix = ""
): Record<string, string> {
  return Object.keys(input).reduce((flattened, current) => {
    const value = input[current];
    const key = prefix + current;

    if (typeof value == "object") {
      if (Array.isArray(value)) {
        if (value.length == 1) {
          if (typeof value[0] == "object") {
            Object.assign(
              flattened,
              flattenObject(value[0], separator, key + separator)
            );
          } else {
            flattened[key] = value[0];
          }

          return flattened;
        }

        if (value.length == 0) {
          return flattened;
        }
      }

      Object.assign(
        flattened,
        flattenObject(value, separator, key + separator)
      );
    } else {
      flattened[key] = String(value);
    }

    return flattened;
  }, {});
}

/**
 * This is similar to {...destination, ...source} with two key differeces:
 *
 * 1. Objects are merged recursively.
 * 2. If the result would be the same as the destination object, the original
 *    destination object is returned instead of a new object. This allows for
 *    calling code to easily determine if the destination object was mutated
 *    by checking for referential equality.
 *
 * Examples:
 *
 * Object properties merged recursively:
 *
 * ```
 * mergeObjects({ a: { b: 1 } }, { a: { c: 2 } }) // { a: { b: 1, c: 2 } }
 * ```
 *
 * Referential stability:
 *
 * ```
 * const a = { a: 1 }
 * const b = { a: 1, b: 2 }
 * const c = { a: 2 }
 * mergeObjects(a, b) === b // true
 * mergeObjects(a, c) === c // false
 * ```
 *
 * Properties from the source object will overwrite properties from the
 * destination object:
 *
 * ```
 * mergeObjects({ a: 1 }, { a: "s" }) // { a: "s" }
 * ```
 *
 * TODO: Prevent infinite loops if the object contains a circular reference.
 *       This is not a problem for the current use cases, but it should be
 *       fixed at some point.
 *
 * @param source Should not contain any circular references
 * @param destination The object to merge into, will not be mutated
 * @param skipUndefined Wheter to skip source properties that are undefined
 * @returns The destination object with the source object merged into it
 */
export function mergeObjects<S, D>(
  source: S,
  destination: D,
  skipUndefined = true
) {
  const result = { ...destination };

  let hasChange = false;
  for (const key of Object.keys(source)) {
    const sourceType = typeof source[key];
    const destinationType = typeof destination[key];

    if (skipUndefined && source[key] === undefined) continue;

    if (sourceType !== destinationType) {
      result[key] = source[key];
      hasChange = true;
      continue;
    }

    if (Array.isArray(source[key])) {
      const arrayHasChange =
        source[key].length != destination[key].length ||
        source[key].some(
          (value: any, index: number) => value !== destination[key][index]
        );

      result[key] = arrayHasChange ? source[key] : destination[key];
      hasChange ||= arrayHasChange;
      continue;
    }

    if (sourceType === "object" && source[key] !== null) {
      result[key] = mergeObjects(source[key], destination[key]);
      if (result[key] !== destination[key]) hasChange = true;
      continue;
    }

    if (source[key] !== destination[key]) {
      result[key] = source[key];
      hasChange = true;
    }
  }

  return hasChange ? result : destination;
}

export function stableDeepMerge(...updates: Record<string, any>[]) {
  return {
    into: (destination: Record<string, any>) =>
      updates.reduce(
        (update, destination) => mergeObjects(update, destination),
        destination
      ),
    and: (...additionalUpdates: Record<string, any>[]) =>
      stableDeepMerge(...updates, ...additionalUpdates),
  };
}
