export function defaultTranslator(
  key: string,
  data: Record<PropertyKey, any> = {}
) {
  if (key === "mins-ago") {
    return `${data.count} mins ago`;
  }

  if (key === "hours-ago") {
    return `${data.count} hours ago`;
  }

  if (key === "days-ago") {
    return `${data.count} days ago`;
  }

  if (key === "present") {
    return "Present";
  }
}
