import { useCallback, useMemo, useState } from "react";
import { useQueries, useQuery, UseQueryOptions } from "react-query";
import { firstMillion } from "store/pagination";
import { authAxios } from "utility/axios";

import {
  deserializeFollowUpQuestion,
  FollowUpId,
  FollowUpListItemResponse,
  FollowUpQuestion,
  getQuestion,
  OptionId,
  QuestionFollowUps,
  QuestionId,
  QuestionOptionId,
  serializeFollowUpQuestionData,
} from "./";
import { QuestionContext } from "./interfaces";
import { getQuestionBaseUrl } from "./question-manager";

export async function getFollowUps(
  context: QuestionContext,
  questionId: QuestionId
): Promise<QuestionFollowUps> {
  const { data } = await authAxios.get(
    `${getQuestionBaseUrl(context)}${questionId}/followup/?${firstMillion}`
  );

  return {
    __context: "Question",
    questionId,
    followups: data.results.map((item: FollowUpListItemResponse) =>
      deserializeFollowUpQuestion(item, questionId)
    ),
  };
}

export async function getFollowUp(
  context: QuestionContext,
  questionId: QuestionId,
  followUpId: FollowUpId
): Promise<FollowUpQuestion> {
  const { data: followup } = await authAxios.get(
    `${getQuestionBaseUrl(
      context
    )}${questionId}/followup/${followUpId}/?${firstMillion}`
  );

  const question = await getQuestion(context, followup.next);

  return {
    ...question,
    id: followUpId,
    __context: "FollowUpQuestion",
    parentId: questionId,
    option: followup.option as OptionId,
    questionId: followup.next as QuestionId,
    isSelected: followup.is_selected,
  };
}

export function useFollowUps(
  context: QuestionContext,
  questionIds: QuestionId[] = []
) {
  return useQueries(
    questionIds.map((questionId) => ({
      queryKey: [`${context}-followup`, questionId],
      queryFn: () => getFollowUps(context, questionId),
    }))
  ).reduce((all: Record<PropertyKey, FollowUpQuestion[]>, current) => {
    if (current.data?.questionId)
      all[current.data.questionId] = current.data.followups;
    return all;
  }, {});
}

export function useQuestionFollowUps(
  context: QuestionContext,
  questionId: QuestionId,
  queryOptions?: Omit<
    UseQueryOptions<
      QuestionFollowUps,
      unknown,
      QuestionFollowUps,
      (string | (number & { __type: "QuestionId" }))[]
    >,
    "queryKey" | "queryFn"
  >
) {
  return useQuery(
    [`${context}-followup`, questionId],
    () => getFollowUps(context, questionId),
    queryOptions
  );
}

export function useFollowUpManager(context: QuestionContext) {
  const [questionIds, setQuestionIds] = useState<Set<QuestionId>>(new Set());
  const followups = useFollowUps(context, Array.from(questionIds));

  const create = useCallback(
    async (followUpQuestionData: {
      id?: FollowUpId;
      parentQuestionId: QuestionId;
      questionId: QuestionId;
      questionOptionId: QuestionOptionId;
      optionIsSelected: boolean;
    }) => {
      const { data } = await authAxios.post(
        `${getQuestionBaseUrl(context)}${
          followUpQuestionData.parentQuestionId
        }/followup/`,
        serializeFollowUpQuestionData(followUpQuestionData)
      );
      return data;
    },
    [context]
  );

  const update = useCallback(
    async (followUpQuestionData: {
      id: FollowUpId;
      parentQuestionId: QuestionId;
      questionId: QuestionId;
      questionOptionId: QuestionOptionId;
      optionIsSelected: boolean;
    }) => {
      const { data } = await authAxios.post(
        `${getQuestionBaseUrl(context)}${
          followUpQuestionData.parentQuestionId
        }/followup/`,
        serializeFollowUpQuestionData(followUpQuestionData)
      );
      return data;
    },
    [context]
  );

  const remove = useCallback(
    async ({ parentId, id }: Pick<FollowUpQuestion, "id" | "parentId">) => {
      await authAxios.delete(
        `${getQuestionBaseUrl(context)}${parentId}/followup/${id}/`
      );
    },
    [context]
  );

  return useMemo(
    () => ({
      followups,
      questionIds,
      create,
      update,
      remove,
      toggleQuestion(questionId: QuestionId) {
        const newQuestionIds = new Set(questionIds);
        if (questionIds.has(questionId)) {
          newQuestionIds.delete(questionId);
        } else {
          newQuestionIds.add(questionId);
        }
        setQuestionIds(newQuestionIds);
      },
    }),
    [create, followups, questionIds, remove, update]
  );
}
