import { ReactNode } from "react";
export type Props = {
  className?: string;
  color?: string;
  children: ReactNode;
  fontSize?: FontSize;
  onClick?: () => void;
};
export type FontSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl";

export function getResponsiveFontClasses(fontSize: FontSize): string {
  let classes = "";

  switch (fontSize) {
    case "xs":
      classes = "text-[9px] sm:text-[10px] md:text-xs lg:text-xs xl:text-xs";
      break;
    case "sm":
      classes = "text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm";
      break;
    case "base":
      classes = "text-sm sm:text-base md:text-base lg:text-base xl:text-base";
      break;
    case "lg":
      classes = "text-base sm:text-lg md:text-lg lg:text-lg xl:text-lg";
      break;
    case "xl":
      classes = "text-base sm:text-base md:text-lg lg:text-xl xl:text-xl";
      break;
    case "2xl":
      classes = "text-base sm:text-lg md:text-2xl lg:text-2xl xl:text-2xl";
      break;
    case "3xl":
      classes = "text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3xl";
      break;
    case "4xl":
      classes = "text-2xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl";
      break;
    case "5xl":
      classes = "text-2xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl";
      break;
    case "6xl":
      classes = "text-3xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl";
      break;
    default:
      break;
  }

  return classes;
}
