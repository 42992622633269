import { useSelect } from "downshift";
import { resolveAccessor } from "./accessor";
import { SimpleSelectProps } from "./interfaces";
import { ControlledSelect } from "./controlled-select";

export function SimpleSelect<Option extends any>({
  raiseError,
  value,
  resetOnDoubleSelection = true,
  ...props
}: SimpleSelectProps<Option>) {
  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    reset,
    isOpen,
    openMenu,
    closeMenu,
  } = useSelect<Option>({
    items: props.options as Option[],
    selectedItem: value,
    itemToString: (option) =>
      resolveAccessor(option, props.optionText || props.optionId),
    onSelectedItemChange: ({ selectedItem }) => props.onChange(selectedItem),
  });

  return (
    <ControlledSelect
      {...{
        ...props,
        value,
        reset,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
        openMenu,
        closeMenu,
        resetOnDoubleSelection,
      }}
    />
  );
}
