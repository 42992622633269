import { useQuery, useQueryClient } from "react-query";
import { authAxios } from "utility/axios";
import { remember } from "utility/browser-cache/browser-cache";
import { falseFunction, nullFunction } from "utility/empty";
import { searchify } from "utility/search";
import allSubjects from "utility/subjects/subjects.json";
import { UniversityResponse } from "./education-responses";
import {
  deserializeSubject,
  deserializeUniversity,
} from "./education-serialization";

export function getUniversities() {
  return remember(
    "universities",
    () => authAxios.get<UniversityResponse>("jobs/get-universities/"),
    {
      version: 3,
    },
  ).then(({ data }) => data.map(deserializeUniversity));
}

export function getSubjects() {
  return allSubjects.map(deserializeSubject);
}

export function useUniversities(enabled = true) {
  return useQuery(["university", "list"], getUniversities, {
    // Since we are caching in local storage, we can set a short
    // stale time. Since getUniversities returns stale data while
    // fetching new data, keeping a low stale time will ensure
    // that the user sees the new data as soon as it is available.
    staleTime: 10,
    enabled,
  });
}

export function useUniversityRankings() {
  const queryClient = useQueryClient();

  return useQuery(
    ["top-university", "dictionary"],
    async () => {
      const universities = await queryClient.fetchQuery(
        ["university", "list"],
        getUniversities,
      );

      if (!universities) {
        throw new Error("Universities not loaded");
      }

      const dictionary = universities.reduce(
        (acc, university, index) => ((acc[university.search] = index + 1), acc),
        {} as Record<string, number>,
      );

      return dictionary;
    },
    {
      staleTime: Infinity,
    },
  ).data;
}

export function useIsTop1000University() {
  const dictionary = useUniversityRankings();

  if (!dictionary) return falseFunction;

  return (universityName: string) =>
    dictionary[searchify(universityName)] <= 1000;
}

export function useGetUniversityRanking() {
  const dictionary = useUniversityRankings();

  if (!dictionary) return nullFunction;

  return (universityName: string) =>
    dictionary[searchify(universityName)] || null;
}

export function useSubjects() {
  return useQuery(["subject", "list"], getSubjects, {
    staleTime: Infinity,
  });
}
