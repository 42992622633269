import { PublicRoutes } from "routes/routes";
import { deserializeUser } from "store/user";
import { axios } from "utility/axios";
import { context } from "utility/context";
import { truth } from "utility/delay/delay";
import { setSentryUser } from "utility/sentry";

import { LoginMutation, LoginResponse, RefreshTokenResponse } from "./";

export function getLoginStatus() {
  return context.localStorage?.getItem("logged-in") === "true";
}

export function clearLoginStatus() {
  context.localStorage?.removeItem("logged-in");
}

export let accessToken = "";
export function setAccessToken(newAccessToken: string) {
  context.localStorage?.setItem("logged-in", "true");
  accessToken = newAccessToken;
}

export async function login({ email, password }: LoginMutation) {
  const { data } = await axios.post<LoginResponse>(
    `/auth/login/`,
    {
      email,
      password,
    },
    {
      hideWaitDialog: true,
      hideSuccessDialog: true,
      hideErrorDialog: true,
    },
  );

  setAccessToken(data.access);
  setSentryUser({ email });
  return { ...data, user: deserializeUser(data.user) };
}

let tokenIsRefreshing = false;
export async function refreshAccessToken(tries = 2, skipLock = false) {
  if (!skipLock && tokenIsRefreshing) {
    await truth(() => !tokenIsRefreshing);
    return accessToken;
  }

  tokenIsRefreshing = true;
  try {
    const { data } = await axios.post<RefreshTokenResponse>(
      "/auth/token/refresh/",
      {},
      { hideWaitDialog: true, hideSuccessDialog: true, hideErrorDialog: true },
    );

    setAccessToken(data.access);
    tokenIsRefreshing = false;
    return data.access;
  } catch (error) {
    if (tries > 1) {
      return await refreshAccessToken(tries - 1, true);
    }

    tokenIsRefreshing = false;
  }
}

export async function logout() {
  await axios.post(
    "/auth/logout/",
    {},
    {
      hideWaitDialog: true,
      hideSuccessDialog: true,
    },
  );
  clearLoginStatus();
  setSentryUser(null);
  window.location.href = PublicRoutes.Login;
}
