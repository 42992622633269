export class ArrayDecorator<T> implements Array<T> {
  constructor(private readonly value: T[]) {
    Object.assign(this, value);
  }

  decorate<U>(value: U[]) {
    return new ArrayDecorator<U>(value);
  }

  get length() {
    return this.value.length;
  }

  at(index: number): T {
    return this.value[index];
  }

  pop() {
    return this.value.pop();
  }

  push(...items: T[]) {
    return this.value.push(...items);
  }

  concat(...items: ConcatArray<T>[]) {
    return this.value.concat(...items);
  }

  join(separator?: string) {
    return this.value.join(separator);
  }

  reverse() {
    return this.value.reverse();
  }

  shift() {
    return this.value.shift();
  }

  slice(start?: number, end?: number) {
    return this.value.slice(start, end);
  }

  sort(compareFn?: (a: T, b: T) => number) {
    this.value.sort(compareFn);
    return this;
  }

  splice(start: number, deleteCount?: number) {
    return this.value.splice(start, deleteCount);
  }

  unshift(...items: T[]) {
    return this.value.unshift(...items);
  }

  indexOf(searchElement: T, fromIndex?: number) {
    return this.value.indexOf(searchElement, fromIndex);
  }

  lastIndexOf(searchElement: T, fromIndex?: number) {
    return this.value.lastIndexOf(searchElement, fromIndex);
  }

  every(
    callbackfn: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ) {
    return this.value.every(callbackfn, thisArg);
  }

  some(
    callbackfn: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ) {
    return this.value.some(callbackfn, thisArg);
  }

  forEach(
    callbackfn: (value: T, index: number, array: T[]) => void,
    thisArg?: any
  ) {
    this.value.forEach(callbackfn, thisArg);
  }

  map<U>(
    callbackfn: (value: T, index: number, array: T[]) => U,
    thisArg?: any
  ): U[] {
    return this.value.map(callbackfn, thisArg);
  }

  filter<S extends T>(
    callbackfn: (value: T, index: number, array: T[]) => value is S,
    thisArg?: any
  ): S[];
  filter(
    callbackfn: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ): T[];
  filter(
    callbackfn: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ) {
    return this.value.filter(callbackfn, thisArg);
  }

  reduce(
    callbackfn: (
      previousValue: T,
      currentValue: T,
      currentIndex: number,
      array: T[]
    ) => T
  ): T;
  reduce<U>(
    callbackfn: (
      previousValue: U,
      currentValue: T,
      currentIndex: number,
      array: T[]
    ) => U,
    initialValue: U
  ): U;
  reduce<U>(
    callbackfn: (
      previousValue: U,
      currentValue: T,
      currentIndex: number,
      array: T[]
    ) => U,
    initialValue?: U
  ): U {
    return this.value.reduce(callbackfn, initialValue);
  }

  reduceRight(
    callbackfn: (
      previousValue: T,
      currentValue: T,
      currentIndex: number,
      array: T[]
    ) => T
  ): T;
  reduceRight(
    callbackfn: (
      previousValue: T,
      currentValue: T,
      currentIndex: number,
      array: T[]
    ) => T,
    initialValue: T
  ): T;
  reduceRight(
    callbackfn: (
      previousValue: T,
      currentValue: T,
      currentIndex: number,
      array: T[]
    ) => T,
    initialValue?: T
  ) {
    return this.value.reduceRight(callbackfn, initialValue);
  }

  find<S extends T>(
    predicate: (this: void, value: T, index: number, obj: T[]) => value is S,
    thisArg?: any
  ): S | undefined;
  find(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any
  ): T | undefined;
  find(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any
  ) {
    return this.value.find(predicate, thisArg);
  }

  findIndex(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any
  ): number {
    return this.value.findIndex(predicate, thisArg);
  }

  fill(value: T, start?: number, end?: number) {
    this.value.fill(value, start, end);
    return this;
  }

  copyWithin(target: number, start: number, end?: number) {
    this.value.copyWithin(target, start, end);
    return this;
  }

  [n: number]: T;

  entries() {
    return this.value.entries();
  }

  keys() {
    return this.value.keys();
  }

  values() {
    return this.value.values();
  }

  includes(searchElement: T, fromIndex?: number) {
    return this.value.includes(searchElement, fromIndex);
  }

  flat<A, D extends number = 1>(depth?: D): FlatArray<A, D>[] {
    this.value.flat(depth);
    return this as any as FlatArray<A, D>[];
  }

  flatMap<U, This = undefined>(
    callback: (
      this: This,
      value: T,
      index: number,
      array: T[]
    ) => U | readonly U[],
    thisArg?: This
  ) {
    return this.value.flatMap(callback, thisArg);
  }

  [Symbol.iterator]() {
    return this.value[Symbol.iterator]();
  }

  [Symbol.unscopables] = this.value[Symbol.unscopables];

  findLast<S extends T>(
    predicate: (value: T, index: number, array: T[]) => value is S,
    thisArg?: any
  ): S {
    return this.value.find(predicate as any, thisArg);
  }

  findLastIndex(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ): number {
    return this.value.findIndex(predicate, thisArg);
  }

  toLocaleString(): string {
    return this.value.toLocaleString();
  }

  toReversed(): T[] {
    return this.value.reverse();
  }

  toSorted(compareFn?: (a: T, b: T) => number): T[] {
    return this.value.sort(compareFn);
  }

  toSpliced(start: number, deleteCount: number, ...items: T[]): T[] {
    return this.value.splice(start as number, deleteCount as number, ...items);
  }

  toString(): string {
    return this.value.toString();
  }

  with(index: number, value: T): T[] {
    this.value[index] = value;
    return this.value;
  }
}
