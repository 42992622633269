import {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { computerize, getFormatting, humanize } from "utility/numbers";

import { useInjections } from "../injection";
import Input from "./";
import { NumericInputProps } from "./interfaces";

function replaceChangeEventValue<T>(
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  value: T
) {
  return {
    ...event,
    currentTarget: { ...event.currentTarget, value },
    target: { ...event.target, value },
  };
}

function syntesizeChangeEvent(target: HTMLInputElement | HTMLTextAreaElement) {
  return {
    currentTarget: target,
    target,
  } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}

export function NumericInput({
  type,
  value,
  onPaste,
  onChange,
  onKeyPress,
  precision: p = 4,
  min = -Infinity,
  max = Infinity,
  raiseError,
  ...props
}: NumericInputProps) {
  const { translate } = useInjections();

  const precision = type === "integer" ? 0 : parseInt(String(p));
  const numberType = precision > 0 ? "decimal" : "integer";
  const formatting = useMemo(() => getFormatting(precision), [precision]);

  const [inputValue, setInputValue] = useState(String(value ?? ""));

  useEffect(() => {
    setInputValue(humanize(String(value ?? ""), formatting));
  }, [formatting, value]);

  function handleChange(
    _: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (!event) return;

    const input = event.currentTarget;

    if (input.value === "" || input.value === undefined) {
      setInputValue("");
      onChange?.("", event);
      return;
    }

    if (input.value === formatting.symbols.minus) {
      setInputValue(formatting.symbols.minus);
      onChange?.("", event);
      return;
    }

    const { selectionStart, selectionEnd } = input;
    const computerized = computerize(input.value, formatting);
    const humanized = humanize(computerized, formatting);
    const offset = humanized.length - input.value.length;

    input.value = humanized;
    input.selectionStart = Math.max(selectionStart + offset, 0);
    input.selectionEnd = Math.max(selectionEnd + offset, 0);
    setInputValue(event.target.value);

    if (input.value.slice(-1) === formatting.symbols.decimal) return;

    const computerizedValue = computerize(event.target.value, formatting);
    const numericValue = parseFloat(computerizedValue);

    if (isNaN(numericValue)) return;

    const roundedValue = parseFloat(numericValue.toFixed(precision));

    if (onChange) {
      const newEvent = replaceChangeEventValue(event, roundedValue);
      onChange(roundedValue, newEvent);
    }
  }

  function handleKeyPress(
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const input = event.currentTarget;
    const { selectionStart, selectionEnd } = input;

    const beforeSelection = input.value.slice(0, selectionStart);
    const afterSelection = input.value.slice(input.selectionEnd);

    const isNegative = input.value[0] === formatting.symbols.minus;
    const numericValue = parseFloat(computerize(input.value, formatting));

    if ("-−".includes(event.key)) {
      if (min >= 0) {
        event.preventDefault();
        return;
      }

      if (selectionStart === 0 && selectionEnd > 0) {
        input.value = `${formatting.symbols.minus}${afterSelection}`;
      } else {
        const newValue = isNegative
          ? input.value.slice(1)
          : `${formatting.symbols.minus}${input.value}`;

        const tooLarge = -numericValue > max;
        const tooSmall = -numericValue < min;
        const zeroWithPositiveConstraint = input.value === "0" && min >= 0;

        if (!tooLarge && !tooSmall && !zeroWithPositiveConstraint) {
          input.value = newValue;
        }

        if (tooSmall) {
          raiseError(translate("errors.number.too-small", { min }));
        }

        if (tooLarge) {
          raiseError(translate("errors.number.too-large", { max }));
        }
      }

      event.preventDefault();
      if (input.value !== "-" && input.value !== "-0") {
        onChange?.(-numericValue, syntesizeChangeEvent(input));
      }

      return;
    }

    if (event.key == "+") {
      const newValue = isNegative ? input.value.slice(1) : input.value;

      if (-numericValue <= max && numericValue < 0) {
        input.value = newValue;
        onChange(-numericValue, syntesizeChangeEvent(input));
      } else {
        raiseError(translate("errors.number.too-large", { max }));
      }

      event.preventDefault();
      return;
    }

    if (
      event.key === formatting.symbols.decimal &&
      (numberType == "integer" ||
        beforeSelection.includes(formatting.symbols.decimal) ||
        afterSelection.includes(formatting.symbols.decimal))
    ) {
      event.preventDefault();
      return;
    }

    if (!/[0-9]/.test(event.key) && event.key !== formatting.symbols.decimal) {
      event.preventDefault();
      return;
    }

    const newValue = parseFloat(
      computerize(`${beforeSelection}${event.key}${afterSelection}`, formatting)
    );

    if (newValue > max) {
      event.preventDefault();
      raiseError(translate("errors.number.too-large", { max }));
      return;
    }

    if (newValue < min) {
      event.preventDefault();
      raiseError(translate("errors.number.too-small", { min }));
      return;
    }

    onKeyPress?.(event);
  }

  function handlePaste(
    event: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const input = event.currentTarget;
    const pasteResult =
      input.value.slice(0, input.selectionStart) +
      pastedData +
      input.value.slice(input.selectionEnd);
    const computerizedResult = computerize(pasteResult, formatting);
    const resultSign = computerizedResult[0] === "-" ? -1 : 1;
    const resultDigits = computerizedResult.replace("-", "");
    const numericResult = parseFloat(`${resultSign}${resultDigits}}`);

    if (isNaN(numericResult)) {
      event.preventDefault();
      return;
    }

    if (numericResult > max) {
      event.preventDefault();
      raiseError(translate("errors.number.too-large", { max }));
      return;
    }

    if (numericResult < min) {
      event.preventDefault();
      raiseError(translate("errors.number.too-small", { min }));
      return;
    }

    onPaste?.(event);
  }

  return (
    <Input
      {...{
        value: inputValue,
        type: "text",
        onPaste: handlePaste,
        onChange: handleChange,
        onKeyPress: handleKeyPress,
        ...props,
      }}
    />
  );
}
