import { defaultOptions, ObfuscatorOptions } from "./obfuscate-options";
import { parseAny } from "./object-formats";

export function getObfuscate(options: ObfuscatorOptions = {}) {
  return (input: string) => obfuscate(input, options);
}

export function getObfuscateObject(options: ObfuscatorOptions = {}) {
  return (input: Record<string, any>) => obfuscateObject(input, options);
}

export function obfuscateObject(
  input: Record<string, any>,
  options: ObfuscatorOptions = {}
): Record<string, any> | null {
  options = { ...defaultOptions, ...options };

  if (input === null) return null;

  if (Array.isArray(input)) {
    return input.map((item) =>
      obfuscate(item, { ...options, ignoreParsingFailure: true })
    );
  }

  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => {
      for (const secretKey of options.secretKeys) {
        if (key.includes(secretKey)) {
          return [key, options.encrypter(String(value))];
        }
      }

      if (typeof value === "string") {
        return [key, obfuscate(value, options)];
      }

      if (typeof value === "object") {
        return [key, obfuscateObject(value, options)];
      }

      return [key, value];
    })
  );
}

export function obfuscate(
  input: string,
  options: ObfuscatorOptions = {}
): string {
  options = { ...defaultOptions, ...options };

  try {
    const { parsed, stringifier } = parseAny(input, options.formatters);
    if (typeof parsed === "object") {
      return stringifier(obfuscateObject(parsed));
    }
    if (typeof parsed === "string") {
      return obfuscate(parsed, { ...options, ignoreParsingFailure: true });
    }
    return input;
  } catch (e) {
    if (options.ignoreParsingFailure) {
      return input;
    }
    throw e;
  }
}
