const arrow = 4;
const border = 1;

export const isocelesArrow4pxCss = `
    .arrow {
      visibility: hidden;
    }

    .arrow div {
      visibility: visible;
      transform: rotate(45deg);
    }

    .tooltip[data-popper-placement^="top"] .arrow {
      bottom: -${arrow}px;
      left: calc(50% - ${arrow}px - 8px);
    }

    .tooltip[data-popper-placement^="top"] .arrow div {
      border-right-width: ${border}px;
      border-right-style: solid;
      border-bottom-width: ${border}px;
      border-bottom-style: solid;
    }

    .tooltip[data-popper-placement^="bottom"] .arrow {
      top: -${arrow}px;
      left: calc(50% - ${arrow}px);
    }

    .tooltip[data-popper-placement^="bottom"] .arrow div {
      border-left-width: ${border}px;
      border-left-style: solid;
      border-top-width: ${border}px;
      border-top-style: solid;
    }

    .tooltip[data-popper-placement^="bottom-end"] .arrow {
      top: -${arrow}px;
      left: calc(100% - ${6 * arrow}px);
    }

    .tooltip[data-popper-placement^="bottom-end"] .arrow div {
      border-left-width: ${border}px;
      border-left-style: solid;
      border-top-width: ${border}px;
      border-top-style: solid;
    }

    .tooltip[data-popper-placement^="left"] .arrow {
      right: -${arrow}px;
      top: calc(50% - ${arrow}px);
    }

    .tooltip[data-popper-placement^="left"] .arrow div {
      border-top-width: ${border}px;
      border-top-style: solid;
      border-right-width: ${border}px;
      border-right-style: solid;
    }

    .tooltip[data-popper-placement^="right"] .arrow {
      left: -${arrow}px;
      top: calc(50% - ${arrow}px);
    }

    .tooltip[data-popper-placement^="right"] .arrow div {
      border-bottom-width: ${border}px;
      border-bottom-style: solid;
      border-left-width: ${border}px;
      border-left-style: solid;
    }
  `;
