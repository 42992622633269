import classNames from "classnames";
import { ReactNode } from "react";
import { access, Accessor } from "utility/accessor";

import { RadioWithLabel } from "../radio-with-label";
import { RadioWithLabelProps } from "../radio-with-label/radio-with-label";

export interface RadioGroupProps<Option>
  extends Omit<RadioWithLabelProps, "value" | "label"> {
  options: Option[];
  value: Option;
  type?: "vertical" | "horizontal";
  onChange: (option: Option) => any;
  idAccessor?: Accessor<string, Option>;
  labelAccessor?: Accessor<ReactNode, Option>;
}

export function RadioGroup<Option>({
  options,
  value,
  onChange,
  type = "horizontal",
  idAccessor = String,
  labelAccessor = String,
  className,
  style,
  floating,
  ...props
}: RadioGroupProps<Option>) {
  return (
    <div {...{ className, style }}>
      <div
        className={classNames(
          "flex",
          type == "horizontal" ? "flex-row space-x-4" : "flex-col space-y-3"
        )}
      >
        {options.map((option) => {
          const id = access(idAccessor, option);
          const isSelected = id === access(idAccessor, value);
          return (
            <RadioWithLabel
              value={isSelected}
              floating={floating}
              label={access(labelAccessor, option)}
              onClick={() => onChange(option)}
              key={id}
              labelClassName="cursor-pointer"
            />
          );
        })}
      </div>
    </div>
  );
}
