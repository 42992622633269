import { PublicEmployer } from "store/employer/employer-models";
import { Currency } from "store/enums/currencies";
import { safeParseFloat, safeParseInt } from "utility/numbers";

import { PublicJob, PublicJobListItem } from "./public-job-models";
import {
  PublicJobListResponseItem,
  PublicJobResponse,
  PublicJobResponseEmployer,
} from "./public-job-responses";

export function deserializePublicJobAdEmployer(
  response: PublicJobResponseEmployer,
): PublicEmployer {
  return {
    employerId: response.id,
    companyId: response.company.id,
    name: response.company.name,
    addresses: response.company.addresses,
    contacts: [],
    default_benefits: response.company.default_benefits,
    description: response.company.description,
    logo: response.company.logo,
    job_sources: response.company.job_sources,
    competitors: response.company?.competitors?.map((c) => c.name) || [],
    personalizedUrl: response.personalized_url,
    linkedinUrl: response.company.linkedin,
    phone: response.company?.contacts[0]?.contact?.phone_number || "",
    website: response.company?.contacts[0]?.contact?.links?.[0] || "",
  };
}

export function deserializePublicJobAd(response: PublicJobResponse): PublicJob {
  return {
    id: response.id,
    title: response.title,
    employer: deserializePublicJobAdEmployer(response.company),
    description: response.description,
    questions: response.questions?.map((q) => ({
      ...q,
      isArchived: q.is_archived,
    })),
    payment: {
      id: response?.payment?.id,
      isVisible: response?.payment?.is_visible,
      annualMin: safeParseInt(String(response?.payment?.annual_min), undefined),
      annualMax: safeParseInt(String(response?.payment?.annual_max), undefined),
      hourlyMin: safeParseFloat(response?.payment?.hourly_min, undefined),
      hourlyMax: safeParseFloat(response?.payment?.hourly_max, undefined),
      currency: response?.payment?.currency as Currency,
    },
    experience: {
      id: response.experience?.id,
      isVisible: response.experience?.is_visible,
      preferred: response.experience?.preferred,
      required: response.experience?.required,
    },
    location: {
      id: response.location?.id,
      locationType: response.location?.required,
      address: response.location?.addresses?.[0],
      regions: response.location?.regions || [],
      countries: response.location?.countries || [],
      timeZones: response.location?.timezones || [],
    },
    position: {
      id: response?.position?.id,
      isVisible: response?.position?.is_visible,
      preferred: response?.position?.preferred,
      required: response?.position?.required,
      hours: response?.position?.desired_working_hours,
    },
    skills: response.skills,
    benefits: response.benefits,
    minimumDegree: response.minimum_degree,
    publishDate: response.publish_date,
    sources: response?.company.company.job_sources,
    archived: response.archived,
    isAssessmentJob: response.is_assessment_jobad,
  };
}

export function deserializePublicJobListItem(
  input: PublicJobListResponseItem,
): PublicJobListItem {
  return {
    jobId: input.id,
    title: input.title,
    jobCategoryName: input.job_category_name,
    publishDate: new Date(input.publish_date),
  };
}
