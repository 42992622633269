import { Folder } from "./folder-models";
import { FolderResponse } from "./folder-responses";

export function deserializeFolder(folder: FolderResponse): Folder {
  return {
    id: folder.id,
    name: folder.name,
    isSingleton: folder.is_singleton,
    order: folder.order,
    defaultSort: {
      column: ["in progress", "incomplete"].includes(folder.name.toLowerCase())
        ? "Date"
        : "Score",
      direction: "-",
    },
  };
}
