import React from "react";
import classNames from "classnames";
import { StyleProps } from "../interfaces";

export interface SwitchProps extends StyleProps {
  radius?: string;
  checked?: boolean;
  onChange?: (val: boolean) => void;
  disabled?: boolean;
  colorClass?: string;
  offText?: string;
  onText?: string;
  labelPosition?: "left" | "right" | "top" | "bottom";
  labelClass?: string;
}

export const Switch = ({
  checked,
  className,
  onChange,
  disabled = false,
  colorClass = "bg-success",
  labelPosition,
  labelClass = "poppins-medium",
  onText,
  offText,
  style,
}: SwitchProps) => {
  return (
    <div {...{ className, style }}>
      <div
        onClick={() => onChange(!checked)}
        className={classNames(
          "flex h-full items-center gap-2",
          {
            left: "flex-row-reverse",
            right: "flex-row",
            top: "flex-col-reverse",
            bottom: "flex-col",
          }[labelPosition] ?? "flex-row",
          "cursor-pointer leading-relaxed"
        )}
      >
        <div className="relative inline-block" data-testid="switch">
          <div
            className={classNames(
              "h-[11px] w-[40px] rounded-lg opacity-30",
              checked ? colorClass : "bg-charcoal-500",
              disabled ? "bg-secondary-300" : ""
            ).toString()}
          />
          <div
            className={classNames(
              "absolute -top-1/3",
              "h-[19px] w-[19px] rounded-full",
              "transition-all duration-300",
              checked ? colorClass : disabled ? "" : "bg-charcoal-500",
              disabled ? "bg-secondary-300" : "",
              checked ? "left-full -translate-x-full" : "left-0"
            )}
          />
        </div>
        <div className={labelClass}>
          {checked ? (
            <span className="text-success">{onText}</span>
          ) : (
            <span className="text-charcoal-500">{offText}</span>
          )}
        </div>
      </div>
    </div>
  );
};
