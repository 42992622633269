import { LaunchScreen } from "components/atomic/launch-screen/launch-screen";
import { useAuth } from "hooks/auth";
import { useTranslation } from "hooks/translation";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useQueryClient } from "react-query";
import { PublicRoutes } from "routes/routes";
import { setAccessToken } from "store/auth";
import { getUser, UserType } from "store/user";
import { axios } from "utility/axios";

interface RedirectionProps {
  children: ReactNode;
  skipAuth?: boolean;
  requireAuth?: boolean;
  userRoot?: Partial<Record<UserType, string>>;
}

export function Redirection({
  children,
  skipAuth,
  requireAuth,
  userRoot,
}: RedirectionProps) {
  const { $t } = useTranslation();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { user, isAuthenticated, isAuthenticating, hijacker } = useAuth({
    redirect: requireAuth,
    enabled: !skipAuth,
  });

  if (!skipAuth && isAuthenticating) {
    return <LaunchScreen context="redirection: isAuthenticating" />;
  }

  if (
    isAuthenticated &&
    (router.pathname === "/" || router.pathname === PublicRoutes.Login)
  ) {
    const path = userRoot[user?.type];
    if (path && path !== router.pathname && !router.query.next) {
      router.push(path);
      return <LaunchScreen context="redirection: redirect" />;
    }
  }

  return (
    <>
      {hijacker && (
        <div className="relative z-50 pb-6">
          <div className="fixed w-full bg-charcoal-dark text-xs text-white">
            <div className="pc-container flex justify-between py-1.5">
              <span>Hijacked by {hijacker}</span>
              <a
                className="text-white underline"
                onClick={() =>
                  axios
                    .post(
                      "/auth/revert-login-as/",
                      {},
                      {
                        waitMessage: $t("account:login-as.return"),
                        successMessage: $t("common:actions.redirecting"),
                        errorMessage: $t("account:login-as.revert-failure"),
                      },
                    )
                    .then(async ({ data }) => {
                      setAccessToken(data.access);
                      queryClient.setQueryData("user", await getUser());
                      router.push("/admin/accounts");
                    })
                }
              >
                Return to Admin Mode
              </a>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
}
