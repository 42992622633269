import classNames from "classnames";
import { ReactNode } from "react";
import { resolveAccessor } from "./accessor";
import { OptionListProps } from "./interfaces";

export default function OptionList<Option extends any>({
  isOpen,
  value,
  reset,
  options,
  optionId,
  optionDisplay,
  getMenuProps,
  getItemProps,
  highlightedIndex,
  resetOnDoubleSelection,
}: OptionListProps<Option>) {
  return (
    <ul
      {...{
        ...getMenuProps(),
        className: classNames(
          isOpen ? "block" : "hidden",
          "px-1 sm:py-2 py-1 z-10",
          "font-poppins"
        ),
        style: { outline: "0" },
      }}
      data-testid="select-option-list"
    >
      {isOpen &&
        options.map((option, index) => {
          const optionIsSelected =
            value &&
            resolveAccessor(option, optionId) ==
              resolveAccessor(value, optionId);

          const { onClick, ...props } = getItemProps({ item: option, index });
          const handleInteraction = (event) => {
            if (optionIsSelected) {
              resetOnDoubleSelection && reset && reset?.();
            } else {
              onClick?.(event);
            }
          };

          return (
            <li
              className={classNames(
                "text-primary",
                "cursor-pointer",
                "px-1 py-1 sm:px-4 sm:py-4",
                "font-semibold",
                "box-border rounded",
                "flex",
                "items-center",
                highlightedIndex === index
                  ? optionIsSelected
                    ? "bg-success-100"
                    : "bg-primary-100"
                  : optionIsSelected && "bg-success-100"
              )}
              key={resolveAccessor<Option, string>(option, optionId)}
              {...{
                ...props,
                onClick: handleInteraction,
                onTouchEnd: handleInteraction,
              }}
              data-testid="select-option-list-item"
            >
              <div>
                {resolveAccessor<Option, ReactNode>(option, optionDisplay)}
              </div>
            </li>
          );
        })}
    </ul>
  );
}
