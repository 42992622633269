import classNames from "classnames";
import { DialogProps } from ".";

export function WorkingDialog({
  children = "Working",
  width,
  height,
  record,
  onClose = ({ resolve }) => {
    resolve(false);
  },
  overlay = true,
  canClose = true,
  ...props
}: DialogProps) {
  const handleClose = async () =>
    canClose && !(await onClose(record)) && record.delete();

  return (
    <div
      className={classNames(
        "fixed top-0 left-0 h-screen w-screen",
        overlay && "z-20 animate-fade-in bg-overlay-light",
        "flex items-center justify-center"
      )}
      onClick={handleClose}
    >
      <div
        className={classNames(
          "bg-primary text-white",
          "animate-bounce rounded",
          "relative z-10 p-4"
        )}
        data-testid="working-dialog"
      >
        {children}
      </div>
    </div>
  );
}
