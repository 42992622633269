import { UseQueryResult } from "react-query";

export function refactorQueryResult<D, E, T extends string>(
  name: T,
  original: UseQueryResult<D, E>
) {
  return {
    [name]: original.data,
    [`${name}UpdatedAt`]: original.dataUpdatedAt,
    [`${name}Error`]: original.error,
    [`${name}ErrorUpdatedAt`]: original.errorUpdatedAt,
    [`${name}FailureCount`]: original.failureCount,
    [`${name}HasError`]: original.isError,
    [`${name}Fetched`]: original.isFetched,
    [`${name}FetchedAfterMount`]: original.isFetchedAfterMount,
    [`${name}Fetching`]: original.isFetching,
    [`${name}Idle`]: original.isIdle,
    [`${name}Loading`]: original.isLoading,
    [`${name}HasLoadingError`]: original.isLoadingError,
    [`${name}IsPlaceholder`]: original.isPlaceholderData,
    [`${name}IsPrevious`]: original.isPreviousData,
    [`${name}HasRefetchError`]: original.isRefetchError,
    [`${name}Refetching`]: original.isRefetching,
    [`${name}HasSuccess`]: original.isSuccess,
    [`${name}IsStale`]: original.isStale,
    [`refetch${name[0].toUpperCase()}${name.slice(1)}`]: original.refetch,
    [`remove${name[0].toUpperCase()}${name.slice(1)}`]: original.remove,
    [`${name}Status`]: original.status,
  } as RefactoredQueryResult<UseQueryResult<D, E>, T>;
}

interface QueryResultMap<N extends string> {
  data: N;
  dataUpdatedAt: `${N}UpdatedAt`;
  error: `${N}Error`;
  errorUpdatedAt: `${N}ErrorUpdatedAt`;
  failureCount: `${N}FailureCount`;
  isError: `${N}HasError`;
  isFetched: `${N}Fetched`;
  isFetchedAfterMount: `${N}FetchedAfterMount`;
  isFetching: `${N}Fetching`;
  isIdle: `${N}Idle`;
  isLoading: `${N}Loading`;
  isLoadingError: `${N}HasLoadingError`;
  isPlaceholderData: `${N}IsPlaceholder`;
  isPreviousData: `${N}IsPrevious`;
  isRefetchError: `${N}HasRefetchError`;
  isRefetching: `${N}Refetching`;
  isSuccess: `${N}HasSuccess`;
  isStale: `${N}IsStale`;
  refetch: `refetch${Capitalize<N>}`;
  remove: `remove${Capitalize<N>}`;
  status: `${N}Status`;
}

export type RefactoredQueryResult<QR, N extends string> = {
  [K in keyof QR as K extends keyof QueryResultMap<N>
    ? QueryResultMap<N>[K]
    : never]: QR[K];
};

function test(queryResult: UseQueryResult, name: "test") {
  const {
    test,
    testUpdatedAt,
    testError,
    testErrorUpdatedAt,
    testFailureCount,
    testHasError,
    testFetched,
    refetchTest,
    removeTest,
    testStatus,
    testFetchedAfterMount,
    testFetching,
    testIdle,
    testLoading,
    testHasLoadingError,
    testIsPlaceholder,
    testIsPrevious,
    testHasRefetchError,
    testRefetching,
    testHasSuccess,
    testIsStale,
  } = refactorQueryResult(name, queryResult);
}
