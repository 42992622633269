import { useCallback, useEffect, useRef, useState } from "react";

export function useTriggeredEffect(effect: () => void, deps: any[] = []) {
  const [triggerState, setTriggerState] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [triggerState, ...deps]);

  return useCallback(
    () => setTriggerState((oldTriggerState) => !oldTriggerState),
    []
  );
}
