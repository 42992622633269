import { JobApplicantId, JobApplicantNoteId } from "store/job-applicant";
import { JobId } from "store/job/interfaces";
import { RoundId } from "store/round/interfaces";
import { UserId } from "store/user";
import { MicroDate } from "utility/dates";

export type NotificationId = string & { __type: "NotificationId" };
export type EmployerNotificationKey = string & { __type: "NotificationKey" };

export const validEmployerNotificationTypes = [
  // "candidate-passed-screening",
  "candidate-failed-screening",
  // "candidate-inactive",
  "candidate-completed-round",
  "mentioned-in-note",
] as const;

export const validCandidateNotificationTypes = [
  "round-changed",
  "application-rejected",
  "application-incomplete",
  "application-shortlisted",
  "new-job-opportunity",
] as const;

export const validAdminNotificationTypes = ["employer-registered"] as const;

export const validNotificationTypes = [
  ...validEmployerNotificationTypes,
  ...validCandidateNotificationTypes,
  ...validAdminNotificationTypes,
] as const;

export type NotificationType = typeof validNotificationTypes[number];
export type EmployerNotificationType =
  typeof validEmployerNotificationTypes[number];

export type EmployerNotificationList = EmployerNotification[];

export interface EmployerNotification {
  type: EmployerNotificationType;
  key: EmployerNotificationKey;
  ids: Set<NotificationId>;
  job: {
    id: JobId;
    title: string;
  };
  round: {
    id: RoundId;
    name: string;
  };
  users: {
    id: UserId;
    firstName: string;
    lastName: string;
  }[];
  notes: {
    id: JobApplicantNoteId;
    userId: UserId;
    jobApplicantId: JobApplicantId;
    firstName: string;
    lastName: string;
  }[];
  seen: boolean;
  seenAt: MicroDate;
  openedAt: MicroDate;
  dismissedAt: MicroDate;
  createdAt: MicroDate;
  updatedAt: MicroDate;
}
