import { useEffect, useState } from "react";
import { JobApplicantId } from "store/job-applicant";
import { saveTestStats } from "store/test-taking";
import { TestId } from "store/test/interfaces";

type Props = {
  jobApplicantId: JobApplicantId;
  testId: TestId;
};
export const usePasteDetected = ({ jobApplicantId, testId }: Props) => {
  useEffect(() => {
    const handlePaste = () => {
      saveTestStats({
        jobApplicantId,
        testId,
        pasteDetected: true,
      });
    };

    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [jobApplicantId, testId]);
};
