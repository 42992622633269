import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { paginateArray } from "store/pagination";
import { mergeObjects } from "utility/poke";
import { RecursivePartial } from "utility/types";
import {
  JobApplicant,
  JobApplicantList,
  JobApplicantListItem,
} from "../job-applicant-models";

export function getListItemUpdatesFromRetrieve(
  freshItem: JobApplicant
): RecursivePartial<JobApplicantListItem> {
  return {
    phScore: freshItem.phScore,
    firstName: freshItem.firstName,
    lastName: freshItem.lastName,
    fullName: freshItem.fullName,
    email: freshItem.owner.email,
    education: freshItem.owner.applicant.educations?.map(
      ({ education }) => education
    ),
    job: {
      title: freshItem.job.title,
      requirements: {
        hoursPerWeek: freshItem.job.position.hours,
        degree: freshItem.job.minimumDegree,
        yearsOfExperience: freshItem.job.experience.required,
      },
    },
    hasNotes: freshItem.notes.length > 0,
    hoursPerWeek: freshItem.preferences?.workingHours,
    experience: freshItem.experience,
    folderId: freshItem.folderId,
    inWorkflow: freshItem.inWorkflow,
    starred: freshItem.starred,
    status: freshItem.status,
  };
}

export function useSyncJobApplicantItem() {
  const queryClient = useQueryClient();

  return useCallback(
    (freshItem: JobApplicant) => {
      const listQueries = queryClient.getQueriesData<JobApplicantList>([
        "job-applicant",
        "list",
      ]);

      if (listQueries) {
        listQueries.forEach(([key, cachedList]) => {
          let queryShouldBeUpdated = false;
          if (!cachedList) return;
          const updatedCache = cachedList.map((cachedItem) => {
            if (cachedItem.id === freshItem.id) {
              const updatedItem = mergeObjects(
                getListItemUpdatesFromRetrieve(freshItem),
                cachedItem
              );

              if (updatedItem !== cachedItem) {
                queryShouldBeUpdated = true;
              }

              return updatedItem;
            }

            return cachedItem;
          });

          if (queryShouldBeUpdated) {
            queryClient.setQueryData<JobApplicantList>(key, () =>
              "pagination" in cachedList
                ? paginateArray(updatedCache, cachedList.pagination)
                : updatedCache
            );
          }
        });
      }
    },
    [queryClient]
  );
}
