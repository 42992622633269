import { useContext, useEffect, useState } from "react";
import {
  WindowScrollContext,
  type WindowScrollAccuracy,
} from "./window-scroll-context";
import { discreteUpdate } from "./discrete-update";

interface WindowScrollHookOptions {
  accuracy?: WindowScrollAccuracy;
}

export function useWindowScroll({
  accuracy = {
    x: 1,
    y: 1,
    width: 1,
    height: 1,
  },
}: WindowScrollHookOptions = {}) {
  const globalState = useContext(WindowScrollContext);
  const [state, setState] = useState(globalState);

  useEffect(() => {
    setState((oldValues) => discreteUpdate(globalState, accuracy, oldValues));
  }, [accuracy, globalState]);

  return state;
}
