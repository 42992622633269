module.exports = {
  locales: ["en"],
  defaultLocale: "en",
  pages: {
    "*": [
      "common",
      "workflow",
      "test",
      "email",
      "timezone",
      "country",
      "region",
      "account",
    ],
    "rgx:^/admin/debug": ["question", "job"],
    "rgx:^/admin/test-questions": ["question"],
    "rgx:^/admin/job-questions": ["question"],
    "rgx:^/admin/company-questions": ["question"],
    "rgx:^/admin/tests": ["test"],
    "rgx:^/admin/tests/.+": ["question"],
    "rgx:^/admin/emails": ["email"],
    "rgx:^/admin/assessments": ["assessment"],
    "rgx:^/employer/reference-check-forms": [
      "reference",
      "assessment",
      "question",
    ],
    "rgx:^/admin/job-categories": ["job-category", "assessment", "question"],
    "rgx:^/employer": ["employer"],
    "rgx:^/company": ["job"],
    "rgx:^/employer/jobs": [
      "job",
      "question",
      "assessment",
      "applicant",
      "reference",
    ],
    "rgx:^/employer/applicant": ["applicant"],
    "rgx:^/employer/assessment": ["assessment", "question"],
    "rgx:^/employer/settings": ["team"],
    "rgx:^/jobs/": ["applicant", "job"],
    "rgx:^/test/shared-link/": ["applicant"],
    "rgx:^/applicant": ["job", "applicant"],
    "rgx:^/employer/marketplace": ["applicant"],
  },
};
