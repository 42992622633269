let globalVersion = 0.01;
export function getGlobalCacheVersion() {
  return globalVersion;
}

export function setGlobalCacheVersion(version: number) {
  globalVersion = version;
}

async function save<D = unknown>(
  key: string,
  retrieve: (() => Promise<D>) | (() => D),
  version: number
) {
  const data = await retrieve();
  localStorage.setItem(
    key,
    JSON.stringify([version, Math.floor(Date.now() / 60000), data])
  );

  return data;
}

interface CacheOptions {
  version?: number;
  minutesToLive?: number;
  staleWhileRevalidate?: boolean;
}

export async function remember<D = unknown>(
  key: string,
  retrieve: (() => Promise<D>) | (() => D),
  options: CacheOptions = {}
): Promise<D> {
  const {
    version = 0.01,
    minutesToLive = Infinity,
    staleWhileRevalidate = true,
  } = options;
  const entry = JSON.parse(localStorage.getItem(key));

  if (!entry || !Array.isArray(entry) || entry.length !== 3) {
    console.error(`Cache entry for ${key} is malformed`);
    return save(key, retrieve, version);
  }

  const storedGlobalVersion = JSON.parse(localStorage.getItem("cache-version"));
  const [entryVersion, entryTime, entryData] = entry;

  const minutesElapsed = Date.now() / 60000 - entryTime;

  const cacheIsValid = storedGlobalVersion === globalVersion;
  const entryIsFresh = minutesElapsed < minutesToLive;
  const entryIsValid = entryVersion === version;

  if (cacheIsValid && entryIsValid && entryIsFresh) {
    return entryData;
  }

  localStorage.setItem("cache-version", JSON.stringify(globalVersion));

  if (staleWhileRevalidate) {
    save(key, retrieve, version);
    return entryData;
  }

  return save(key, retrieve, version);
}
