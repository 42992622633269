import { useDialog } from "components/atomic/dialog";
import { useInvalidator } from "hooks/invalidator";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { authAxios } from "utility/axios";
import { AxiosDialogProps } from "utility/axios/interfaces";
import {
  syncJobApplicantStarredCache,
  syncJobApplicantStatusCache,
} from "utility/cache-update";
import { syncJobApplicantRoundCache } from "utility/cache-update/sync-job-applicant-round-cache";

import { useTranslation } from "hooks/translation";
import { delay } from "utility/delay/delay";
import { syncJobApplicantAsssessment } from "./cache/job-applicant-assessment-cache";
import { StarJobApplicantProps } from "./interfaces";
import { getQueryString } from "./job-applicant-filters";
import { JobApplicantId } from "./job-applicant-models";
import {
  ChangeApplicantFolder,
  ChangeApplicantRound,
  ChangeApplicantWorkflowStatus,
  RejectApplicant,
  SendJobApplicantAssessment,
  SendJobApplicantCustomEmail,
  SendJobApplicantEmail,
} from "./job-applicant-mutations";
import { JobApplicantRequest } from "./job-applicant-requests";
import { JobApplicantResponse } from "./job-applicant-responses";

export function useJobApplicantManager() {
  const queryClient = useQueryClient();
  const dialog = useDialog();
  const { $t } = useTranslation();

  const { invalidate, invalidateAll } = useInvalidator<JobApplicantId>(
    "job-applicant",
    queryClient,
  );

  const update = useCallback(
    async (
      jobApplicant: JobApplicantRequest,
      invalidate = true,
      config: AxiosDialogProps = {},
    ) => {
      const { data }: { data: JobApplicantResponse } = await authAxios.patch(
        `companies/job-applicant/${jobApplicant.id}/`,
        jobApplicant,
        config,
      );

      if (invalidate) invalidateAll();
    },
    [invalidateAll],
  );

  const star = useCallback(
    async ({
      jobApplicant,
      folderId,
      invalidate = false,
    }: StarJobApplicantProps) => {
      const updatedValue = !jobApplicant.starred;
      await update(
        {
          id: jobApplicant.id,
          jobad: jobApplicant.job.id,
          starred: updatedValue,
        },
        invalidate,
      );
      syncJobApplicantStarredCache({
        queryClient,
        jobApplicant,
        folderId,
        newStarred: updatedValue,
      });
    },
    [queryClient, update],
  );

  const changeWorkflowStatus = useCallback(
    async ({
      filters,
      status,
      axiosDialogProps,
    }: ChangeApplicantWorkflowStatus) => {
      const { data } = await authAxios.patch(
        `/companies/job-applicant/change-workflow-status/?${getQueryString(
          filters,
        )}`,
        {
          in_workflow: status,
        },
        axiosDialogProps,
      );

      if (filters.id) {
        syncJobApplicantStatusCache({
          queryClient,
          jobApplicantIds: filters.id,
          status,
        });
      } else {
        invalidateAll();
      }
      return data;
    },
    [invalidateAll, queryClient],
  );

  const changeRound = useCallback(
    async ({
      filters,
      status,
      defaultReminder,
      disabledEmailStepIds,
      invalidate = false,
      axiosDialogProps,
    }: ChangeApplicantRound) => {
      const { data } = await authAxios.patch(
        `/companies/job-applicant/change-round/?${getQueryString(filters)}`,
        {
          round: status.roundId,
          default_reminder: defaultReminder,
          disabled_emails: disabledEmailStepIds,
        },
        axiosDialogProps,
      );
      queryClient.invalidateQueries(["email-threads"]);

      if (filters.id && !invalidate) {
        syncJobApplicantRoundCache({
          queryClient,
          jobApplicantIds: filters.id,
          status,
        });

        for (const id of filters.id) {
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "list",
            id,
          ]);
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "item",
            id,
          ]);
          queryClient.invalidateQueries(["test-history", "list", id]);
          queryClient.invalidateQueries(["job-applicant", "item", id]);
        }
      } else {
        invalidateAll();
      }
    },
    [invalidateAll, queryClient],
  );

  const changeFolder = useCallback(
    async ({ filters, folderId, axiosDialogProps }: ChangeApplicantFolder) => {
      const { data } = await authAxios.patch(
        `/companies/job-applicant/change-folder/?${getQueryString(filters)}`,
        {
          folder: folderId,
        },
        axiosDialogProps,
      );

      invalidateAll();
    },
    [invalidateAll],
  );

  const reject = useCallback(
    async ({
      filters,
      scheduledOn,
      shouldSendRejectionEmail,
      axiosDialogProps,
    }: RejectApplicant) => {
      if (filters.id) {
      }

      await authAxios.patch(
        `/companies/job-applicant/reject/?${getQueryString(filters)}`,
        {
          scheduled_on: scheduledOn,
          should_send_rejection_email: shouldSendRejectionEmail,
        },
        axiosDialogProps,
      );

      setTimeout(() => {
        invalidateAll();

        if (filters.id) {
          setTimeout(() => {
            for (const id of filters.id) {
              queryClient.invalidateQueries([
                "job-applicant",
                "email-threads",
                "list",
                id,
              ]);
              queryClient.invalidateQueries([
                "job-applicant",
                "email-threads",
                "item",
                id,
              ]);
            }
          }, 1200);
        }
        queryClient.invalidateQueries(["email-threads"]);
      }, 1200);
    },
    [invalidateAll, queryClient],
  );

  const sendAssessment = useCallback(
    async ({ filters, assessment }: SendJobApplicantAssessment) => {
      const { data } = await authAxios.post(
        `/companies/job-applicant/send-assessment/?${getQueryString(filters)}`,
        {
          assessment: assessment.id,
        },
        {
          hideWaitDialog: true,
          hideSuccessDialog: true,
        },
      );

      if (filters.id) {
        for (const id of filters.id) {
          syncJobApplicantAsssessment(queryClient, id, assessment);
        }

        for (const id of filters.id) {
          queryClient.invalidateQueries(["job-applicant", "item", id]);
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "list",
            id,
          ]);
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "item",
            id,
          ]);
          queryClient.invalidateQueries(["test-history", "list", id]);
        }
      } else {
        queryClient.invalidateQueries(["job-applicant", "item"]);
        queryClient.invalidateQueries(["test-history"]);
      }
      queryClient.invalidateQueries(["email-threads"]);

      return data;
    },
    [queryClient],
  );
  const sendReferenceCheck = useCallback(
    async ({ filters, assessmentId, applicantReferenceId }) => {
      const { data } = await authAxios.post(
        `/companies/job-applicant/send-reference-check/?${getQueryString(filters)}`,
        {
          assessment_id: assessmentId,
          applicant_reference_id: applicantReferenceId,
        },
        {
          waitMessage: "Sending..",
          successMessage: "Sent",
        },
      );
      queryClient.invalidateQueries([
        "job-applicant",
        "item",
        filters.id[0],
        "overview",
      ]);
      return data;
    },
    [queryClient],
  );

  const sendEmail = useCallback(
    async ({ filters, emailId, scheduledOn }: SendJobApplicantEmail) => {
      const { data } = await authAxios.post(
        `/companies/job-applicant/send-email/?${getQueryString(filters)}`,
        {
          email: emailId,
          scheduled_on: scheduledOn,
        },
        {
          waitMessage: scheduledOn
            ? $t("common:actions.scheduling")
            : $t("common:actions.sending"),
          successMessage: scheduledOn
            ? $t("common:actions.scheduled")
            : $t("common:actions.sent"),
          errorMessage: $t("common:error"),
        },
      );
      await delay(1500);
      queryClient.invalidateQueries(["email-threads"]);

      if (filters.id) {
        for (const id of filters.id) {
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "list",
            id,
          ]);
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "item",
            id,
          ]);
        }
      }
    },
    [$t, queryClient],
  );
  const sendCustomEmail = useCallback(
    async ({
      filters,
      subject,
      body,
      scheduledOn,
      threadId,
    }: SendJobApplicantCustomEmail) => {
      const { data } = await authAxios.post(
        `/companies/job-applicant/send-custom-email/?${getQueryString(
          filters,
        )}`,
        {
          subject,
          body,
          scheduled_on: scheduledOn,
          thread: threadId,
        },
        {
          waitMessage: scheduledOn
            ? $t("common:actions.scheduling")
            : $t("common:actions.sending"),
          successMessage: scheduledOn
            ? $t("common:actions.scheduled")
            : $t("common:actions.sent"),
          errorMessage: $t("common:error"),
        },
      );
      queryClient.invalidateQueries(["email-history", "list"]);
      queryClient.invalidateQueries(["email-threads"]);

      if (threadId) {
        queryClient.invalidateQueries(["email-threads", "item", threadId]);
      }
      if (filters.id) {
        await delay(1500);

        for (const id of filters.id) {
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "list",
            id,
          ]);
          queryClient.invalidateQueries([
            "job-applicant",
            "email-threads",
            "item",
            id,
          ]);
        }
      }
    },
    [$t, queryClient],
  );

  const cancelScheduledEmail = async ({
    jobApplicantId,
    emailHistoryId,
    threadId,
  }) => {
    await authAxios.delete(
      `jobs/job-applicant/${jobApplicantId}/email-history/${emailHistoryId}/`,
      {
        waitMessage: "Cancelling..",
        successMessage: "Cancelled",
      },
    );
    queryClient.invalidateQueries(["email-history", "list"]);

    if (threadId) {
      queryClient.invalidateQueries(["email-threads", "item", threadId]);
      queryClient.invalidateQueries(["email-threads", "list"]);
    }

    queryClient.invalidateQueries([
      "job-applicant",
      "email-threads",
      "list",
      jobApplicantId,
    ]);
    queryClient.invalidateQueries([
      "job-applicant",
      "email-threads",
      "item",
      jobApplicantId,
    ]);
  };

  return {
    star,
    reject,
    changeWorkflowStatus,
    changeRound,
    changeFolder,
    invalidate,
    sendAssessment,
    sendEmail,
    cancelScheduledEmail,
    sendCustomEmail,
    sendReferenceCheck,
  };
}
