import { AttainedDegree, DegreeType } from "./education-enums";

export function compareDegrees(
  degree1: AttainedDegree | DegreeType,
  degree2: AttainedDegree | DegreeType
): number {
  if (degree1 === degree2) return 0;
  const degreeValues = Object.values(AttainedDegree);
  const degreeIndex1 = degreeValues.indexOf(degree1 as AttainedDegree);
  const degreeIndex2 = degreeValues.indexOf(degree2 as AttainedDegree);

  return degreeIndex1 - degreeIndex2;
}
