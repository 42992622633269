import { Tooltip } from "components/popup";
import { dateString, formattedTime } from "utility/dates";

import { useInjections } from "../injection";
import { StyleProps } from "../interfaces";
import { Placement } from "@popperjs/core";

interface DateDisplayProps extends StyleProps {
  date: Date;
  tooltipPlacement?: Placement;
  showTime?: boolean;
}

export function DateDisplay({
  date,
  tooltipPlacement = "bottom",
  className = "text-base",
  style,
  showTime = false,
}: DateDisplayProps) {
  const { translate, language } = useInjections();
  return date ? (
    <span {...{ className, style }}>
      <Tooltip
        tip={date.toLocaleString()}
        options={{
          placement: tooltipPlacement,
        }}
      >
        {showTime && formattedTime(date)}
        {showTime && " "}
        {dateString(date, translate, language)}
      </Tooltip>
    </span>
  ) : null;
}
