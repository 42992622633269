import { QueryClient } from "react-query";
import { FolderId } from "store/folder";
import {
  JobApplicantId,
  JobApplicantList,
  JobApplicantListItem,
} from "store/job-applicant";
import { PageData, paginateArray } from "store/pagination";

type Props = {
  queryClient: QueryClient;
  jobApplicantIds: JobApplicantId[];
  newFolderId: FolderId;
};
export function syncJobApplicantFolderCache({
  queryClient,
  jobApplicantIds,
  newFolderId,
}: Props) {
  const listQueries = queryClient.getQueriesData<
    PageData<JobApplicantListItem>
  >(["job-applicant", "list"]);

  listQueries.forEach(([key, data]) => {
    if (key[2]) {
      // folderId is not null
      data = paginateArray(
        data.filter((item) => !jobApplicantIds.includes(item.id)),
        data.pagination
      );
      queryClient.setQueryData(key, data);
    }
  });
}
