import { StateProps } from "utility/object-state";
import {
  QuestionType,
  ResponseQuestionTypeProps,
  serializeQuestionType,
} from ".";
import { JobCategoryId } from "store/job-category/interfaces";
import { PaginationFilters } from "store/pagination";
import { RoughQuestionType } from "./interfaces";
import { serializeRoughQuestionType } from "./question-serialization";

export const validJobQuestionSources = ["ph", "employer"] as const;
export type JobQuestionSource = typeof validJobQuestionSources[number];

export type QuestionFilterState = PaginationFilters &
  StateProps<{
    title: string;
    sources: JobQuestionSource[];
    questionType?: QuestionType;
    roughQuestionTypes?: RoughQuestionType[];
    jobCategoryIds: JobCategoryId[];
  }> & {
    reset: () => void;
  };

export interface QuestionRequestFilters
  extends PaginationFilters,
    ResponseQuestionTypeProps {
  title?: string;
  sources?: string;
  jobad_category?: string;
}

export function serializeQuestionFilters(
  filters: QuestionFilterState
): QuestionRequestFilters {
  if (!filters) return {} as QuestionRequestFilters;

  const { type, weighted, answer_type } = filters.questionType
    ? serializeQuestionType(filters.questionType)
    : { type: undefined, weighted: undefined, answer_type: undefined };

  const roughTypes =
    filters.roughQuestionTypes &&
    filters.roughQuestionTypes.flatMap(serializeRoughQuestionType);

  return {
    title: filters.title || undefined,
    sources: filters.sources.join(",") || undefined,
    jobad_category: filters.jobCategoryIds.join(",") || undefined,
    type: (type ||
      roughTypes?.join(",") ||
      undefined) as QuestionRequestFilters["type"],
    weighted,
    answer_type,
    offset: filters.offset,
    limit: filters.limit,
  };
}
