export function safeGetText(node: Node): string {
  if (!node) return "";
  if (typeof node === "string") return node;
  if ("innerText" in node) return (node as any).innerText;
  return node.textContent || "";
}

export function getFirstNodeText(html: string): string {
  const firstNode = getElement(html).firstChild;
  if (typeof firstNode === "string") return firstNode;
  return safeGetText(firstNode);
}

export function getText(html: string): string {
  return safeGetText(getElement(html));
}

export function findFinalNode(element: Node): Node {
  if (!element) return null;
  while (element.lastChild) {
    element = element.lastChild;
  }

  return element;
}

export function insertInlineHtml(element: Node, html: string) {
  const finalNode = findFinalNode(element);
  const insertNode =
    finalNode.nodeType === Node.ELEMENT_NODE
      ? (finalNode as Element)
      : finalNode.parentElement;

  insertNode.insertAdjacentHTML("beforeend", html);
  return element;
}

export function getHtmlSubstring(
  html: string,
  maxLength: number,
  insertHtml = "...",
  trimAngleOpen = true
) {
  const text = getText(html);
  if (text.length <= maxLength) return html;

  const ratio = html.length / text.length;
  const length = Math.floor(maxLength * ratio);
  const slicedHtml = html.slice(0, length);
  const element = getElement(slicedHtml);
  insertInlineHtml(element, insertHtml);
  const completedHtml = element.innerHTML;

  if (trimAngleOpen && completedHtml.endsWith("<"))
    return completedHtml.slice(0, -1);

  return completedHtml;
}

export function getElement(html: string): HTMLElement {
  if (typeof document === "undefined") {
    return new DOMParser().parseFromString(html, "text/html").documentElement;
  } else {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div;
  }
}

let canvas: HTMLCanvasElement;
let context2d: CanvasRenderingContext2D;

if (typeof document !== "undefined") {
  canvas = document.createElement("canvas");
  context2d = canvas && canvas.getContext("2d");
}

export function getTextWidth(text: string, font: string): number {
  if (!context2d) return 0;

  context2d.font = font;
  const metrics = context2d.measureText(text);
  return metrics.width;
}

export function hasImgTag(html: string): boolean {
  const element = getElement(html);
  const imgElements = element.getElementsByTagName("img");
  return imgElements.length > 0;
}

export function hasContent(html: string): boolean {
  return Boolean(getText(html).trim()) || hasImgTag(html);
}
