import { authAxios } from "utility/axios";
import {
  PaymentMethod,
  StripeCheckoutRequest,
  SubscriptionPlan,
} from "./interfaces";
import { useQuery, useQueryClient } from "react-query";
import {
  deserializePaymentMethod,
  deserializeSubscriptionPlan,
} from "./serializers";
import { refactorQueryResult } from "utility/refactor";
import { useUser } from "store/user";
import { useMemo } from "react";
import { AxiosResponse } from "axios";
import { PaymentMethodResponse } from "./responses";

export async function getSubscriptionPlans(): Promise<SubscriptionPlan[]> {
  const { data } = await authAxios.get(`subscription-plans/`);
  return data.results.map(deserializeSubscriptionPlan);
}
export async function cancelSubscriptionRequest(): Promise<any> {
  const { data } = await authAxios.post(
    `payments/cancel-subscription/`,
    {},
    {
      waitMessage: "Cancelling...",
      successMessage: "Cancelled",
    },
  );
  return data;
}
export const useSubscriptionManager = () => {
  const queryClient = useQueryClient();
  const cancelSubscription = async () => {
    await cancelSubscriptionRequest();
    queryClient.invalidateQueries(["employer", "own"]);
  };
  return {
    cancelSubscription,
  };
};

export function useSubscriptionPlans() {
  return refactorQueryResult(
    "subscriptionPlans",
    useQuery({
      queryKey: ["subscription-plan", "list"],
      queryFn: () => getSubscriptionPlans(),
    }),
  );
}

export function useCurrentSubscriptionPlan() {
  const { data: user, isFetched: isUserFetched } = useUser();
  const { subscriptionPlans, subscriptionPlansFetched } =
    useSubscriptionPlans();

  const currentSubscriptionPlan = useMemo(
    () =>
      subscriptionPlans?.find(
        (p) => p.id === user?.employer?.subscriptionPlanId,
      ),
    [subscriptionPlans, user],
  );
  return {
    currentSubscriptionPlan,
    fetchedSubscriptionPlan: subscriptionPlansFetched && isUserFetched,
  };
}
export async function getStripeClientSecret({
  credits,
  email,
  subscriptionTier,
  setupMode = false,
}: StripeCheckoutRequest) {
  const {
    data: { clientSecret },
  } = await authAxios.post(
    "/payments/create-checkout-session/",
    {
      credits,
      subscription_tier: subscriptionTier,
      setup_mode: setupMode,
    },
    {
      hideSuccessDialog: true,
      hideWaitDialog: true,
    },
  );
  return clientSecret;
}

export async function fetchPaymentMethods(): Promise<AxiosResponse<any>> {
  try {
    const response = await authAxios.get(`payments/payment-method/`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPaymentMethods(): Promise<PaymentMethod[]> {
  const { data } = await authAxios.get(`payments/payment-method/`);
  return data.results.map(deserializePaymentMethod);
}

export function usePaymentMethods() {
  return refactorQueryResult(
    "paymentMethods",
    useQuery({
      queryKey: ["payment-method", "list"],
      queryFn: () => getPaymentMethods(),
    }),
  );
}
