import { useTranslation } from "hooks/translation";
import { useCallback, useMemo } from "react";
import { setAccessToken } from "store/auth";
import { axios } from "utility/axios";
import { handleAccountError } from "./registration-errors";
import { RegistrationResult } from "./registration-models";

import {
  RegisterApplicantMutation,
  RegisterEmployerMutation,
  ResetPasswordMutation,
  SendPasswordResetEmailMutation,
  SendVerificationEmailMutation,
  VerifyEmailAddressMutation,
} from "./registration-mutations";
import {
  serializeApplicantRegistration,
  serializeEmailVerification,
  serializeEmployerRegistration,
  serializePasswordReset,
} from "./registration-serialization";

export function useRegistrationManager() {
  const { $t } = useTranslation();
  const registerEmployer = useCallback(
    (mutation: RegisterEmployerMutation) =>
      axios
        .post("/companies/register/", serializeEmployerRegistration(mutation), {
          hideErrorDialog: true,
          hideSuccessDialog: true,
          hideWaitDialog: true,
        })
        .then((response) => response.data)
        .catch(handleAccountError),
    []
  );

  const registerApplicant = useCallback(
    (mutation: RegisterApplicantMutation) => {
      const { data, params } = serializeApplicantRegistration(mutation);
      return axios
        .post("/applicants/register/", data, {
          params,
          hideWaitDialog: true,
          hideSuccessDialog: true,
        })
        .then((response) => response.data)
        .catch(handleAccountError);
    },
    []
  );

  const resetPassword = useCallback(async (mutation: ResetPasswordMutation) => {
    return axios
      .post("/auth/reset-and-confirm/", serializePasswordReset(mutation))
      .then((response) => response.data)
      .catch(handleAccountError);
  }, []);

  const sendVerificationEmail = useCallback(
    async ({
      email,
      waitMessage,
      successMessage,
      errorMessage,
    }: SendVerificationEmailMutation) => {
      return axios.post(
        "/auth/registration/resend-email/",
        { email },
        {
          waitMessage,
          successMessage,
          errorMessage,
          isBlockingAction: true,
        }
      );
    },
    []
  );

  const sendPasswordResetEmail = useCallback(
    async (mutation: SendPasswordResetEmailMutation) => {
      const requestData: SendPasswordResetEmailMutation = mutation;
      return axios.post("/auth/password/reset/", requestData, {
        waitMessage: $t("common:actions.sending"),
        successMessage: $t("common:actions.sent"),
      });
    },
    []
  );

  const verifyEmailAddress = useCallback(
    async (mutation: VerifyEmailAddressMutation) => {
      const { data } = await axios.post(
        "/auth/confirm-and-login/",
        serializeEmailVerification(mutation)
      );

      setAccessToken(data.access);
      return data.access;
    },
    []
  );

  return useMemo(
    () => ({
      registerEmployer,
      registerApplicant,
      sendVerificationEmail,
      sendPasswordResetEmail,
      verifyEmailAddress,
      resetPassword,
    }),
    [
      registerEmployer,
      registerApplicant,
      sendVerificationEmail,
      sendPasswordResetEmail,
      verifyEmailAddress,
      resetPassword,
    ]
  );
}
