import { CheckboxProps } from "../../checkbox";
import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import { Variant, variantClasses } from "utility/variant-classes";
import classNames from "classnames";

export interface RadioProps extends CheckboxProps {
  disabled?: boolean;
  variant?: Variant;
  isCheckedVariant?: Variant;
}

export const Radio: FC<RadioProps> = ({
  radioEdge = "20px",
  value,
  onClick,
  defaultValue,
  className,
  disabled,
  variant = "primary",
  style,
  isCheckedVariant = variant,
}) => {
  const [isChecked, setIsChecked] = useState(defaultValue || value);
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }

      if (value === undefined) {
        setIsChecked(!isChecked);
      }
      onClick && onClick(Boolean(!value), event);
    },
    [value, disabled, isChecked, onClick]
  );
  useEffect(() => {
    setIsChecked(value);
  }, [value]);
  return (
    <div {...{ className, style }}>
      <div
        style={{
          width: radioEdge,
          height: radioEdge,
        }}
        onClick={handleOnClick}
        className={classNames(
          `rounded-full`,
          `border-2 `,
          variantClasses(
            {
              primary: "border-primary",
              secondary: "border-secondary",
              white: "border-white",
              blue: "border-blue",
              success: "border-success ",
              danger: "border-danger",
              warning: "border-warning",
              disabled: "border-secondary-300",
            },
            {
              variant: isChecked ? isCheckedVariant : variant,
              disabled,
            }
          ),
          `relative`,
          `cursor-pointer`
        )}
      >
        {isChecked ? (
          <div
            style={{ width: "50%", height: "50%" }}
            className={classNames(
              `rounded-full`,
              `absolute`,
              `bottom-1/2 left-1/2`,
              `translate-y-1/2 -translate-x-1/2`,
              variantClasses(
                {
                  primary: "bg-primary",
                  secondary: "bg-secondary",
                  white: "bg-white",
                  blue: "bg-blue",
                  success: "bg-success ",
                  danger: "bg-danger",
                  warning: "bg-warning",
                  disabled: "bg-secondary-300",
                },
                {
                  variant: isChecked ? isCheckedVariant : variant,
                  disabled,
                }
              ),
              className
            )}
          ></div>
        ) : null}
      </div>
    </div>
  );
};
