import { FormField } from "components/atomic/form-field";

import { InputProps, NumericInputProps, SimpleInputProps } from "./interfaces";
import { NumericInput } from "./numeric-input";
import SimpleInput from "./simple-input";

export default function Input(props: InputProps) {
  const isNumeric = ["number", "integer"].includes(props.type);
  const Component = isNumeric ? NumericInput : SimpleInput;

  return (
    <FormField<
      Omit<SimpleInputProps, "variant"> | Omit<NumericInputProps, "variant">
    >
      element={Component}
      {...props}
    />
  );
}
