import { ArrayDecorator } from "./array-decorator";
import { ResponsePagination } from "./paginated-response";
import {
  firstMillionPagination,
  OffsetLimitPagination,
} from "./pagination-filters";

export type ArrayPagination = OffsetLimitPagination & ResponsePagination;

export const defaultPagination: ArrayPagination = {
  count: 0,
  previous: "",
  next: "",
  ...firstMillionPagination,
};

/**
 * A decorator for arrays that adds pagination information.
 * The decorator is necessary because any information added to the array
 * will be lost when the array is restored from the store by react-query.
 * This works because the decorator is not an array, but an object that
 * contains an array. So react query preserves all of the properties.
 */
export class PageData<T, P = ArrayPagination> extends ArrayDecorator<T> {
  constructor(input: T[], public pagination: P) {
    super(input);
  }

  decorate<U>(value: U[]): ArrayDecorator<U> {
    return new PageData(value, this.pagination);
  }
}

export const paginationKeys = Object.keys(defaultPagination);

export function paginateArray<T>(input: T[], pagination = defaultPagination) {
  pagination = Object.keys(pagination)
    .filter((key) => paginationKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = pagination[key];
      return obj;
    }, {} as ArrayPagination);

  return new PageData(input, pagination);
}

export const emptyPage = paginateArray([]);
