import Link from "next/link";
import { Fragment, useState } from "react";

import { LogoutIcon } from "assets/icons";
import { IconButton } from "components/atomic/buttons";
import { useTranslation } from "hooks/translation";
import { AdminRoutes } from "routes/routes";
import { logout } from "store/auth";

const menu = [
  { title: "questions", link: "/admin/test-questions" },
  { title: "tests", link: "/admin/tests" },
  { title: "assessments", link: AdminRoutes.AssessmentList },
  { title: "emails", link: AdminRoutes.EmailList },
  { title: "job-categories", link: "/admin/job-categories" },
  { title: "accounts", link: "/admin/accounts" },
  { title: "hijack", link: "/admin/accounts" },
  { title: "marketplace", link: AdminRoutes.Marketplace },
];

export function AdminMenu() {
  const { $t } = useTranslation();
  const [isAccountHovered, setIsAccountHovered] = useState(false);

  const handleAccountsHover = (hovered) => {
    setIsAccountHovered(hovered);
  };

  let subMenu;

  if (isAccountHovered) {
    const hijack = menu.find((item) => item.title === "hijack");
    const marketplace = menu.find((item) => item.title === "marketplace");

    subMenu = (
      <div className="absolute -right-20 top-full w-max border border-solid border-black bg-white p-2 shadow">
        <div className="m-2 text-left">
          <Link key={hijack.title} href={hijack.link}>
            {$t(`common:menu.${hijack.title}`)}
          </Link>
        </div>
        <div className="m-2 text-left">
          <Link key={marketplace.title} href={marketplace.link}>
            {$t(`common:menu.${marketplace.title}`)}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <nav className="flex h-full items-center justify-center">
      <section className="flex space-x-7 text-right font-poppins text-primary">
        {menu.map((item) => (
          <Fragment key={item.title}>
            {item.title !== "accounts" &&
              item.title !== "hijack" &&
              item.title !== "marketplace" && (
                <Link key={item.title} href={item.link}>
                  {$t(`common:menu.${item.title}`)}
                </Link>
              )}
            {item.title === "accounts" && (
              <div
                key={item.title}
                className={`relative ${isAccountHovered ? "hovered" : ""}`}
                onMouseEnter={() => handleAccountsHover(true)}
                onMouseLeave={() => handleAccountsHover(false)}
              >
                <span className="cursor-default">
                  {$t(`common:menu.${item.title}`)}
                </span>
                {isAccountHovered && subMenu}
              </div>
            )}
          </Fragment>
        ))}
      </section>
      <div className="mx-[40px] h-full w-[1px] bg-silver"></div>
      <IconButton onClick={logout} icon={LogoutIcon} />
    </nav>
  );
}
