import { Logo } from "components/brand";
import Loader from "components/loader/loader";
import { EmployerMenu } from "components/menu";
import { useUserStatus } from "hooks/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import NotFoundPage from "pages/404";
import { accessToken } from "store/auth";
import { UserType } from "store/user";
import { decodeToken } from "utility/auth";
import { sendErrorToSentry } from "utility/sentry";

import { AuthLayout, LayoutProps } from "./";
import { useFolderApplicationCounts } from "store/job-applicant";
import classNames from "classnames";
import { useScreenSize } from "utility/detection";
import CustomDialog from "components/employer/subscription-plans/custom-dialog";
import { PiWarning } from "react-icons/pi";
import { EmployerRoutes } from "routes/routes";
import { useStripeCheckout } from "components/employer/payment/hooks/use-stripe-checkout";
import { SubscriptionFailed } from "components/employer/subscription-plans/subscription-failed";

export function EmployerLayout({ children }: LayoutProps) {
  const {
    userIsFetched,
    userIsValid,
    employerFetched,
    companyProfileIsCompleted,
    IsQuestionnaireComplete,
    companyQuestionnaireStep,
    paymentFailed,
  } = useUserStatus([UserType.Employer, UserType.Admin]);
  const router = useRouter();
  const { isDesktop } = useScreenSize();

  const isApplicantDetailsLayout =
    router.pathname === "/employer/jobs/applicant/[job-applicant-id]" ||
    router.pathname === "/employer/marketplace/applicant/[applicant-id]";

  const isSettingsPage = router.pathname.includes("/employer/settings");
  useFolderApplicationCounts({ enabled: !isSettingsPage });

  if (!userIsFetched || !employerFetched) return <Loader />;
  if (!userIsValid) {
    const { header, payload, signature } = decodeToken(accessToken);
    sendErrorToSentry(new Error("User is not valid for admin layout"), {
      extra: {
        userIsFetched,
        userIsValid,
        pathname: router.pathname,
        t: {
          header,
          payload,
          signature,
        },
      },
    });

    return (
      <AuthLayout>
        <NotFoundPage />
      </AuthLayout>
    );
  }

  if (!companyProfileIsCompleted) {
    if (!router.asPath.includes("/employer/create-profile")) {
      router.push("/employer/create-profile");
      return <Loader />;
    }
  } else if (!IsQuestionnaireComplete) {
    if (!router.asPath.includes("/employer/questionnaire")) {
      router.push(`/employer/questionnaire?step=${companyQuestionnaireStep}`);
      return <Loader />;
    }
  }

  const employerHeaderMenu = (
    <div
      id="employer-layout-menu-container"
      className={classNames(
        "flex h-full w-fit items-center xs:w-full",
        isApplicantDetailsLayout
          ? isDesktop
            ? ""
            : "pc-container"
          : "pc-container",
      )}
    >
      <div className=" flex  w-full max-w-[100vw] items-center">
        <div className="w-fit flex-shrink-0 lg:w-[300px]">
          <Link href="/">
            <Logo className="w-36 lg:ml-8 lg:w-48" />
          </Link>
        </div>
        <div className="container flex-shrink overflow-hidden px-2">
          <EmployerMenu />
        </div>
      </div>
    </div>
  );
  return (
    <div className="flex min-h-screen animate-fade-in flex-col  bg-silver">
      <header className="z-5 flex h-16 w-full flex-col justify-center  bg-white lg:h-24">
        <div className="ph-container">{employerHeaderMenu}</div>
      </header>

      {employerFetched &&
      paymentFailed &&
      !router.asPath.includes(EmployerRoutes.Plans) &&
      !router.asPath.includes(EmployerRoutes.Payment) ? (
        <SubscriptionFailed />
      ) : (
        <div className="ph-container w-full flex-1">{children}</div>
      )}
    </div>
  );
}
