import {
  FieldMesssageProps,
  HasMessageProps,
  MessageProps,
} from "components/atomic/form-field/interfaces";
import { isocelesArrow4pxCss } from "components/popup/containers/isoceles-arrow-4px-css";
import { usePopup } from "hooks/popup";
import { useEffect } from "react";
import { BroadVariant } from "utility/variant-classes/interfaces";

import { Message } from "./message";

function getVariant({
  variant,
  message,
  success,
  error,
  hint,
  disabled,
}: FieldMesssageProps): BroadVariant {
  if (disabled) return "disabled";
  if (variant) return variant;
  if (error) return "error";
  if (success) return "success";
  if (hint) return "hint";
  if (message) return "hint";
  return "none";
}

function getMessage(props: FieldMesssageProps, variant: BroadVariant) {
  return props[variant] && typeof props[variant] !== "boolean"
    ? props[variant]
    : props.message;
}

function getMessageProps(props: FieldMesssageProps): MessageProps {
  const realVariant = getVariant(props);
  return {
    variant: realVariant,
    message: getMessage(props, realVariant),
  };
}

export function HasMessage({
  render,
  messageId,
  focused,
  messageClassName = "",
  ...props
}: HasMessageProps) {
  const { anchorRef, popupProps, update } = usePopup({
    placement: "bottom-end",
    sameWidth: false,
  });

  useEffect(() => {
    update && update();
  }, [update]);

  const messageProps = getMessageProps(props);
  return (
    <div className={`group ${messageClassName}`}>
      <div ref={anchorRef}>{render({ variant: messageProps.variant })}</div>
      {messageProps.message && (
        <div {...popupProps} className="tooltip">
          <style>{isocelesArrow4pxCss}</style>
          <Message id={messageId} hidden={!focused} {...messageProps} />
        </div>
      )}
    </div>
  );
}
