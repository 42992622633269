import { config } from "pages/jobs/[...public-job]";
import { DialogAxiosRequestConfig } from "utility/axios/interfaces";
import { isEmpty } from "utility/poke";

export function getAxiosReqDialogMessage(
  config: DialogAxiosRequestConfig<{}>
): string {
  const { isDeleting, isArchived, isRestoring, isCopying } =
    determineRequestAction(config);

  let message: string;
  switch (true) {
    case isDeleting && isArchived:
      message = "Archiving...";
      break;
    case isDeleting && !isArchived:
      message = "Deleting...";
      break;
    case isRestoring:
      message = "Restoring...";
      break;
    case isCopying:
      message = "Copying...";
      break;
    default:
      message = "Saving...";
  }

  return config.waitMessage || message;
}

export function getAxiosResponseDialogMessage(
  config: DialogAxiosRequestConfig<{}>
): string {
  const { isDeleting, isArchived, isRestoring, isCopying } =
    determineRequestAction(config);
  let message: string;
  switch (true) {
    case isDeleting && isArchived:
      message = "Archived";
      break;
    case isDeleting && !isArchived:
      message = "Deleted";
      break;
    case isRestoring:
      message = "Restored";
      break;
    case isCopying:
      message = "Copied";
      break;
    default:
      message = "Saved";
  }

  return config.successMessage || message;
}

function determineRequestAction(config: DialogAxiosRequestConfig<{}>) {
  const isDeleting = config.method.toUpperCase() === "DELETE";
  const isArchived =
    config.params?.archived === "false" || config.params?.archived === false;
  const isRestoring =
    config.method.toUpperCase() === "PATCH" &&
    config.params?.archived === true &&
    isEmpty(config.data);
  const isCopying =
    config.method.toUpperCase() === "POST" &&
    JSON.stringify(config.data)?.includes("(Copy)");

  return { isDeleting, isArchived, isRestoring, isCopying };
}
