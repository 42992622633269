import { defaultOptions } from "./obfuscate-options";

export interface ObjectFormatter {
  parser: (input: string) => Record<string, any> | string;
  stringifier: (records: Record<string, string>) => string;
}

export function parseAny(
  input: string,
  formatters: ObjectFormatter[] = defaultOptions.formatters
) {
  for (const formatter of formatters) {
    try {
      return {
        stringifier: formatter.stringifier,
        parsed: formatter.parser(input),
      };
    } catch (e) {
      continue;
    }
  }

  throw new Error("Invalid input");
}

export function createFormats(assignmentOperators, recordSeparators) {
  return [
    { parser: JSON.parse, stringifier: JSON.stringify },
    ...assignmentOperators.flatMap((assignmentOperator) =>
      recordSeparators.map((recordSeparator) => ({
        parser: (input: string) =>
          parseSetting(input, assignmentOperator, recordSeparator),
        stringifier: (records: Record<string, string>) =>
          stringifySetting(records, recordSeparator, assignmentOperator),
      }))
    ),
  ];
}

function parseSetting(
  input: string,
  assignmentOperator: string,
  recordSeparator: string
): Record<string, string> {
  if (!input.includes(assignmentOperator)) throw new Error("Invalid input");
  const records = input.split(recordSeparator);
  return records.reduce((parsed, record) => {
    const assignmentIndex = record.indexOf(assignmentOperator);
    if (assignmentIndex === -1) throw new Error("Invalid input");
    const key = record.slice(0, assignmentIndex);
    const value = record.slice(assignmentIndex + assignmentOperator.length);
    parsed[key] = value;
    return parsed;
  }, {} as Record<string, string>);
}

function stringifySetting(
  records: Record<string, string>,
  recordSeparator: string,
  assignmentOperator: string
) {
  return Object.entries(records)
    .map(([key, value]) => `${key}${assignmentOperator}${value}`)
    .join(recordSeparator);
}
