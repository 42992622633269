import { useState, useEffect } from "react";
import { JobApplicantId } from "store/job-applicant";
import { saveTestStats } from "store/test-taking";
import { TestId } from "store/test/interfaces";
import { streamCamVideo } from "utility/stream-video";

type Props = {
  isCameraPermissionGranted: boolean;
  isMicPermissionGranted: boolean;
  testId: TestId;
  jobApplicantId: JobApplicantId;
};

const useVideoStream = ({
  isCameraPermissionGranted,
  isMicPermissionGranted,
  testId,
  jobApplicantId,
}: Props) => {
  const [videoRef, setVideoRef] = useState(null);

  useEffect(() => {
    saveTestStats({
      micEnabled: isMicPermissionGranted,
      webcamEnabled: isCameraPermissionGranted,
      testId: testId,
      jobApplicantId,
    });
  }, [
    isCameraPermissionGranted,
    isMicPermissionGranted,
    testId,
    jobApplicantId,
  ]);

  useEffect(() => {
    if (isCameraPermissionGranted && videoRef) {
      streamCamVideo({
        videoRef: videoRef,
        isCameraPermissionGranted,
        isMicPermissionGranted,
      });
    }
    return () => {
      if (videoRef) {
        const tracks = videoRef.srcObject?.getTracks();
        if (!tracks) return;
        tracks.forEach((track) => {
          track.stop();
        });
      }
    };
  }, [isCameraPermissionGranted, isMicPermissionGranted, videoRef]);

  return { setVideoRef, videoRef };
};

export default useVideoStream;
