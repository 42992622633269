import { Logo } from "components/brand";
import React from "react";

type Props = {
  context: string;
  message?: string;
};

export const LaunchScreen = ({ context, message }: Props) => {
  return (
    <section>
      <Logo
        className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        width={200}
      />
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 translate-y-6 font-poppins text-secondary">
        {message}
      </div>
    </section>
  );
};
