import { CloseIcon, EyeIcon } from "assets/icons";
import classNames from "classnames";
import AdornedNode from "components/atomic/form-field/adorned-node";
import BorderBox from "components/atomic/form-field/border-box";
import React, { useCallback, useMemo, useState } from "react";
import { ComponentSize } from "utility/component-size";
import { safeParseInt } from "utility/numbers";
import { isNotEmpty } from "utility/poke";

import { IconButton } from "../buttons";
import { useInjections } from "../injection";
import { SimpleInputProps } from "./interfaces";
import { useOverflow } from "./overflow-hook";
import { useRequired } from "./required-hook";

export default function SimpleInput({
  className,
  style,
  rows = 1,
  value,
  variant,
  onPaste,
  onChange,
  onKeyDown,
  onKeyPress,
  borderColor,
  paddingClass,
  leftAdornment,
  rightAdornment,
  raiseError,
  clearable,
  maxLength,
  required,
  nodeClasses,
  ...props
}: SimpleInputProps) {
  const { translate } = useInjections();

  const componentSize = new ComponentSize(props);
  props = componentSize.otherProps;

  const isPassword = props.type === "password";
  const [type, setType] = useState(props.type);

  const [element, setElement] = useState<
    HTMLInputElement | HTMLTextAreaElement
  >();

  useRequired(required, value, raiseError);
  const { handleKeyPress, handlePaste } = useOverflow({
    maxLength: safeParseInt(String(maxLength), undefined),
    onPaste,
    onChange,
    onKeyPress,
    raiseError,
    translate,
  });

  function handleKeyDown(
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>,
  ) {
    const keyIsDeletion = ["Backspace", "Delete"].includes(event.key);
    const keyIsPrintable = event.key.length === 1;

    if (keyIsDeletion || keyIsPrintable) {
      raiseError?.(null);
    }

    onKeyDown?.(event);
  }

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    let formattedValue = event.target.value;
    const maxLength = 16; // Define maximum length for card number

    if (props.type === "card") {
      formattedValue = formattedValue.replace(/\s/g, ""); // Remove spaces

      // Format the value with spaces every 4 digits
      if (formattedValue.length > maxLength) {
        formattedValue = formattedValue.substr(0, maxLength); // Trim excess characters
      }
      formattedValue = formattedValue.replace(/(\d{4})/g, "$1 ").trim(); // Add spaces every 4 digits
    } else if (props.type === "expiry") {
      formattedValue = formattedValue.replace(/\D/g, ""); // Remove non-numeric characters
      const maxLength = 4; // Define maximum length for expiry date
      // Format the value with a slash after the second character
      if (formattedValue.length > maxLength) {
        formattedValue = formattedValue.substr(0, maxLength); // Trim excess characters
      }
      formattedValue = formattedValue
        .replace(/(\d{2})(\d{0,2})/, "$1/$2")
        .trim(); // Add slash after the second character
    }

    onChange?.(formattedValue, event);
  };

  rightAdornment = useMemo(
    () =>
      isPassword ? (
        <div className="flex items-stretch gap-2">
          {rightAdornment}
          <IconButton
            icon={EyeIcon}
            className="-mt-0.5"
            scale="small"
            variant={type === "text" ? "primary" : "disabled"}
            onClick={(event) => {
              event.stopPropagation();
              const { selectionStart, selectionEnd } = element;
              setType(type === "text" ? "password" : "text");
            }}
          />
        </div>
      ) : (
        rightAdornment
      ),
    [element, isPassword, rightAdornment, type],
  );

  rightAdornment = useMemo(
    () =>
      maxLength ? (
        <div className="flex items-stretch gap-2">
          <div className="flex items-center text-xs text-charcoal-600">
            {String(value).length ?? 0}/{maxLength}
          </div>
          {rightAdornment}
        </div>
      ) : (
        rightAdornment
      ),
    [maxLength, rightAdornment, value],
  );

  rightAdornment = useMemo(
    () =>
      clearable && value ? (
        <div className="flex items-stretch gap-2">
          <IconButton
            icon={CloseIcon}
            scale="tiny"
            compact
            className="mt-0.5"
            variant="secondary"
            stroke
            onClick={(event) => {
              event.stopPropagation();
              onChange?.("", event);
            }}
          />
          {rightAdornment}
        </div>
      ) : (
        rightAdornment
      ),
    [clearable, onChange, rightAdornment, value],
  );

  const inputProps = {
    ...props,
    ref: setElement,
    type,
    value,
    onPaste: handlePaste,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onKeyPress: handleKeyPress,
    className: classNames(
      `w-full flex-1 border-0`,
      `placeholder:text-charcoal-600`,
      `focus:border-0 focus:outline-none`,
    ),
    style: {
      height: isNotEmpty(componentSize.height) ? "100%" : rows * 28,
    },
  };

  const handleBoxClick = useCallback(() => {
    element?.focus();
  }, [element]);

  return (
    <div
      {...{
        className,
        style,
      }}
    >
      <BorderBox
        {...{
          variant,
          disabled: props.disabled,
          borderColor,
          paddingClass,
          onClick: handleBoxClick,
          ...componentSize.props,
        }}
      >
        <AdornedNode
          className={classNames(
            "border-b-2 font-poppins text-base",
            nodeClasses,
          )}
          leftAdornment={leftAdornment}
          rightAdornment={rightAdornment}
        >
          {rows > 1 ? (
            <textarea {...inputProps} rows={rows} />
          ) : (
            <input {...inputProps} />
          )}
        </AdornedNode>
      </BorderBox>
    </div>
  );
}
