import { useState } from "react";
import { usePopper } from "react-popper";

import { beforeWrite } from "@popperjs/core";

import { PopupOptions } from "./interfaces";

const sameWidthModifier = {
  name: "sameWidth",
  enabled: true,
  phase: beforeWrite,
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.elements.reference.offsetWidth}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

export function usePopup({
  offset = [0, 10],
  placement = "auto",
  strategy = "absolute",
  sameWidth = false,
}: PopupOptions = {}) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      strategy,
      modifiers: [
        {
          name: "offset",
          options: { offset },
        },
        sameWidth && sameWidthModifier,
      ].filter(Boolean),
    }
  );

  return {
    update,
    anchorRef: setReferenceElement,
    popupProps: {
      ref: setPopperElement,
      style: { ...styles.popper, zIndex: 20 },
      ...attributes.popper,
    },
  };
}
