import { useRouter } from "next/router";
import { useMemo } from "react";

const prefixIterators = {};

export default function useUniqueId(prefix: string = "") {
  const router = useRouter();
  const fullPrefix = `${router.pathname.slice(1).replace("/", "--")}-${prefix}`;
  return useMemo(() => {
    if (fullPrefix in prefixIterators) {
      return `${fullPrefix}${++prefixIterators[fullPrefix]}`;
    } else {
      return `${fullPrefix}${(prefixIterators[fullPrefix] = 1)}`;
    }
  }, [fullPrefix]);
}
