import { setHttp } from "utility/links";
import { searchify } from "utility/search";
import { Employer, EmployerListItem } from "./employer-models";
import { EmployerRequest } from "./employer-requests";
import { EmployerListResponse, EmployerResponse } from "./employer-responses";
import { CountryCode } from "utility/geography";

export function deserializeEmployerList(
  response: EmployerListResponse,
): EmployerListItem[] {
  return response.results.map((employer) => ({
    employerId: employer.id,
    companyId: employer.company_id,
    name: employer.name,
    search: searchify(employer.name || ""),
    memberCount: employer.member_count,
  }));
}

export function deserializeEmployer(response: EmployerResponse): Employer {
  return {
    employerId: response.id,
    companyId: response.company.id,
    subscriptionPlanId: response.subscription_plan,
    subscriptionTier: response.subscription_tier,
    name: response.company.name,
    addresses: response.company.addresses,
    contacts: response.company.contacts,
    logo: response.company.logo,
    default_benefits: response.company.default_benefits,
    description: response.company.description,
    job_sources: response.company.job_sources,
    competitors: response.company?.competitors?.map((c) => c.name) || [],
    personalizedUrl: response.personalized_url,
    phone: response.company?.contacts[0]?.contact?.phone_number || "",
    website: response.company?.contacts[0]?.contact?.links?.[0] || "",
    linkedinUrl: response.company.linkedin,
    questionnaireStep: response.questionnaire_step,
    isQuestionnaireCompleted: response.is_questionnaire_completed,
    cancelAtPeriodEnd: response.cancel_at_period_end,
    credits: response.credits,
    allowJobadCreation: response.allow_jobad_creation,
    renewalDate: response.renewal_date,
    marketplaceCredits: response.marketplace_credits,
    hasMarketplaceApplicants: response.has_marketplace_applicants,
    hasNoJobs: response.has_no_jobs,
    paymentFailed: response.payment_failed,
    industry: response.industry,
  };
}

export function serializeEmployer(request: Partial<Employer>): EmployerRequest {
  return {
    company: {
      id: request.companyId,
      name: request.name,
      logo: request.logo,
      default_benefits: request.default_benefits,
      description: request.description,
      addresses: request.addresses?.map((companyAddress) => ({
        ...companyAddress,
        address: {
          ...companyAddress.address,
          country: companyAddress.address.country
            ? companyAddress.address.country
            : ("" as CountryCode),
        },
      })),
      contacts: request.website
        ? [
            {
              contact: {
                is_visible: true,
                links: [setHttp(request.website)],
                phone_number: request.phone,
              },
              default: true,
            },
          ]
        : undefined,
      job_sources: request.job_sources,
      competitors: request.competitors?.map((name) => ({ name })),
      linkedin: request.linkedinUrl ? setHttp(request.linkedinUrl) : undefined,
    },
    personalized_url: request.personalizedUrl,
    questionnaire_step: request.questionnaireStep,
    is_questionnaire_completed: request.isQuestionnaireCompleted,
    industry: request.industry,
  };
}
