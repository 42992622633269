import { useEffect, useState } from "react";
import {
  ScreenSizeContext,
  type ScreenSizeAccuracy,
} from "./screen-size-context";
import { getScreenValues } from "./screen-size-retrieval";

interface ScreenSizeProviderProps {
  children: React.ReactNode;
  initialAccuracy?: ScreenSizeAccuracy;
}

export function ScreenSizeProvider(props: ScreenSizeProviderProps) {
  const [state, setState] = useState(getScreenValues());

  useEffect(() => {
    const handleResize = () => {
      setState(getScreenValues());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ScreenSizeContext.Provider value={state}>
      {props.children}
    </ScreenSizeContext.Provider>
  );
}
