import { useCombobox } from "downshift";
import { useCallback, useEffect, useRef, useState } from "react";
import { access } from "utility/accessor";

import { resolveAccessor } from "./accessor";
import { ControlledAutoComplete } from "./controlled-auto-complete";
import { AutoCompleteProps } from "./interfaces";

// Can not easily use enums from downshift. Doing so without modifying tsconfig.json produces a
// runtime error. It might be possible to solve the problem by modifying the tsconfig.json to
// include downshift in preprocessing but this can also increase build times. This dummy enum
// is a quick fix to supresses both the typescript error in the object passed to `options` in
// the useEffect hook and the runtime error received when the enum is imported directly from
// downshift instead.
enum UseComboboxStateChangeTypes {
  InputChange = "__input_change__",
  InputBlur = "__input_blur__",
}

export function AutoComplete<Option extends any>({
  options,
  raiseError,
  onChange,
  onInput,
  value,
  optionId,
  optionText = optionId,
  optionDisplay = optionText,
  resetOnDoubleSelection = true,
  ...props
}: AutoCompleteProps<Option>) {
  const [items, setItems] = useState<Option[]>([]);

  useEffect(
    () =>
      setItems(
        options({
          inputValue: "",
          type: UseComboboxStateChangeTypes.InputChange,
        }),
      ),
    [options],
  );

  const accessId = useCallback(
    (option: Option) =>
      onInput && typeof option == "string"
        ? option
        : resolveAccessor(option, optionId),
    [optionId, onInput],
  );

  // Tracks previous updates to avoid render loops caused by the interaction of
  // selectItem and onChange. There might be a more elegant solution to this
  // problem. But this is the best I could come up with. I also tried using a
  // boolean flag, but this was preventing the internal state from updating when
  // the value prop changes in the time between the useEffect hook at the bottom
  // and the onSelectedItemChange handler. See comments below for more details.
  const updateSetRef = useRef(new Set());

  const {
    getItemProps,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    openMenu,
    closeMenu,
    reset,
    selectItem,
    setInputValue,
  } = useCombobox<Option>({
    items,
    onInputValueChange: (changes) => {
      setItems(options(changes));
    },
    onIsOpenChange: (changes) => {
      if (
        !onInput &&
        changes.type == UseComboboxStateChangeTypes.InputBlur &&
        !changes.isOpen
      ) {
        const items = options({
          inputValue: changes.inputValue,
          type: UseComboboxStateChangeTypes.InputChange,
        });

        if (items.length === 1) {
          if (accessId(items[0]) !== accessId(value)) onChange?.(items[0]);
          setInputValue(access(optionText, items[0]));
        } else {
          reset();
        }
      }

      const selectedText = value && access(optionText, value);
      if (changes.inputValue === selectedText) {
        setItems(
          options({
            inputValue: "",
            type: UseComboboxStateChangeTypes.InputChange,
          }),
        );
      }
    },
    itemToString: (option) =>
      onInput && typeof option === "string"
        ? option
        : resolveAccessor<Option, string>(option, optionText || optionId),
    onSelectedItemChange: ({ selectedItem }) => {
      // This callback can be triggered by the user or by the parent component
      // Either way we will call onChange since the source can't be determined
      // We can however avoid an infinite loop in the use effect hook further
      // down by keeping track of the updates
      updateSetRef.current.add(accessId(selectedItem));
      onChange && onChange(selectedItem);
      onInput && onInput(selectedItem ? access(optionText, selectedItem) : "");
    },
  });

  useEffect(() => {
    if (!value) {
      reset();
    } else {
      // This will only be triggered if the parent component updates the value
      // If we updated the value earlier we will not trigger the callback to
      // modify the internal state (selectItem). We should however remove the
      // value from the update set to allow the user to select the same value
      // again.
      if (updateSetRef.current.has(accessId(value))) {
        updateSetRef.current.delete(accessId(value));
      } else {
        if (value !== "null") selectItem(value);
      }
    }
  }, [selectItem, reset, value, accessId]);

  return (
    <ControlledAutoComplete
      {...{
        ...props,
        options: items,
        onChange,
        onInput,
        value,
        optionId,
        optionText,
        optionDisplay,
        getItemProps,
        getMenuProps,
        getInputProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
        reset,
        openMenu,
        closeMenu,
        resetOnDoubleSelection,
      }}
    />
  );
}
