import { deserializeApplicant } from "store/applicant";
import { deserializeEmployer } from "store/employer/employer-serialization";

import {
  Role,
  RoleResponse,
  UpdateProfileMutation,
  UpdateProfileRequest,
  User,
  UserResponse,
  UserType,
  UserTypeResponse,
} from "./";

export function serializeRole(role: Role) {
  const result = {
    [Role.Admin]: "admin",
    [Role.Owner]: "company-admin",
    [Role.HiringManager]: "company-manager",
    [Role.Colleague]: "company-colleague",
  }[role];

  if (!result) {
    throw new Error(`Unknown role: ${role}`);
  }

  return result as RoleResponse;
}

export function serializeUserProfile(
  settings: UpdateProfileMutation
): UpdateProfileRequest {
  return {
    first_name: settings.firstName,
    last_name: settings.lastName,
    address: settings.address,
    contact: settings.contact,
    avatar: settings.avatar,
    default_applicant_page: settings.defaultApplicantPage,
    last_job: settings.lastJob,
  };
}

export function deserializeRole(role: RoleResponse) {
  return {
    admin: Role.Admin,
    "company-admin": Role.Owner,
    "company-manager": Role.HiringManager,
    "company-colleague": Role.Colleague,
  }[role];
}

export function deserializeUserType(type: UserTypeResponse) {
  return {
    admin: UserType.Admin,
    applicant: UserType.JobSeeker,
    company_member: UserType.Employer,
  }[type];
}

export function deserializeUser(response: UserResponse): User {
  return {
    userId: response.pk,
    email: response.email,
    firstName: response.first_name,
    lastName: response.last_name,
    fullName: `${response.first_name} ${response.last_name}`,
    avatar: response.avatar,
    address: response.address,
    contact: response.contact,
    type: deserializeUserType(response.role),
    applicant: response.applicant
      ? deserializeApplicant(response.applicant)
      : null,
    employer: response?.company ? deserializeEmployer(response.company) : null,
    role: deserializeRole(response.company_role),
    phone: response.contact?.phone_number,
    defaultApplicantPage: response.default_applicant_page,
    lastJob: response.last_job,
  };
}
