import classNames from "classnames";

interface PaperProps extends React.HTMLAttributes<HTMLDivElement> {}

export function Paper(props: PaperProps) {
  const { children, className, ...rest } = props;

  return (
    <div className={classNames("bg-white p-4 shadow-md", className)} {...rest}>
      {children}
    </div>
  );
}
