import { QueryClient, useQueryClient } from "react-query";
import { Assessment } from "store/assessment/interfaces";
import { JobApplicantId, JobApplicantOverview } from "../job-applicant-models";
import { TestStatus } from "store/test-taking/enums";

export function syncJobApplicantAsssessment(
  queryClient: QueryClient,
  jobApplicantId: JobApplicantId,
  assessment: Assessment
) {
  const cachedData = queryClient.getQueryData<JobApplicantOverview>([
    "job-applicant",
    "item",
    jobApplicantId,
    "overview",
  ]);

  if (cachedData) {
    const assessmentScores = cachedData.assessmentScores.slice();
    const assessmentIndex = assessmentScores.findIndex(
      (a) => a.assessmentName === assessment.name
    );

    if (assessmentIndex > -1) {
      assessmentScores[assessmentIndex] = {
        ...assessmentScores[assessmentIndex],
        score: null,
      };
    } else {
      assessmentScores.push({
        assessmentName: assessment.name,
        status: TestStatus.NotStarted,
        score: null,
      });
    }

    queryClient.setQueryData<JobApplicantOverview>(
      ["job-applicant", "item", jobApplicantId, "overview"],
      {
        ...cachedData,
        assessmentScores,
        cognitiveScore: -Infinity,
      }
    );
  }
}
