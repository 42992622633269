export function clickHandledInDescendant(event: React.MouseEvent<Element>) {
  let node = event.target as Element;

  while (
    node.parentNode &&
    node.parentNode !== document.body &&
    node.parentNode !== event.currentTarget
  ) {
    const isClickable =
      node.hasAttribute("href") ||
      node.hasAttribute("onclick") ||
      node.hasAttribute("onmousedown") ||
      node.hasAttribute("onmouseup") ||
      node.hasAttribute("ontouchstart") ||
      node.hasAttribute("ontouchend");
    if (isClickable) return true;

    node = node.parentNode as Element;
  }

  return false;
}

export function withoutPropagation(
  handler: (event: React.MouseEvent<Element>) => any | (() => any)
) {
  return (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    handler(event);
  };
}
