import { CloseIcon } from "assets/icons";
import classNames from "classnames";
import { IconButton, SolidButton } from "components/atomic/buttons";
import { useScreenSize } from "utility/detection";
import { DialogProps, useDialog } from ".";
import { useEffect } from "react";

export function Dialog({
  children,
  className = "bg-white",
  width,
  height,
  maxWidth,
  maxHeight,
  record,
  clearQuery,
  onClose = ({ resolve }) => {
    resolve(false);
  },
  overlay = true,
  canClose = true,
  closeOnOutsideClick = true,
  showUnsavedChanges = false,
  dangerCloseButton = false,
  ...props
}: DialogProps) {
  const dialog = useDialog();
  useEffect(() => {
    if (dialog.all.length === 1) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [dialog]);

  const handleClose = async () => {
    if (canClose) {
      if (showUnsavedChanges) {
        const currentDialog = window["dialog"].all.find(
          (d) => d.props.identifier === props.identifier,
        );

        if (currentDialog.hasUnsavedChanges) {
          if ((await dialog.unsavedChanges()) == "cancel") {
            return;
          }
        }
      }

      !(await onClose(record)) && record.delete();
    }
  };

  const { isMobile, isDesktop } = useScreenSize();

  return (
    <div
      className={classNames(
        "fixed left-0 top-0 h-screen w-screen",
        overlay && "z-20 animate-fade-in bg-overlay",
        "flex items-center justify-center",
      )}
      onClick={closeOnOutsideClick ? handleClose : null}
    >
      <div
        className={classNames(
          "z-10 animate-blow-up rounded",
          "relative flex justify-items-stretch",
          "container lg:mx-auto",
          className,
        )}
        style={{
          minWidth: isMobile ? 250 : 480,
          minHeight: 100,
          width: width || (isDesktop ? "60%" : "100%"),
          height,
          maxHeight: maxHeight || "85vh",
          maxWidth: maxWidth || "95vw",
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {canClose && dangerCloseButton ? (
          <SolidButton
            leftIcon={CloseIcon}
            width="fit-content"
            scale="small"
            className="absolute right-5 top-1    stroke-2"
            data-testid="dialog-close"
            variant="danger"
            onClick={handleClose}
          >
            Close
          </SolidButton>
        ) : (
          <IconButton
            variant="primary"
            icon={CloseIcon}
            className="absolute -right-3 top-1  -translate-x-full  stroke-2"
            onClick={handleClose}
            data-testid="dialog-close"
          />
        )}
        <div
          {...{
            className: "w-full overflow-y-auto p-4 bg-silver rounded scrollbar",
            ...props,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
