import {
  CircledCheck,
  DisabledIcon,
  ErrorIcon,
  PendingIcon,
} from "assets/icons";
import { ProgressIndicator } from "components/atomic/progress-indicator";
import { useTimeout } from "hooks/timer";
import useUniqueId from "hooks/unique-id";
import React, { useCallback, useState } from "react";
import { extractSizeProps } from "utility/component-size";

import { Label } from "../form-field/label";
import { AdornedComponentProps } from "../interfaces";
import { HasMessage } from "./has-message";
import { BaseElementProps, FormFieldProps } from "./interfaces";
import { LoadingIcon } from "../progress-indicator/loading-icon";

function getAdornment(props: FormFieldProps & AdornedComponentProps) {
  if (props.error) return <ErrorIcon height="16px" width="16px" />;
  if (props.disabled)
    return (
      <DisabledIcon height="16px" width="16px" className="fill-charcoal-200" />
    );
  if (props.success) return <CircledCheck height="16px" width="16px" />;
  if (props.loading) return <LoadingIcon height="16px" width="16px" />;
  return props.rightAdornment;
}

/**
 * Generic input group component.
 */
export default function FormField<ElementProps extends BaseElementProps>(
  props: FormFieldProps & Omit<ElementProps, keyof FormFieldProps>,
) {
  const {
    id: providedId,
    element: Element,
    className,
    style,
    label,
    tooltip,
    required = false,
    disabled = false,
    labelClasses,
    labelStyles,
    labelProps,
    onFocus,
    onBlur,
    optional,
    ...otherProps
  } = props;

  const { componentSize } = extractSizeProps(otherProps);
  const rightAdornment = getAdornment(props);

  const uniqueId = useUniqueId("-field-");
  const id = providedId || uniqueId;

  const labelId = `${id}-label`;
  const messageId = `${id}-message`;

  const [focused, setFocused] = useState(false);

  const { clearTimeout, setTimeout } = useTimeout();
  const [raisedError, setRaisedError] = useState<string | null>(null);

  const raiseError = useCallback(
    (message: string, timeout: false | number = 2000) => {
      clearTimeout();
      setRaisedError(message);
      if (timeout) setTimeout(() => setRaisedError(null), timeout);
    },
    [clearTimeout, setTimeout],
  );

  const variant = raisedError ? "error" : props.variant;
  const error = raisedError || props.error;

  return (
    <div className={className} style={style}>
      {label && (
        <Label
          {...{
            ...labelProps,
            required,
            optional,
            disabled,
            tooltip,
            id: labelId,
            htmlFor: id,
            style: labelStyles,
            className: labelClasses,
          }}
        >
          {label}
        </Label>
      )}
      <div style={componentSize.apply({})}>
        <HasMessage
          {...{
            ...props,
            variant,
            error,
            focused,
            messageId,
            render: ({ variant }) => (
              <Element
                {...{
                  id: id,
                  variant,
                  required,
                  className: "w-full h-full",
                  onBlur: (event) => (
                    setFocused(false), onBlur && onBlur(event)
                  ),
                  onFocus: (event) => (
                    setFocused(true), onFocus && onFocus(event)
                  ),
                  ...otherProps,
                  rightAdornment,
                  raiseError,
                  "aria-errormessage": messageId,
                  disabled,
                }}
              />
            ),
          }}
        />
      </div>
    </div>
  );
}
