import { Translator } from "components/atomic/injection";
import { defaultTranslator } from "./default-date-translator";

export function dateInterval(
  start: Date,
  end: Date = undefined,
  $t: Translator = defaultTranslator,
  lang = "en"
) {
  return `${start.toLocaleString(lang, {
    month: "long",
    year: "numeric",
  })} - ${
    end && end.toString() !== "Invalid Date"
      ? end.toLocaleString("eng", {
          month: "long",
          year: "numeric",
        })
      : $t("present")
  }`;
}
