import classNames from "classnames";
import { Tooltip } from "components/popup";
import { ComponentSize } from "utility/component-size";
import { variantClasses } from "utility/variant-classes";

import {
  BasicButtonProps,
  ButtonScaleStyles,
  IconButtonProps,
} from "./interfaces";
import { canHover } from "utility/detection";

export default function IconButton({
  className = "flex items-center",
  variant,
  compact,
  scale,
  style,
  stroke,
  onClick,
  disabled,
  icon: Icon,
  tooltip = false,
  inverted = false,
  actualButton = true,
  showTooltipArrow = true,
  alwaysShowToolTip,
  tooltipPlacement = "bottom-end",
  iconHeight,
  iconWidth,
  ...props
}: IconButtonProps) {
  const clickable = onClick || props.href;
  tooltip = (!clickable || canHover()) && tooltip;

  const componentSize = new ComponentSize(props);
  props = componentSize.otherProps;

  const scaleStyles: ButtonScaleStyles = {
    huge: compact
      ? { height: "28px", width: "28px" }
      : { height: "32px", width: "32px" },
    large: compact
      ? { height: "20px", width: "20px" }
      : { height: "24px", width: "24px" },
    medium: compact
      ? { height: "18px", width: "18px" }
      : { height: "22px", width: "22px" },
    small: compact
      ? { height: "14px", width: "14px" }
      : { height: "20px", width: "20px" },
    tiny: compact
      ? { height: "12px", width: "12px" }
      : { height: "16px", width: "16px" },
  };

  const scaleStyle = scaleStyles[scale || "medium"];
  style = { ...scaleStyle, ...style };

  const Component = actualButton
    ? (props: BasicButtonProps) => <button {...props} />
    : (props: BasicButtonProps) => (
        <div>
          <a {...{ ...props, href: props.href || "#" }} />
        </div>
      );

  return (
    <Tooltip
      tip={tooltip}
      deemphasize={disabled}
      timeout={0}
      options={{ placement: tooltipPlacement, offset: [10, 10] }}
      alwaysShow={alwaysShowToolTip}
      showArrow={showTooltipArrow}
    >
      <Component
        {...props}
        disabled={disabled}
        onClick={
          disabled
            ? undefined
            : onClick &&
              ((event) => {
                event.preventDefault();
                onClick(event);
              })
        }
        className={classNames(
          "block cursor-pointer",
          stroke ? `stroke-${Number(stroke)}` : "stroke-0",
          inverted
            ? variantClasses(
                {
                  primary: classNames(
                    "bg-primary hover:bg-primary-accent active:bg-primary-dark",
                    "fill-white stroke-white",
                  ),
                  secondary: classNames(
                    "bg-secondary hover:bg-secondary-accent active:bg-secondary-dark",
                    "fill-white stroke-white",
                  ),
                  white: classNames(
                    "bg-white hover:bg-silver active:bg-white",
                    "fill-white stroke-white",
                  ),
                  blue: classNames(
                    "bg-blue hover:bg-blue-accent active:bg-blue-dark",
                    "fill-white stroke-white",
                  ),
                  success: classNames(
                    "bg-success hover:bg-success-accent active:bg-success-dark",
                    "fill-white stroke-white",
                  ),
                  danger: classNames(
                    "bg-danger hover:bg-danger-accent active:bg-danger-dark",
                    "fill-white stroke-white",
                  ),
                  warning: classNames(
                    "bg-warning hover:bg-warning-accent active:bg-warning-dark",
                    "fill-white stroke-white",
                  ),
                  disabled: "fill-charcoal-200 stroke-charcoal-200",
                },
                { variant, disabled },
              )
            : variantClasses(
                {
                  primary: classNames(
                    "stroke-primary hover:stroke-primary-accent active:stroke-primary-dark",
                    "fill-primary hover:fill-primary-accent active:fill-primary-dark",
                  ),
                  secondary: classNames(
                    "stroke-secondary hover:stroke-secondary-accent active:stroke-secondary-dark",
                    "fill-secondary hover:fill-secondary-accent active:fill-secondary-dark",
                  ),
                  white: classNames(
                    "stroke-white hover:stroke-silver active:stroke-white",
                    "fill-white hover:fill-silver active:fill-white",
                  ),
                  blue: classNames(
                    "stroke-blue hover:stroke-blue-accent active:stroke-blue-dark",
                    "fill-blue hover:fill-blue-accent active:fill-blue-dark",
                  ),
                  success: classNames(
                    "stroke-success hover:stroke-success-accent active:stroke-success-dark",
                    "fill-success hover:fill-success-accent active:fill-success-dark",
                  ),
                  danger: classNames(
                    "stroke-danger hover:stroke-danger-accent active:stroke-danger-dark",
                    "fill-danger hover:fill-danger-accent active:fill-danger-dark",
                  ),
                  warning: classNames(
                    "stroke-warning hover:stroke-warning-accent active:stroke-warning-dark",
                    "fill-warning hover:fill-warning-accent active:fill-warning-dark",
                  ),
                  disabled: "fill-charcoal-200 stroke-charcoal-200",
                },
                { variant, disabled },
              ),
          className,
        )}
      >
        <div style={componentSize.apply(style)}>
          <Icon style={{ width: "100%", height: "100%" }} />
        </div>
      </Component>
    </Tooltip>
  );
}
