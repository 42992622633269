import { useState, useEffect } from "react";

export function useMediaPermission(
  requestedMedia: MediaStreamConstraints = { audio: true, video: true }
) {
  const [isCameraPermissionGranted, setIsCameraPermissionGranted] =
    useState<boolean>(undefined);

  const [isMicPermissionGranted, setIsMicPermissionGranted] =
    useState<boolean>(undefined);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia(requestedMedia)
      .then((mediaStream) => {
        setIsCameraPermissionGranted(true);
        setIsMicPermissionGranted(true);
        setPermissions(mediaStream);
      })
      .catch((e) => {
        setIsCameraPermissionGranted(false);
        setIsMicPermissionGranted(false);
        setPermissions();
      });
  }, []);

  const setPermissions = (mediaStream = null) => {
    const tracks = mediaStream?.getTracks();
    if (tracks) {
      tracks.forEach((track) => {
        track.stop();
      });
    }
    if (navigator.permissions && detectBrowser() == "Google Chrome") {
      (navigator.permissions as any)
        .query({ name: "camera" })
        .then(function (permissionStatus) {
          if (permissionStatus.state === "denied") {
            setIsCameraPermissionGranted(false);
          } else {
            setIsCameraPermissionGranted(true);
          }
        })
        .catch((error) => {
          console.error("Got error :", error);
        });

      (navigator.permissions as any)
        .query({ name: "microphone" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            setIsMicPermissionGranted(false);
          } else {
            setIsMicPermissionGranted(true);
          }
        })
        .catch((error) => {
          console.error("Got error :", error);
        });
    }
  };

  return { isMicPermissionGranted, isCameraPermissionGranted };
}

export function detectBrowser() {
  const userAgent = navigator.userAgent;
  let browser = "";

  if (/edge\//i.test(userAgent)) {
    browser = "Microsoft Edge";
  } else if (/chrome\//i.test(userAgent)) {
    browser = "Google Chrome";
  } else if (/firefox\//i.test(userAgent)) {
    browser = "Mozilla Firefox";
  } else if (/safari\//i.test(userAgent)) {
    browser = "Apple Safari";
  } else if (/opera\//i.test(userAgent)) {
    browser = "Opera";
  } else if (/trident\//i.test(userAgent)) {
    browser = "Internet Explorer";
  }

  return browser;
}
