import { useEffect, useState } from "react";

export function useRequired(
  required: boolean,
  value: string | number | undefined,
  raiseError: (error: string, timeout: false | number) => any,
) {
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (!required) return;

    setValidated((validated) => {
      if (!validated) {
        return value !== undefined && value !== "";
      }

      if (!value) {
        raiseError("This field is required", false);
      }

      if (value && validated) {
        raiseError("", false);
      }

      return true;
    });
  }, [raiseError, required, value]);
}
