import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { FetchQueryOptions } from "react-query";
import { JobApplicantTestStats } from "store/applicant/applicant-models";
import { accessToken } from "store/auth";
import { JobApplicantId } from "store/job-applicant";
import { JobId } from "store/job/interfaces";
import { deserializeQuestionType } from "store/question";
import { TestId } from "store/test/interfaces";
import { authAxios } from "utility/axios";
import { renameError, sendErrorToSentry } from "utility/sentry";

import { TestStatus } from "./enums";
import {
  ApplicantTest,
  CurrentQuestion,
  CurrentQuestionOption,
  GameToken,
  TestEnding,
} from "./interfaces";
import { AnswerRequest } from "./requests";
import {
  ApplicantTestResponse,
  CurrentQuestionResponse,
  CurrentQuestionResponseOption,
  StartGameResponse,
} from "./responses";
import { serializeJobApplicantTestStats } from "./test-taking-serialization";

export function deserializeApplicantTest({
  id: testId,
  created_at,
  updated_at,
  progress: { progress: { status, step, total } = {} } = {},
  ...test
}: ApplicantTestResponse): ApplicantTest {
  return {
    testId,
    status,
    ...test,
    progress: {
      step,
      total,
    },
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    gameUrl: test?.game_url, // use optional chaining here
  };
}

export function deserializeCurrentQuestionOption({
  id: questionOptionId,
  option: { id: optionId, text },
}: CurrentQuestionResponseOption): CurrentQuestionOption {
  return {
    questionOptionId,
    optionId,
    text,
  };
}

export async function startGame(
  testId: TestId,
  jobId?: JobId,
): Promise<GameToken> {
  const { data } = await authAxios.post<StartGameResponse>(
    `/jobtests/game/start_game/`,
    {
      job_id: jobId ?? null,
      test_id: testId,
    },
    {
      waitMessage: "Starting...",
      hideSuccessDialog: true,
      isBlockingAction: true,
    },
  );

  return {
    testId,
    tokenReference: data.applicant_id,
  };
}

export function deserializeCurrentQuestion({
  answer: { id: answerId, time_left: timeRemaining } = {
    id: undefined,
    time_left: undefined,
  },
  id: questionId,
  company: employerId,
  options,
  title,
  text,
  file,
  type,
  answer_type,
  weighted,
  progress: { status, step, total },
  time_limit: timeLimit,
}: CurrentQuestionResponse): CurrentQuestion | TestEnding | undefined {
  if (status === TestStatus.Unknown) return undefined;
  if (status === TestStatus.NotStarted) return undefined;
  return status === TestStatus.Running
    ? {
        status,
        answerId,
        questionId,
        employerId,
        options: options?.map(deserializeCurrentQuestionOption),
        title,
        type: deserializeQuestionType({ type, weighted, answer_type }),
        text,
        file,
        progress: {
          step,
          total,
        },
        timeLimit,
        timeRemaining,
      }
    : {
        status,
        progress: { step, total },
        questionId: undefined,
        answerId: undefined,
        timeLimit: undefined,
        timeRemaining: undefined,
      };
}

export async function getCurrentQuestion(
  testId: TestId,
  jobApplicantId?: JobApplicantId,
) {
  const { data } = await authAxios.get(`jobtests/test/${testId}/take/`, {
    params: jobApplicantId && {
      "job-applicant": jobApplicantId,
    },
  });
  return deserializeCurrentQuestion(data);
}

export function useTestingManager(
  test: ApplicantTest,
  jobApplicantId?: JobApplicantId,
) {
  const [question, setQuestion] = useState<CurrentQuestion | TestEnding>();

  const loadQuestion = useCallback(
    () => getCurrentQuestion(test.testId, jobApplicantId).then(setQuestion),
    [test.testId, jobApplicantId],
  );

  const saveAnswer = useCallback(
    (answer: AnswerRequest) =>
      "answerId" in question &&
      authAxios
        .patch(`/jobtests/answer/${question.answerId}/`, answer, {
          hideWaitDialog: true,
          hideSuccessDialog: true,
          hideErrorDialog: true,
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            sendErrorToSentry(
              renameError(
                new Error("Failed to patch answer"),
                `Test Taker 401`,
              ),
              {
                extra: {
                  data: { at: accessToken },
                },
              },
            );
          }

          throw error;
        }),
    [question],
  );

  return useMemo(
    () => ({
      question,
      loadQuestion,
      saveAnswer,
    }),
    [question, loadQuestion, saveAnswer],
  );
}

export function useApplicantTestQuery(
  testId: TestId,
  jobapplicant_id?: JobApplicantId,
): FetchQueryOptions<ApplicantTest> {
  return {
    queryKey: ["applicant-test", "item", testId],
    queryFn: async () => {
      const { data } = await authAxios.get(`/jobtests/test/${testId}/`, {
        params: { jobapplicant_id },
      });
      return deserializeApplicantTest(data);
    },
  };
}

export const saveTestStats = (testStats: JobApplicantTestStats) => {
  if (!testStats.jobApplicantId) return;
  authAxios.post(
    `/jobs/job-applicant/${testStats.jobApplicantId}/stats/`,
    serializeJobApplicantTestStats(testStats),
    {
      hideErrorDialog: true,
      hideSuccessDialog: true,
      hideWaitDialog: true,
    },
  );
};
