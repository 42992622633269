import { ScreenSizeProvider } from "./screen-size-provider";
import { WindowScrollProvider } from "./window-scroll-provider";

interface DetectionProviderProps {
  children: React.ReactNode;
}

export function DetectionProvider({ children }: DetectionProviderProps) {
  return (
    <ScreenSizeProvider>
      <WindowScrollProvider>{children}</WindowScrollProvider>
    </ScreenSizeProvider>
  );
}
