import { searchify } from "utility/search";

import { DegreeType } from "./education-enums";
import { Subject, University } from "./education-models";
import { SubjectResponseItem } from "./education-responses";

export function deserializeDegreeType(
  input: SubjectResponseItem["type"]
): DegreeType {
  return {
    A: DegreeType.Associate,
    B: DegreeType.Bachelor,
    M: DegreeType.Master,
    D: DegreeType.Doctorate,
  }[input];
}

export function deserializeSubject(input: SubjectResponseItem): Subject {
  return {
    name: input.name,
    type: deserializeDegreeType(input.type),
    search: searchify(input.name),
  };
}

export function deserializeUniversity(input: string): University {
  return {
    name: input,
    search: searchify(input),
  };
}
