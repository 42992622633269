import { ChangeEvent } from "react";

interface UseOverflowOptions {
  maxLength?: number;
  raiseError?: (message: string, timeout?: false | number) => void;
  translate: (key: string, data?: any) => string;
  onChange?: (value: string, event: React.ChangeEvent) => void;
  onPaste?: (event: React.ClipboardEvent) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
}

function insertWithUndoSupport({
  text,
  fallback,
}: {
  text: string;
  fallback?: () => any;
}) {
  const supported = document.execCommand?.("insertText", false, text);
  if (!supported) {
    fallback?.();
  }
}

export function useOverflow({
  maxLength,
  raiseError,
  translate,
  onChange,
  onPaste,
  onKeyPress,
}: UseOverflowOptions) {
  const hasMaxLength = maxLength !== undefined && !isNaN(maxLength);

  function handleOverflow(event: React.SyntheticEvent) {
    event.stopPropagation();
    event.preventDefault();
    raiseError?.(
      translate("errors.string.max-length", {
        count: maxLength,
      })
    );
  }

  function handleKeyPress(
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) {
    const { selectionStart, selectionEnd, value } = event.currentTarget;

    const hasSelection = selectionStart !== selectionEnd;
    const isAtMaxLength = value.length >= maxLength;

    if (!hasSelection && isAtMaxLength) {
      handleOverflow(event);
      return;
    }

    onKeyPress?.(event);
  }

  function handlePaste(
    event:
      | React.ClipboardEvent<HTMLInputElement>
      | React.ClipboardEvent<HTMLTextAreaElement>
  ) {
    const { selectionStart, selectionEnd, value } = event.currentTarget;
    const pastedText = event.clipboardData.getData("text/plain");

    const selectionLength = selectionEnd - selectionStart;
    const replacementLength = pastedText.length - selectionLength;
    const totalLength = value.length + replacementLength;

    if (totalLength <= maxLength) {
      onPaste?.(event);
      return;
    }

    handleOverflow(event);
    const trimLength = pastedText.length - (totalLength - maxLength);
    const trimmedText = pastedText.slice(0, trimLength);
    insertWithUndoSupport({
      text: trimmedText,
      fallback: () =>
        onChange?.(trimmedText, event as any as ChangeEvent<Element>),
    });
  }

  return {
    handlePaste: hasMaxLength ? handlePaste : onPaste,
    handleKeyPress: hasMaxLength ? handleKeyPress : onKeyPress,
  };
}
