import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

/**
 * Returns a createPortal function that is safe to use with
 * server-side rendering. The portal target is determined by
 * the getTarget function, which is called on the client only.
 */
export function useClientPortal(getTarget = () => document.body) {
  const [portalTarget, setPortalTarget] = useState(null);
  useEffect(() => {
    setPortalTarget(getTarget());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    createPortal: (children: ReactNode) => {
      return portalTarget
        ? (createPortal(children, portalTarget) as ReactNode)
        : null;
    },
  };
}
