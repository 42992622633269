import { QueryClient } from "react-query";
import { FolderId } from "store/folder";
import {
  JobApplicant,
  JobApplicantId,
  JobApplicantListItem,
} from "store/job-applicant";

type Props = {
  queryClient: QueryClient;
  jobApplicantIds: JobApplicantId[];
  status: boolean;
};

export function syncJobApplicantStatusCache({
  queryClient,
  jobApplicantIds,
  status,
}: Props) {
  const listQueries = queryClient.getQueriesData<JobApplicantListItem[]>([
    "job-applicant",
    "list",
  ]);

  listQueries.forEach(([key, data]) => {
    data?.forEach((jobApplicant) => {
      if (jobApplicantIds.includes(jobApplicant.id)) {
        jobApplicant.inWorkflow = status;
      }
    });
    queryClient.setQueryData(key, data);
  });

  jobApplicantIds.forEach((jobApplicantId) => {
    const detailQueries = queryClient.getQueriesData<JobApplicant>([
      "job-applicant",
      "item",
      jobApplicantId,
    ]);
    detailQueries.forEach(([key, data]) => {
      data.inWorkflow = status;
      queryClient.setQueryData(key, data);
    });
  });
}
