import { SolidButton } from "components/atomic";
import React, { cloneElement } from "react";
import { UnsavedChangesDialogProps } from "./interfaces";
import { Dialog } from "./dialog";

export function UnsavedChangesDialog({
  record,
  message = "How would you like to proceed?",
  withDraft,
  onSaveDraft,
  onDiscard,
  onCancel,
  saveDraftLabel = "Save as Draft",
  discardLabel = "Discard and Exit",
  cancelLabel = "Continue Editing",
  saveDraftVariant = "success",
  discardVariant = "danger",
  cancelVariant = onSaveDraft ? "disabled" : "success",
  CancelButton = SolidButton,
  SaveDraftButton = SolidButton,
  DiscardButton = SolidButton,
  saveDraftElement = (
    <SaveDraftButton
      className="min-w-max max-w-[300px] flex-1"
      variant={saveDraftVariant}
    >
      {saveDraftLabel}
    </SaveDraftButton>
  ),
  discardElement = (
    <DiscardButton
      className="min-w-max max-w-[300px] flex-1"
      variant={discardVariant}
    >
      {discardLabel}
    </DiscardButton>
  ),
  cancelElement = (
    <CancelButton
      className="min-w-max max-w-[300px] flex-1"
      variant={cancelVariant}
    >
      <div className={onSaveDraft && "text-secondary-600"}>{cancelLabel}</div>
    </CancelButton>
  ),
  ...props
}: UnsavedChangesDialogProps) {
  const handleSaveDraft = async () => {
    if (await onSaveDraft?.(record)) return "abort";
    record.resolve("save");
    record.delete();
  };

  const handleDiscard = async () => {
    if (await onDiscard?.(record)) return "abort";
    record.resolve("discard");
    record.delete();
  };

  const handleCancel = async () => {
    if (await onCancel?.(record)) return "abort";
    record.resolve("cancel");
    record.delete();
  };

  return (
    <Dialog {...{ onClose: onCancel, record, ...props }}>
      <div className="poppins-semibold-secondary mt-3 text-center text-3xl">
        You have unsaved changes
      </div>
      <div className="poppins-regular-secondary mt-5 text-center text-lg">
        {message}
      </div>
      <div className="mt-10 flex flex-wrap items-center justify-center gap-3 text-secondary-dark">
        {onSaveDraft &&
          cloneElement(saveDraftElement, { onClick: handleSaveDraft })}
        {cloneElement(discardElement, { onClick: handleDiscard })}
        {cloneElement(cancelElement, { onClick: handleCancel })}
      </div>
    </Dialog>
  );
}
