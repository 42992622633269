import { WindowScrollState } from "./window-scroll-context";

export function getWindowScrollValues(): WindowScrollState {
  if (typeof window === "undefined") {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }

  return {
    x: window.scrollX,
    y: window.scrollY,
    width: document.body.scrollWidth,
    height: document.body.scrollHeight,
  };
}
