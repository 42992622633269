import { useCallback, useEffect, useRef, useState } from "react";
import { classnames, ClassNames } from "utility/classnames";
import { ComponentSize } from "utility/component-size";
import { linkWrap } from "utility/links";
import { variantClasses, VariantClasses } from "utility/variant-classes";

import { radiusTranslation } from "../css-props";
import { ButtonProps, ButtonScaleStyles } from "./interfaces";
import { useTriggeredEffect } from "utility/triggered-effect";

export default function buttonFactory(
  defaultClassNames: ClassNames | string,
  buttonVariantClasses: VariantClasses
) {
  // eslint-disable-next-line react/display-name
  return ({
    className,
    variant,
    disabled,
    preventDisable,
    scale,
    radius,
    compact,
    style,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    stroke,
    children,
    href,
    target,
    onClick,
    align,
    ...props
  }: ButtonProps) => {
    const componentSize = new ComponentSize(props);
    props = componentSize.otherProps;
    const [isDisabled, setIsDisabled] = useState(disabled);

    // The point of this effect is to set the value of
    // isDisabled to the value of disabled after the click
    // has occurred. We can't just set isDisabled because
    // disabled will contain the value from the render in
    // which the button was clicked, but the value might
    // change by the time onClick is finished. So we
    // delay until the next render.
    const trigger = useTriggeredEffect(() => {
      setIsDisabled(disabled);
    }, [disabled]);

    let timeout = useRef<NodeJS.Timeout>();
    const handleClick = async (e) => {
      if (preventDisable) {
        onClick && (await onClick(e));
      } else {
        // disable button until onClick is resolved + 300ms
        if (isDisabled) return;
        setIsDisabled(true);
        onClick && (await onClick(e));
        timeout.current = setTimeout(trigger, 300);
      }
    };

    useEffect(() => () => timeout.current && clearTimeout(timeout.current), []);

    const scaleStyles: ButtonScaleStyles = {
      large: { height: compact ? "70px" : "62px" },
      medium: { height: compact ? "58px" : "50px" },
      small: { height: compact ? "40px" : "32px" },
      tiny: { height: compact ? "26px" : "18px" },
    };

    const scaleStyle = scaleStyles[scale || "medium"];
    style = { ...scaleStyle, ...style };

    const iconStyles: ButtonScaleStyles = {
      large: { height: "24px", width: "24px" },
      medium: { height: "22px", width: "22px" },
      small: { height: "20px", width: "20px" },
      tiny: { height: "12px", width: "12px" },
    };
    const iconStyle = iconStyles[scale || "medium"];

    return linkWrap(
      <div
        data-testid="factory-button"
        className={classnames(className, "group").toString()}
        onClick={disabled ? () => {} : handleClick}
        style={componentSize.apply(style)}
      >
        <button
          {...props}
          disabled={Boolean(isDisabled)}
          className={classnames(
            "flex h-full w-full items-center",
            LeftIcon || RightIcon ? "justify-between" : "justify-center",
            "font-poppins font-medium",
            "py-auto inline-block",
            compact ? (scale == "tiny" ? "text-xs" : "text-sm") : "text-md",
            stroke ? `stroke-${stroke}` : "stroke-0",
            defaultClassNames,
            variantClasses(buttonVariantClasses, {
              variant,
              disabled: isDisabled,
              compact,
            })
          ).toString()}
          style={{ borderRadius: radiusTranslation[radius] ?? radius }}
        >
          <div className={LeftIcon ? "mr-2" : ""}>
            {LeftIcon ? (
              <LeftIcon style={{ ...iconStyle }} />
            ) : (
              RightIcon && <div style={{ ...iconStyle }}></div>
            )}
          </div>
          {children && (
            <div className="flex-1" style={{ textAlign: align }}>
              {children}
            </div>
          )}
          <div className={RightIcon ? "ml-2" : ""}>
            {RightIcon ? (
              <RightIcon style={{ ...iconStyle }} />
            ) : (
              LeftIcon && <div style={{ ...iconStyle }}></div>
            )}
          </div>
        </button>
      </div>,
      href,
      target
    );
  };
}
