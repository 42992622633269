import { classnames } from "utility/classnames";
import buttonFactory from "../factory";

const GhostButton = buttonFactory("border-2 rounded", {
  primary: classnames(
    "border-primary group-hover:border-primary-accent active:border-primary-dark",
    "text-primary group-hover:text-primary-accent active:text-primary-dark",
    "fill-primary group-hover:fill-primary-accent active:fill-primary-dark",
    "stroke-primary group-hover:stroke-primary-accent active:fill-primary-dark"
  ),
  secondary: classnames(
    "border-secondary group-hover:border-secondary-accent active:border-secondary-dark",
    "text-secondary group-hover:text-secondary-accent active:text-secondary-dark",
    "fill-secondary group-hover:fill-secondary-accent active:fill-secondary-dark",
    "stroke-secondary group-hover:stroke-secondary-accent active:fill-secondary-dark"
  ),
  white: classnames(
    "border-white group-hover:border-silver active:border-white",
    "text-white group-hover:text-silver active:text-white",
    "fill-white group-hover:fill-silver active:fill-white",
    "stroke-white group-hover:stroke-silver active:fill-white"
  ),
  blue: classnames(
    "border-blue group-hover:border-blue-accent active:border-blue-dark",
    "text-blue group-hover:text-blue-accent active:text-blue-dark",
    "fill-blue group-hover:fill-blue-accent active:fill-blue-dark",
    "stroke-blue group-hover:stroke-blue-accent active:fill-blue-dark"
  ),
  success: classnames(
    "border-success group-hover:border-success-accent active:border-success-dark",
    "text-success group-hover:text-success-accent active:text-success-dark",
    "fill-success group-hover:fill-success-accent active:fill-success-dark",
    "stroke-success group-hover:stroke-success-accent active:fill-success-dark"
  ),
  danger: classnames(
    "border-danger group-hover:border-danger-accent active:border-danger-dark",
    "text-danger group-hover:text-danger-accent active:text-danger-dark",
    "fill-danger group-hover:fill-danger-accent active:fill-danger-dark",
    "stroke-danger group-hover:stroke-danger-accent active:fill-danger-dark"
  ),
  warning: classnames(
    "border-warning group-hover:border-warning-accent active:border-warning-dark",
    "text-warning group-hover:text-warning-accent active:text-warning-dar",
    "fill-warning group-hover:fill-warning-accent active:fill-warning-dark",
    "stroke-warning group-hover:stroke-warning-accent active:fill-warning-dark"
  ),
  disabled: classnames(
    "border-secondary-100 text-secondary-light",
    "fill-charcoal-200 stroke-charcoal-200"
  ),
  green: classnames(
    "border-green  text-green",
    "fill-white-200 stroke-white-200"
  ),
  compact: "px-3",
  default: "px-7",
});

export default GhostButton;
