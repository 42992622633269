import { useCallback, useEffect, useMemo, useRef } from "react";

const context = typeof window !== "undefined" ? window : global;

export function useTimeout() {
  const timeoutRef = useRef<any>();

  const clearTimeout = useCallback(
    () => timeoutRef.current && context.clearTimeout(timeoutRef.current),
    []
  );

  const setTimeout = useCallback(
    (callback: () => void, timeout: number) => {
      if (timeoutRef.current) {
        clearTimeout();
      }

      timeoutRef.current = context.setTimeout(() => {
        callback();
        clearTimeout();
      }, timeout);

      return timeoutRef.current;
    },
    [clearTimeout]
  );

  useEffect(() => clearTimeout, [clearTimeout]);

  return useMemo(
    () => ({
      setTimeout,
      clearTimeout,
      set: setTimeout,
      clear: clearTimeout,
    }),
    [setTimeout, clearTimeout]
  );
}

export function useInterval() {
  const intervalRef = useRef<number>();

  const clearInterval = useCallback(
    () => intervalRef.current && window.clearInterval(intervalRef.current),
    []
  );

  const setInterval = useCallback(
    (callback: () => void, interval: number) => {
      if (intervalRef.current) {
        clearInterval();
      }

      intervalRef.current = window.setInterval(() => {
        callback();
      }, interval);

      return intervalRef.current;
    },
    [clearInterval]
  );

  useEffect(() => clearInterval, [clearInterval]);

  return useMemo(
    () => ({
      setInterval,
      clearInterval,
      set: setInterval,
      clear: clearInterval,
    }),
    [setInterval, clearInterval]
  );
}
