import classNames from "classnames";
import { ReactNode } from "react";

import { Radio, RadioProps } from "../radio/radio";
import { clickHandledInDescendant } from "utility/events";

export interface RadioWithLabelProps extends RadioProps {
  label: ReactNode;
  labelClassName?: string;
  className?: string;
  floating?: boolean;
}

export const RadioWithLabel = ({
  className,
  labelClassName,
  floating,
  label,
  onClick,
  ...props
}: RadioWithLabelProps) => {
  return (
    <div className={className}>
      <div
        onClick={(event) => {
          if (clickHandledInDescendant(event)) return;

          onClick(!props.value, event);
        }}
        className={classNames(
          floating || "flex",
          "w-full cursor-pointer items-center",
          "font-poppins text-base font-semibold"
        )}
      >
        <Radio
          className={classNames(floating && "float-left", "mr-2")}
          {...props}
        ></Radio>
        <label className={labelClassName}>{label}</label>
      </div>
    </div>
  );
};
