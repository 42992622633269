import { useEffect } from "react";
import { JobApplicantId } from "store/job-applicant";
import { saveTestStats } from "store/test-taking";
import { TestId } from "store/test/interfaces";
type Props = {
  jobApplicantId: JobApplicantId;
  testId: TestId;
};
export function useMouseLeave({ jobApplicantId, testId }: Props) {
  useEffect(() => {
    function handleMouseLeave(event: MouseEvent) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        saveTestStats({
          jobApplicantId,
          testId,
          mouseOffWindowCount: 1,
        });
      }
    }

    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [jobApplicantId, testId]);
}
