import {
  autoUpdate,
  flip,
  offset,
  size,
  useFloating,
} from "@floating-ui/react-dom";
import { MenuDownIcon, SmallXIcon } from "assets/icons";
import classNames from "classnames";
import AdornedNode from "components/atomic/form-field/adorned-node";
import BorderBox from "components/atomic/form-field/border-box";
import { useClientPortal } from "components/atomic/hooks/client-portal";
import { useTranslation } from "hooks/translation";
import { MouseEvent, useCallback, useState } from "react";
import { extractSizeProps } from "utility/component-size";
import { hoverAvailable } from "utility/hover";
import { isNotEmpty } from "utility/poke";
import { ControlledAutoCompleteProps } from "./interfaces";
import OptionList from "./option-list";

export function ControlledAutoComplete<Option extends any>({
  value,
  options,
  optionId,
  optionText = optionId,
  optionDisplay = optionText,

  disabled,

  getItemProps,
  getMenuProps,
  getInputProps,
  getToggleButtonProps,
  isOpen,
  openMenu,
  closeMenu,
  highlightedIndex,

  onClick,
  onChange,
  onInput,
  reset,
  style,
  compact,
  className,
  leftAdornment,
  rightAdornment,
  listToggle = <MenuDownIcon width="14px" />,
  resetButton = <SmallXIcon width="30px" />,
  unresettable = false,
  placeholder = null,
  resetOnDoubleSelection,
  ...props
}: ControlledAutoCompleteProps<Option>) {
  const { $t } = useTranslation();
  const { createPortal } = useClientPortal();

  placeholder = placeholder ?? $t("common:option-placeholder-text");
  const { componentSize, otherProps } = extractSizeProps(props);

  const [inputEl, setInputEl] = useState(null);

  const { refs, floatingStyles, update } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "absolute",
    placement: "bottom",
    middleware: [
      offset(10),
      flip(),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${elements.reference.offsetWidth}px`,
            maxHeight: `${Math.min(Math.max(availableHeight, 300), 400)}px`,
          });
        },
      }),
    ],
  });

  const inputProps = getInputProps();

  const handleResetButtonClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      reset();
    },
    [reset],
  );

  return (
    <div className={className} style={componentSize.apply(style)}>
      <div ref={refs.setReference} className="group">
        <BorderBox
          {...{
            ...otherProps,
            className: disabled ? undefined : "cursor-pointer",
            compact,
            disabled,
            onClick: (event) => {
              if (disabled) {
                event.preventDefault();
                return;
              }

              if (isOpen) {
                closeMenu();
              } else {
                update();
                openMenu();
                if (inputEl) {
                  inputEl.focus();
                  inputEl.select();
                }
              }
              onClick && onClick(event);
            },
          }}
        >
          <AdornedNode
            leftAdornment={leftAdornment}
            rightAdornment={
              unresettable ? (
                rightAdornment || (
                  <span className="flex items-center gap-x-1">
                    <div {...getToggleButtonProps()}>{listToggle}</div>
                  </span>
                )
              ) : (
                <span className="flex items-center gap-x-1">
                  <div
                    onClick={handleResetButtonClick}
                    className={classNames(
                      hoverAvailable && [
                        value && !disabled && "group-hover:block",
                        "hidden",
                      ],
                    )}
                  >
                    {resetButton}
                  </div>
                  <div {...getToggleButtonProps()}>{listToggle}</div>
                </span>
              )
            }
          >
            <input
              {...{
                ...inputProps,
                ref: (el) => el && (setInputEl(el), inputProps.ref(el)),
                className: classNames(
                  disabled ? "bg-secondary-100" : "bg-white",
                  "flex items-center",
                  "font-poppins",
                  compact ? "text-sm" : "text-base",
                  "focus:shadow-outline",
                  "focus:outline-none",
                  "w-full",
                  "appearance-none",
                  value && !compact && !disabled && "font-semibold",
                  disabled
                    ? "placeholder:italic placeholder:text-charcoal-400"
                    : "placeholder:text-charcoal-600",
                  (isOpen && !onInput) || disabled
                    ? "text-charcoal-600"
                    : "text-secondary",
                ),
                style: {
                  height: isNotEmpty(componentSize.height) ? "100%" : 28,
                },
                placeholder,
                onKeyDown: (event) => {
                  inputProps.onKeyDown && inputProps.onKeyDown(event);
                  if (onInput && event.key == "Enter") {
                    closeMenu();
                  }
                },
                onChange: (event) => {
                  inputProps.onChange && inputProps.onChange(event);
                  onInput && onInput(event.target.value);
                },
              }}
            />
          </AdornedNode>
        </BorderBox>
      </div>
      <div {...getMenuProps()}>
        {createPortal(
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="scrollbar-thick z-50 overflow-y-auto rounded bg-white shadow"
          >
            {options.length ? (
              <OptionList
                {...{
                  getItemProps,
                  getMenuProps,
                  highlightedIndex,
                  value,
                  reset: !unresettable && reset,
                  isOpen,
                  options,
                  optionId,
                  optionDisplay,
                  resetOnDoubleSelection,
                }}
              />
            ) : null}
          </div>,
        )}
      </div>
    </div>
  );
}
