export { useInvalidator } from "./invalidator";

type InvalidateQueryForDurationProps = {
  queryClient: any;
  queryKey?: string | unknown[];
  duration?: number;
  interval?: number;
  queryFn?: () => void;
};

export function invalidateQueryForDuration({
  queryClient,
  queryKey,
  duration = 10 * 1000,
  interval = 1 * 1000,
  queryFn,
}: InvalidateQueryForDurationProps) {
  if (!queryKey && !queryFn) {
    throw new Error("Either queryKey or queryFn must be provided");
  }

  const invalidate = () => {
    if (queryKey) {
      queryClient.invalidateQueries(queryKey);
    }
    if (queryFn) {
      queryFn();
    }
  };

  invalidate();

  const timer = setInterval(invalidate, interval);

  setTimeout(() => {
    clearInterval(timer);
  }, duration);
}
