import Axios from "axios";
import { baseurl } from "store/ph-api";
import { errorHandler } from "./error-handler";
import { DialogAxiosInstance } from "./interfaces";
import { requestInterceptor } from "./request-interceptor";
import { responseInterceptor } from "./response-interceptor";

export const axios: DialogAxiosInstance = Axios.create({
  baseURL: baseurl,
  timeout: 30000,
});
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor, errorHandler);
axios.defaults.withCredentials = true;
axios.defaults.headers["Accept"] = "application/json";
