import { Accessor } from "./interfaces";

export function identity<Type>(input: unknown) {
  // Logic to convert input to string representation
  return String(input);
}

export function cast<Type>(input: any) {
  return input as Type;
}

export function resolveAccessor<Input extends any, Output>(
  input: Input,
  accessor: Accessor<Input, Output>,
) {
  return typeof accessor === "function"
    ? accessor(input as object)
    : input?.[accessor];
}
